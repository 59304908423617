ul.resp-tabs-list {
	@extend .reduced;
	margin-bottom: 0;
}

.resp-tabs-list li {
	float: left;
	display: inline-block;
	padding: 13px 15px;
	margin: 0 5px 0 0;
	list-style: none;
	cursor: pointer; 
	position: relative;
	background: #cccccc; 
	background: -webkit-linear-gradient(top,  rgba(204,204,204,1) 43%,rgba(178,178,178,1) 100%);
	background: linear-gradient(to bottom,  rgba(204,204,204,1) 43%,rgba(178,178,178,1) 100%); 
	color: $brand-primary;
  	border-top-left-radius:5px;
 	border-top-right-radius:5px;
 	border-right: 1px solid #aaaaaa;
	border-top: 1px solid #aaaaaa;
	border-left: 1px solid #aaaaaa;

	&:hover { 
		color: #FFFFFF;
		background: #333333;
		background: -webkit-linear-gradient(top,  rgba(40,40,40,1) 0%,rgba(51,51,51,1) 27%);
		background: linear-gradient(to bottom,  rgba(40,40,40,1) 0%,rgba(51,51,51,1) 27%);
	}

	&.resp-tab-active { 
		cursor: default; 
		color: #FFFFFF;
		background: #bd1829;
		background: -webkit-linear-gradient(top,  rgba(157,22,36,1) 0%,rgba(189,24,41,1) 27%); 
		background: linear-gradient(to bottom,  rgba(157,22,36,1) 0%,rgba(189,24,41,1) 27%); 
		border-right: 1px solid #921825;
		border-top: 1px solid #921825;
		border-left: 1px solid #921825;
	}

	&.resp-tab-active:hover {  }

	/*&:last-child {border-left: 0; }
	&:first-child {border-left: 1px solid #CCC; }*/
  
}

.resp-tabs-container {
	padding: 0;
	clear: left;
}
 
.resp-tab-content { 
	display: none; 
	padding: 1.25em; 
	border: 1px solid #ccc;
} 

.resp-tab-content-active { 
	border-top: solid 3px $brand-secondary;
}

.resp-tab-active {   

}

@media only screen and (min-width: 768px) {

	
}

.resp-content-active,
.resp-accordion-active,
.resp-tab-content-active { display: block; }

.resp-accordion-closed { display: none; }

h2.resp-accordion {
	display: none;
	cursor: pointer;
	font-size: 1em;
	margin: 0;
	padding: 10px 15px;
	border-bottom: 1px solid #fff;
	text-align: left;
	background: $btn-primary-bg;
	color: $btn-primary-color;

	&:after {
		content:"+";
		float: right;
		font-size: 1.25em;
		line-height: 1;
	}

	&:hover { background-color: $btn-primary-hover-bg; }

	&:active { background-color: $btn-primary-active-bg; }

	&:focus { background-color: $btn-primary-focus-bg; }

	a {color:#fff;}
}

h2.resp-tab-active {
	border: none;
	margin-bottom: 0 !important;
	padding: 10px 15px !important;

	&:after {
		content:"-";
	}

}

//== vertical tabs

.resp-vtabs ul.resp-tabs-list {
	float: left;
	width: 30%;
}

.resp-vtabs .resp-tabs-list li {
	display: block;
	padding: 15px 15px !important;
	margin: 0;
	cursor: pointer;
	float: none;
}

.resp-vtabs .resp-tabs-container {
	padding: 0;
	border: 1px solid #ccc;
	float: left;
	width: 70%;
	clear: none;
}

.resp-vtabs .resp-tab-content { border: none; }

.resp-vtabs li.resp-tab-active {
	border: 1px solid #ccc;
	border-right: none;
	position: relative;
	z-index: 1;
	margin-right: -1px !important;
	padding: 14px 15px 15px 14px !important;
}

@media only screen and (max-width: $screen-sm) {

	ul.resp-tabs-list { display: none; }

	h2.resp-accordion { 
		display: block; 
		margin: 0 !important; 
	}

	.resp-tab-content { border: none; }

	.resp-vtabs .resp-tabs-container {
		border: none;
		float: none;
		width: 100%;
		min-height: initial;
		clear: none;
	}

	.resp-accordion-closed { display: none ;}

	.resp-vtabs .resp-tab-content:last-child { border-bottom: 1px solid #ccc !important; }

}