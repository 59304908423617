.notice-banner {
    display: flex;
    justify-content: space-between;
    background-color: #fff;
    width: 100%;
    padding: 10px 15px 10px 35px;
    border-bottom: 2px ridge #ccc;
    font-size: 15px;
    &.hidden {
        display: none;
    }
    @media screen and (max-width: 1024px) {
        align-items: flex-start;
        font-size: .85em;   
    }
}

.notice-banner p {
    margin: 0;
    text-align: center;
    @media screen and (max-width: 1024px) {
        text-align: left;
    }
}

.notice-banner svg {
    width: 25px;
    height: 25px;
}

.notice-banner button {
    background: none;
    border: none;
    color: #666;
    padding: 10px 0 10px 10px;
    cursor: pointer;
    &:hover {
        color: #000;
    }
}