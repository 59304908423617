.panel-group { margin-bottom: -20px; }

.panel {
	padding: 25px;
	margin-bottom: 20px;
	color: $panel-color;
	background-color: $panel-bg;
	overflow: hidden;
}

.panel-header {
	margin: -5px -15px 0;
	padding: 0 15px 10px;
	border-bottom: 1px solid $panel-border-color;

	.panel-title { margin-bottom: 0; }
}

.panel-content-no-bg {
	margin: 0 -15px -15px;
	padding: 15px;
}

.panel-content {
	margin: 0 -15px -15px;
	padding: 15px;
	background-color: #fff;
}

.panel-primary {
	color: $panel-primary-color;
	background-color: $panel-primary-bg;
	border-color: $panel-primary-border-color;

	.panel-content { color: $panel-color; }
}