.flexslider,
.banner {
	position: relative;
	max-width: 1280px;
	margin: 0 auto 12px;
	overflow: hidden;

	li, .banner-content { margin-bottom: 0; }

	img {
		display: block;
		margin: 0 auto;
	}

	.slides { 
		@extend .clearfix;
		margin: 0;
	}
}

.flex-viewport { 
	min-height: 200px; 
	@media only screen and (max-width: $screen-xs) {
		min-height: inherit;
	}		
}

.slide {
    position: relative;

    a {
        color: #fff;
        text-decoration: none;
    }

    img.award {
	    position: absolute;
	    top: 90px;
	    right: 65px;
	    width: 100%;
	    max-width: 155px;

	    @media only screen and (max-width: $screen-sm) {
	    	top: 15px;
		    right: auto;
		    max-width: 105px;
		    left: 15px;
	    }
	}
}

.slide-caption,
.banner-caption {
	padding: 100px 0 0;
	position: absolute;
	top: 100px;
	left: 8%;
	z-index: 4;
	width: 34%;
	height: 70%;
	color: #fff;

	.btn-primary {
		margin-top: 25px; 
		background: #222;
		color: #fff;
		font-size: 1.2rem;
		padding: 3px 30px 5px;
		border-radius: 20px;
		font-family: Oswald, sans-serif;
		text-transform: uppercase;
		&:after {
			content: ">";
			position: relative;
			top: 2px;
			color: $brand-secondary;
			display: inline-block;
			padding-left: 20px;
			font-size: 2rem;
			line-height: 1.2rem;
		}
	} 

/* 	&.frontline {
		width: 52%;
		padding-top: 25px;
		h1.main-title {
			font-size: 4em;
		}
		p {
			color: rgba(255,255,255,.8);
			text-transform: uppercase;
			width: 70%;
			margin: 20px 0 0;
		}
		@media only screen and (max-width: $screen-sm) {
			width: auto;
			h1.main-title {
				font-size: 1.7rem;
			}
			p {
				font-size: 1rem;
				margin-top: 15px;
				color: #000;
				width: auto;
			}
		}
	} */

	/* &.contactless {
		width: 60%;
		.sub {
			font-size: 2rem;
			margin-top: 50px;
		}
		@media only screen and (max-width: $screen-sm) {
			width: auto;
			.sub {
				font-size: 1rem;
				margin-top: 15px;
			}
		}
	} */

	/* &.superbowl {
		right: 0;
		left: auto;
		width: 46%;
		@media only screen and (max-width: $screen-sm) {
	    	width: 100%;
        }
		a {
        	display:block;
        }

        h1.main-title {
		    font-size: 1.85em;
		    text-transform: initial;
		    color: #333333;
		    @media only screen and (max-width: $screen-sm) {
		    	font-size: 1.6em;
	        }
		}

		.sub {
			font-family: "Open Sans",sans-serif;
			font-size: 1.15em;
		    text-transform: none;
		    margin-top: 1.15em;
		    color: #333333;
		    font-weight: 600;
		    width: 78.5%;
    		line-height: 1.3em;
    		@media only screen and (max-width: $screen-sm) {
		    	width: 100%;
		    	margin-top: 1em;
	        }
		}

		a.btn-primary.btn {
		    background-image: none;
		    background-color: #333333;
		    margin-top: 2em;
		    border-radius: 20px;
		    padding: 0.5em 1em 0.4em 1em;
		    text-transform: uppercase;
		    font-family: "Oswald",sans-serif;
		    font-size: 1.1em;
		    display: inline-block;
		    -webkit-box-shadow: 0px 2px 5px 1px rgba(0,0,0,0.30);
			-moz-box-shadow: 0px 2px 5px 1px rgba(0,0,0,0.30);
			box-shadow: 0px 2px 5px 1px rgba(0,0,0,0.30);
			@media only screen and (max-width: $screen-sm) {
		    	margin-top: 1em;
		    	font-size: 1em;
	        }
		    &:after {
		    	font-family: "FontAwesome";
			    content: "\f04b";
			    display: inline-block;
			    margin-left: 1em;
			    color: #bf0000;
		    }
		}
	} */

	&.vault {
        width: 40%;
        top: 45px;
        a {
        	display:block;
        }
        h1.main-title {
            text-shadow: 4px 6px 9px rgba(0,0,0,0.7);
            font-size: 72px;
            line-height: 1.1;
            @media only screen and (max-width: $screen-sm) {
                text-shadow: none;
                font-size: 1.7em;
    			color: #000000;
            }
        }

        .sub {
		    border-top: 2px solid #00a6c9;
		    font-family: "Open Sans",sans-serif;
		    font-size: 20px;
		    font-weight: 600;
		    margin-top: 1.25rem;
		    padding-top: 1.25rem;
		    text-transform: uppercase;
		    width: 75%;
		    @media only screen and (max-width: $screen-sm) {
    			color: #000000;
    			margin-top: 0.5rem;
		    	padding-top: 0.5rem;
		    	width: 100%;
		    	font-size: 1.35em;
            }
		}
        
        @media only screen and (max-width: $screen-sm) {
            width: 100%;
            top: 0;
        }
    }

    &.bluetooth {
        width: 50%;
        top: 135px;
        a {
        	display:block;
        }
        h1.main-title {
            text-shadow: 4px 6px 9px rgba(0,0,0,0.7);
            font-size: 3.5em;
            line-height: 1.1;
            @media only screen and (max-width: $screen-sm) {
                text-shadow: none;
                font-size: 1.7em;
    			color: #000000;
            }
        }

        h2 {
        	font-size: 2.5em;
        	@media only screen and (max-width: $screen-sm) {
    			color: #000000;
		    	width: 100%;
		    	font-size: 1.7em;
            }
        }

        .sub {
		    border-top: 2px solid #00a6c9;
		    font-family: "Open Sans",sans-serif;
		    font-size: 20px;
		    font-weight: 600;
		    margin-top: 1.25rem;
		    padding-top: 1.25rem;
		    text-transform: uppercase;
		    width: 75%;
		    @media only screen and (max-width: $screen-sm) {
    			color: #000000;
    			margin-top: 0.8rem;
		    	padding-top: 0.8rem;
		    	width: 80%;
            }
		}
        
        @media only screen and (max-width: $screen-sm) {
            width: 100%;
            top: 0;
        }
    }

    /* &.award {
        width: 55%;
        top: 100px;
        a {
        	display:block;
        }
        h1.main-title {
            font-size: 2.9em;
            line-height: 1.1;
            color: #000000;
            @media only screen and (max-width: $screen-sm) {
                font-size: 1.7em;
            }
        }

        .sub {
        	color: #000000;
		    font-family: "Oswald",sans-serif;
		    font-size: 4em;
		    font-weight: 900;
		    text-transform: uppercase;
		    line-height: 1;
		    @media only screen and (max-width: $screen-sm) {
		    	font-size: 1.7em;
		    	line-height: 1.1;
            }

            .block {
            	@media only screen and (max-width: $screen-sm) {
		    		display: inline-block;
            	}
            }
		}
        
        @media only screen and (max-width: $screen-sm) {
            width: 100%;
            top: 0;
        }
    } */
    
    /* &.electronic {
        width: 40%;
        
        h1 {
            text-shadow: 4px 6px 9px rgba(0,0,0,0.7);
            
            @media only screen and (max-width: $screen-sm) {
                 text-shadow: none;
            }
        }
        
        @media only screen and (max-width: $screen-sm) {
            width: 100%;
        }
    } */

    &.ballbearing {
		left: 13.5%;
		width: 35%;
		padding: 20px 0 0;
		@media only screen and (max-width: $screen-sm) {
	    	width: 100%;
	    	left: 0;
			padding: .5em 15px;
        }
		a {
        	display:block;
        }

        h1.main-title {
		    font-size: 6.8em;
		    text-transform: uppercase;
		    color: #ffffff;
		    font-weight: 600;
		    line-height: 1em;
		    letter-spacing: 2px;
		    text-shadow: 4px 6px 9px rgba(0,0,0,0.7);
		    @media only screen and (max-width: $screen-sm) {
		    	font-size: 2em;
		    	color: #000000;
		    	text-shadow: none;
	        }

	        span.spacing {
			    letter-spacing: 4px;
			    @media only screen and (max-width: $screen-sm) {
			    	letter-spacing: 2px;
		        }
			}
		}

		.sub {
			font-family: "Open Sans",sans-serif;
			font-size: 2.3em;
		    color: #ffffff;
		    font-weight: 700;
		    line-height: 1.3em;
		    text-shadow: 4px 6px 9px rgba(0,0,0,0.7);
    		@media only screen and (max-width: $screen-sm) {
		    	width: 100%;
		    	font-size: 1.6em;
		    	color: #000000;
		    	text-shadow: none;
	        }
		}

		img.cta {
		    margin-top: 2.5em;
		    margin-left: 11.5em;
		}
	}

	&.prep {
		width: 220px;
		bottom: 0;
		top: auto;
		height: 45%;
		margin-left: 25px;
	}

	.col { align-content: center;  margin: auto; }
    
    a {
        color: #fff;
        
        &:hover {
            color: #fff;
            text-decoration: none;
		}
		
		@media only screen and (max-width: $screen-sm) {
			color: #000000;
			&:hover {
				color: #000;
			}
		}
    }

	h2 { 
		font-family: $font-family-narrow;
		font-size: 3.3em;  
		text-transform: uppercase;
		margin: 0;
	} 

	p { font-size: 1.2em; }

	@media only screen and (max-width: $screen-sm) {
		position: relative;
		top: auto;
		left: auto;
		width: 100%;
		padding: .5em 15px;  
		background-color: transparent;
		color: inherit;

		h2 { 
			font-size: 1.7em;
			.line {
				display: inline;
			}
		}
	}
}


.home .slide-caption {

	h1 { 
		font-family: $font-family-narrow;
		font-size: 3.3em;  
		text-transform: uppercase;
		margin: 0;

		@media only screen and (max-width: $screen-sm) {
			font-size: 1.7em; 
			.line {
				display: inline;
			}		 
		}

		a {
			text-decoration: none;
			color: #ffffff;
			@media only screen and (max-width: $screen-sm) {
				color: #000000;
			}

			&:hover {
				text-decoration: none;
			}
		}
	
	} 
}

@media only screen and (max-width: $screen-sm) {
	.home a .slide-caption h1{
		text-decoration: none;
		color: #000000;

		&:hover {
			text-decoration: underline;
		}
	}
}

@media only screen and (min-width: 768px) {
	.vault-home {
		top: 70px!important;
	}
	.r-ball-mobile {
		font-size:20px;
		vertical-align:25px;
	}
} 

.flex-control-nav {
	position: relative;
	z-index: 9;
	@extend .reduced;
	margin: -3em 0 2rem 0;
	text-align: center;

	li {
		display: inline-block;
		margin: 0 .5em;
	}

	a {
		display: block;
		cursor: pointer;
		text-indent: -999em;
		background-color: #ddd;
		width: 17px;
		height: 17px;
		border-radius: 100%;

		&.flex-active { background-color: $link-color; }

		&:hover { background-color: #ccc; }
	}

	@media only screen and (max-width: $screen-sm) {
		margin: 0;

		a { 
			width: 8px;
			height: 8px;
		}
	}
}
@media only screen and (max-width: $screen-sm) {
	.category .flex-control-nav {
		margin: 3% 0 0;
	}
}

/* Direction Nav */

.flexslider {
	&:hover .flex-next {
		opacity: 0.8; 
		right: 5px;
		z-index: 100;
	}

	&:hover .flex-prev {
		opacity: 0.8;
		left: 5px;
		z-index: 100;
	}
}

.flex-direction-nav {
	margin: 0; 
	padding: 0; 
	list-style: none;

	@media only screen and (max-width: $screen-sm) {
		display: none;
	} 

	a {
		margin: -20px 0 0; 
		display: block; 
		position: absolute; 
		top: 47%; 
		cursor: pointer; 
		text-indent: -9999px;  
		opacity: 0; 
		-webkit-transition: all .3s ease; 
	}

	.flex-next {
		@include sprite($height: 100px, $width: 36px, $x-coord: -224px, $y-coord: -148px);
		right: 32px !important; 
		&:hover {opacity: 1;}
	}

	.flex-prev {
		@include sprite($height: 100px, $width: 36px, $x-coord: -179px, $y-coord: -148px);
		left: 34px !important;
		&:hover {opacity: 1;}
	}

	.disabled {	
		opacity: .3!important; 
		filter:alpha(opacity=30); cursor: default;
	}

} 

/* Category Flexslider */
.category {    
    .slide-caption {
        .section-title {
            @media only screen and (max-width: $screen-sm) {
                color: #000;
                font-size: 1.7em;
            }
        }
        
        p {
            @media only screen and (max-width: $screen-sm) {
                color: #000;
            }
        }
            
        @media only screen and (max-width: $screen-sm) {
            width: 100%;
        }
    }
}
.category-flexslider {
	@media only screen and (max-width: $screen-sm) {
		width: 100%;
		overflow: hidden;
		clear: both;
	}
}