@charset "UTF-8";

//$cdnPath: ""; This is inserted upon compilation by the gulp file

body {
    background-color: white !important;
    margin: 0;
}

.legacy {
    //== variables & mixins
    @import "variables";

    //Formerly 'body' styles
    font-size: $font-size-base;
    font-family: $font-family-base;
    line-height: $line-height-base;
    font-weight: $font-weight-base;
    color: $color-base;
    background: white;
    //background: $body-bg;

    @media only screen and (max-width: $screen-sm) {
        background-color: $brand-secondary; 
    }

    @media only screen and (max-width: $screen-xs) {
        word-wrap:break-word;
    }

    @import "mixins";

    //== fonts
    @import "fonts";

    //== 3rd-party plugin-dependent styles
    @import "easyResponsiveTabs";
    @import "easyzoom";
    @import "flexslider";
    @import "fancybox";
    @import "featherlight";
    @import "featherlightGallery";

    //== base styles
    @import "normalize"; 
    @import "grid";
    @import "defaults";
    @import "base";
    @import "colors";
    @import "buttons";
    @import "icons";
    @import "lists";
    @import "forms";
    @import "tables";
    @import "header";
    @import "footer";
    @import "content";
    @import "panels";
    @import "video";
    @import "tooltips";
    @import "notice-banner";
    @import "modal";

    //== Page styles
    @import "homepage";
    @import "segmented-home";
    @import "product-search";
    @import "bluetooth";
    @import "eu-us";
    @import "padlock-buying-guide";
    @import "verticals";
    @import "healthcare";
    @import "electronic-products";
    @import "retired-products";
    @import "careers";
    @import "about-us";
    @import "prepared";

    //@import "font-awesome";

    // This has to go last
    @import "print";
}