.btn {
	display: inline-block;
	text-align: center;
	cursor: pointer;
	text-decoration: none;
	line-height: inherit;
	-webkit-tap-highlight-color: transparent;
	font-size: $btn-font-size;
	color: $btn-color;
	background: $btn-bg;
	padding: $btn-padding;
	border: $btn-border;
	@if $btn-border-radius != 0 {
		border-radius: $btn-border-radius;
	}
	-moz-user-select: none;
	-webkit-user-select: none;
	-ms-user-select: none;
	user-select: none;

	&:hover {
		background: $btn-hover-bg;
		color: $btn-hover-color;
		text-decoration: none;
	}

	&:focus { background: $btn-focus-bg; }

	&:active { background: $btn-active-bg; }

	&::-moz-focus-inner { border: none; }
}

.btn-primary {
	color: $btn-primary-color;
	background-color: #cc0000;
	background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIwJSIgeTI9IjEwMCUiPgogICAgPHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iI2NjMDAwMCIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjEwMCUiIHN0b3AtY29sb3I9IiM5OTAwMDAiIHN0b3Atb3BhY2l0eT0iMSIvPgogIDwvbGluZWFyR3JhZGllbnQ+CiAgPHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEiIGhlaWdodD0iMSIgZmlsbD0idXJsKCNncmFkLXVjZ2ctZ2VuZXJhdGVkKSIgLz4KPC9zdmc+);
	background-image: -webkit-linear-gradient(top,  #cc0000 0%,#990000 100%);
	background-image: linear-gradient(to bottom,  #cc0000 0%,#990000 100%);
	border-radius: 3px;

	@if $btn-border != 'none' {
		border: $btn-primary-border-color;
	}

	&:hover {
		background: $btn-primary-hover-bg;
		color: $btn-primary-hover-color;
	}

	&:focus { background: $btn-primary-focus-bg; }

	&:active { background: $btn-primary-active-bg; }
}

.btn-bordered { border: 1px solid #fff; }

.btn-alt {
	background: #fff;
	color: $brand-secondary;
	font-weight: 600;
}

.btn-info {
	color: $btn-info-color;
	background: $btn-info-bg;
	@if $btn-border != 'none' {
		border: $btn-info-border-color;
	}

	&:hover {
		background: $btn-info-hover-bg;
		color: $btn-info-hover-color;
	}

	&:focus { background: $btn-info-focus-bg; }

	&:active { background: $btn-info-active-bg; }
}

.btn-success {
	color: $btn-success-color;
	background: $btn-success-bg;
	@if $btn-border != 'none' {
		border: $btn-success-border-color;
	}

	&:hover {
		background: $btn-success-hover-bg;
		color: $btn-success-hover-color;
	}

	&:focus { background: $btn-success-focus-bg; }

	&:active { background: $btn-success-active-bg; }
}

.btn-warning {
	color: $btn-warning-color;
	background: $btn-warning-bg;
	@if $btn-border != 'none' {
		border: $btn-warning-border-color;
	}

	&:hover {
		background: $btn-warning-hover-bg;
		color: $btn-warning-hover-color;
	}

	&:focus { background: $btn-warning-focus-bg; }

	&:active { background: $btn-warning-active-bg; }
}

//---- end temporary -----------------------------------------------------------------------------

.btn-danger {
	color: $btn-danger-color;
	background: $btn-danger-bg;
	@if $btn-border != 'none' {
		border: $btn-danger-border-color;
	}

	&:hover {
		background: $btn-danger-hover-bg;
		color: $btn-danger-hover-color;
	}

	&:focus { background: $btn-danger-focus-bg; }

	&:active { background: $btn-danger-active-bg; }
}

.btn-link {
	color: $link-color;
	background: transparent;
	border: none;
	text-decoration: underline;

	&:hover,
	&:focus,
	&:active {
		color: $link-hover-color;
		background: transparent;
	}
}

.btn.disabled,
.btn[disabled] {
	color: #999 !important;
	background: #ccc !important;
	text-shadow: 1px 1px 1px #fff;
	cursor: not-allowed !important;
}

.btn-lg { font-size: $btn-lg-font-size; }

.btn-sm { 
	font-size: $btn-sm-font-size;
	padding: $btn-padding-sm;
}

.btn-block {
	display: block;
	width: 100%;
}

.btn-submit {
	@media only screen and (max-width: 480px) {
		display: block;
		width: 100%;
		font-size: $btn-lg-font-size;
	}
}

//---- Callout style -----------------------------------------------------------------------------
.callout-links {
	@extend .clearfix;
	background: none;
	margin: 10px 0;
	position: relative;
	z-index: 99;

	.col { margin: 5px 0; }

	li {
		
		a {
			color: #fff;
			min-height: 75px;
			display: table;
			height: 75px;
			font-size: 18px;
			line-height: 120%;
			width: 100%;
			@extend .gradient-gray-dark;

			&:hover {
				background-color: #777777;
				background-image: none;
			}

			span, img {
				vertical-align: middle;
				display: table-cell;
			}

			.callout-link-text {
				width: 85%;
				padding-left: 10px;
			}
			
			.callout-link-icon {
				width: 15%;
			}
		}			
	} 
}