:focus { outline: none; }

img,
embed,
object,
video { max-width: 100%; }

.align-left { text-align: left; }

.align-right { text-align: right; }

.align-center { text-align: center; }
 
.uppercase {text-transform: uppercase;}

.right { float: right; }

.left { float: left; }

.clear { clear: both; }

.clear-left { clear: left; }

.clear-right { clear: right; }

.assistive-text {
	position: absolute;
	clip: rect(1px 1px 1px 1px);
	clip: rect(1px, 1px, 1px, 1px);
}

.hide,
.print { display: none !important; }

.hidden { display: none; }

.no-mobile [href^="tel:"],
.no-touch [href^="tel:"] {
	text-decoration: none;
	color: inherit;
	cursor: inherit;

	&:hover { color: inherit; }
}

a[href$=".pdf"]:after { content: " (PDF)"; }

a.no-icon[href$=".pdf"]:after { content: ""; }

.iframe-container iframe { 
   min-height: 400px;
   width: 100%;
   overflow-x: scroll;
   border: 0;
}