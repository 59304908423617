//== flex mixins
@import "flex-mixins";

////////// Retina Screens //////////

@mixin image-2x() {
  @media (-webkit-min-device-pixel-ratio: 1.3),
         (min-resolution: 1.3dppx) {
    background-image: url('#{$cdnPath}/img/sprite@2x.png');
    background-size: 256px 857px;
  }
}

////////// Background Sprite //////////

@mixin sprite($height, $width, $x-coord, $y-coord) {
	background-image: url('#{$cdnPath}/img/sprite.png');
	height: $height;
	width: $width;
	background-position: $x-coord $y-coord;
	background-repeat: no-repeat;
}