$screen-sm:	767px !default;
$black: #000000 !default;
$blue: #00a6c9 !default;
$dark-gray: #55575a !default;
$gray-border: #c8c8c8 !default;
$light-gray: #e9e9e9 !default;
$white: #ffffff !default;


.healthcare {
    &.category {
        .site-nav-bkgd {
            opacity: 1;
        }
        
        #content {
            margin-bottom: 0;
            top: auto;
            
            .hero {
                line-height: 0;
                margin-bottom: 0;
                top: -110px;

                .overlay-container {
                    position: absolute;
                    padding: 10px;
                    border: 1px solid $white;
                    width: 260px;
                    @media only screen and (max-width: $screen-sm) {
                        position: relative;
                        width: 100%;
                        padding: 0 20px 20px;
                        border: none;
                    }
                    .overlay-interior {
                        background: rgba(0, 166, 201, 0.65);
                        padding: 25px;
                        h3 {
                            color: $white;
                            text-align: left;
                            text-transform: uppercase;
                            font-size: 0.95rem;
                            margin-bottom: 0;
                        }
                        .block-alt {
                            font-size: 0.75rem;
                            text-transform: lowercase;
                            display: block;
                            line-height: 17px;
                            color: $white;
                            text-align: left;
                            padding-top: 3px;
                        }
                    } 
                }

                .item-1 {
                    bottom: 140px;
                    left: 355px;
                    @media only screen and (max-width: $screen-sm) {
                        bottom: 0;
                        left: 0;
                    }
                }

                .item-2 {
                    top: 165px;
                    right: 350px;
                    @media only screen and (max-width: $screen-sm) {
                        top: 0;
                        right: 0;
                    }
                }

                .item-3 {
                    bottom: 40px;
                    right: 30px;
                    @media only screen and (max-width: $screen-sm) {
                        bottom: 0;
                        right: 0;
                    }
                }

                .section-header {
                    padding: 145px 10px 10px 10px;
                    max-width: 330px;
                    background: rgba(0, 0, 0, 0.65);
                    text-align: center;
                    @media only screen and (max-width: $screen-sm) {
                        padding: 145px 0 70px 10px;
                        max-width: 100%;
                        background: none;
                        text-align: left;
                    }

                    .section-title {
                        font: 60px/1.1 "Oswald",sans-serif;
                        text-transform: uppercase;
                    }
                    
                    .sub {
                        border-top: 2px solid $blue;
                        font-size: 30px;
                        font-weight: 600;
                        margin-top: 1.25rem;
                        padding-top: 1.25rem;
                        text-transform: uppercase;
                        width: 100%;
                        
                        @media only screen and (max-width: $screen-sm) {
                            color: $black;
                            font-size: 20px;
                        }
                    }
                        
                    @media only screen and (max-width: $screen-sm) {
                        padding: 1.25rem 1.25rem 0;
                    }
                }
                
                @media only screen and (max-width: $screen-sm) {
                    top: 0;
                }
            }
            
            .expandable {
                cursor: pointer;
            }
        }
    }
    
    .section {
        padding: 0;
        
        &.first {
            margin-top: -110px;
            
            @media only screen and (max-width: $screen-sm) {
                margin-top: 0;
            }
        }
        
        .row {
            padding: 3.125rem 3.75rem;
            max-width: 1280px;
            
            &.section-content {
                h2 {
                    border-bottom: 1px solid $blue;
                    color: $dark-gray;
                    font-weight: 600;
                    padding-bottom: 0.625rem;
                    width: 93%;
                    
                    @media only screen and (max-width: $screen-sm) {
                        width: 100%;
                    }
                }
                
                p {
                    color: $dark-gray;
                    font-size: 1rem;
                    margin-bottom: 0;
                    width: 93%;
                    line-height: normal;
                    
                    @media only screen and (max-width: $screen-sm) {
                        width: 100%;
                    }
                }
                
                .blue-title {
                    color: $blue;
                    font-size: 1.125rem;
                    font-weight: 600;
                    text-transform: uppercase;
                }
                
                @media only screen and (max-width: $screen-sm) {
                    padding: 1.25rem;
                }
            }
            
            @media only screen and (max-width: $screen-sm) {
                padding: 0;
            }
        }



        &.image-banner {
            position: relative;
            
            .row {
                &.section-content {
                    line-height: 0;
                    padding: 0;
                    
                    .col {
                        padding: 0;
                        
                        &.content-left {
                            background-color: #53504a;
                            padding: 25px 150px 10px 150px;
                            @media only screen and (max-width: $screen-sm) {
                                padding: 25px;
                            }
                            .overview-top, .overview-bottom {
                                display: -webkit-box;
                                display: -ms-flexbox;
                                display: flex;
                                -webkit-box-orient: horizontal;
                                -webkit-box-direction: normal;
                                    -ms-flex-direction: row;
                                        flex-direction: row;
                                .left-items {
                                    -webkit-box-flex: 1;
                                    -ms-flex: 1 0 auto;
                                        flex: 1 0 auto;
                                }
                                img {
                                    width: 70px;
                                    margin-right: 1.2rem;
                                    -ms-flex-negative: 0;
                                    flex-shrink: 0;
                                }
                            }

                            .overview-bottom {
                                margin-top: 1rem;
                                border-top: 1px solid $blue;
                                padding-top: 2rem;
                            }

                            h2 {
                                border: none;
                                color: #ffffff;
                                font-weight: 600;
                                font-size: 1rem;
                                padding-bottom: 0;
                                width: 100%;
                            }
                            p {
                                color: #ffffff;
                                width: 100%;
                                margin-bottom: 1rem;
                                font-size: 0.8rem;
                            }
                        }
                        &.content-right {
                            padding: 65px 100px 10px 100px;
                            @media only screen and (max-width: $screen-sm) {
                                padding: 25px;
                            }

                            h2 {
                                border: none;
                                font-weight: 600;
                                font-size: 1rem;
                                padding-bottom: 0;
                                width: 90%;
                                margin-bottom: 5px;
                                @media only screen and (max-width: $screen-sm) {
                                     float: left;
                                }
                            }
                            p {
                                width: 90%;
                                margin-bottom: 1.7rem;
                                font-size: 0.8rem;
                                @media only screen and (max-width: $screen-sm) {
                                     float: left;
                                }
                            }

                            span {
                                display: block;
                                padding-top: 0.5rem;
                                font-weight: 700;
                                font-size: 1rem;
                            }
                            .content-items {
                                display: -webkit-box;
                                display: -ms-flexbox;
                                display: flex;
                                -webkit-box-orient: horizontal;
                                -webkit-box-direction: normal;
                                    -ms-flex-direction: row;
                                        flex-direction: row;
                                img {
                                    width: 45px;
                                    -ms-flex-negative: 0;
                                    flex-shrink: 0;
                                }
                            }
                            .left-items {
                                -webkit-box-flex: 1;
                                -ms-flex: 1 0 auto;
                                flex: 1 0 auto;
                                -ms-flex-line-pack: center;
                                align-content: center;
                                text-align: center;
                                margin-right: 1rem;
                            }
                        }
                    }
                }
            }
        }

        &.security {
            .section-heading {
                padding: 3.125rem 3.75rem 2rem;
                @media only screen and (max-width: $screen-sm) {
                     padding: 1.25rem 1.25rem 0;
                }
                h2 {
                    border-bottom: 1px solid #00a6c9;
                    color: #55575a;
                    font-weight: 600;
                    padding-bottom: 0.625rem;
                    width: 93%;
                    @media only screen and (max-width: $screen-sm) {
                         width: 100%;
                    }
                }
            }
            .section-content {
                padding: 0 3.75rem 3.125rem;
                display: -webkit-box;
                display: -ms-flexbox;
                display: flex;
                align-items: center;
                @media only screen and (max-width: $screen-sm) {
                     display: block;
                     padding:  0 20px 20px;
                }

                .content-right {
                    padding: 0 10px 0 40px;
                    @media only screen and (max-width: $screen-sm) {
                        padding: 0;
                    }
                    p {
                        width: 88%;
                        @media only screen and (max-width: $screen-sm) {
                             width: 100%;
                        }
                        &:after {
                            height: 1px;
                            display: block;
                            width: 100%;
                            content: '';
                            margin: 25px 0 20px;
                            border: 0.025rem solid $blue;
                        }
                    }
                    .security-items {
                        padding: 0;
                        list-style: none;
                        @media only screen and (max-width: 625px) {
                            margin: 0;
                        }
                        li {
                            display: inline-block;
                            margin: 0;
                            font-size: 1.5rem;
                            font-weight: 600;
                            padding-right: 40px;
                            position: relative;
                            padding: 0 2rem 0 3.1rem;
                            @media only screen and (max-width: 625px) {
                                display: block;
                                padding: 1rem 2rem 1rem 3.1rem;
                            }
                            &:before {
                                content: "";
                                background-size: 50px 50px;
                                display: inline-block;
                                width: 50px;
                                height: 50px;
                                position: absolute;
                                left: 0;
                                margin-top: 2px;
                            }
                        }
                        .item-1 {
                            &:before {
                                background-image: url('../../resources/img/verticals/healthcare/ec-icon.png');
                            }
                        }
                        .item-2 {
                            &:before {
                                background-image: url('../../resources/img/verticals/healthcare/ls-icon.png');
                            }
                        }
                        .item-3 {
                            &:before {
                                background-image: url('../../resources/img/verticals/healthcare/ic-icon.png');
                            }
                        }
                        .block {
                            font-size: 1rem;
                            margin-top: -3px;
                        }
                    }
                }
            }
        }

        &.safety {
            .section-heading {
                padding: 3.125rem 3.75rem 2rem;
                @media only screen and (max-width: $screen-sm) {
                     padding: 1.25rem 1.25rem 0;
                }
                h2 {
                    border-bottom: 1px solid #00a6c9;
                    color: #55575a;
                    font-weight: 600;
                    padding-bottom: 0.625rem;
                    width: 93%;
                    @media only screen and (max-width: $screen-sm) {
                         width: 100%;
                    }
                }
            }
            .section-content {
                padding: 0 3.75rem 3.125rem;
                display: -webkit-box;
                display: -ms-flexbox;
                display: flex;
                -webkit-box-align: center;
                    -ms-flex-align: center;
                        align-items: center;
                @media only screen and (max-width: $screen-sm) {
                     display: block;
                     padding:  0 20px 20px;
                }

                .content-right {
                    padding: 0 10px 0 40px;
                    @media only screen and (max-width: $screen-sm) {
                        padding: 0;
                    }
                    p {
                        width: 88%;
                        @media only screen and (max-width: $screen-sm) {
                             width: 100%;
                        }
                        &:after {
                            height: 1px;
                            display: block;
                            width: 100%;
                            content: '';
                            margin: 25px 0 20px;
                            border: 0.025rem solid $blue;
                        }
                    }
                    .security-items {
                        padding: 0;
                        list-style: none;
                        @media only screen and (max-width: 625px) {
                            margin: 0;
                        }
                        li {
                            display: inline-block;
                            margin: 0;
                            font-size: 1.5rem;
                            font-weight: 600;
                            padding-right: 40px;
                            position: relative;
                            padding: 0 2rem 0 3.2rem;
                            @media only screen and (max-width: 625px) {
                                display: block;
                                padding: 1rem 2rem 1rem 3.2rem;
                            }
                            &:before {
                                content: "";
                                background-size: 50px 50px;
                                display: inline-block;
                                width: 50px;
                                height: 50px;
                                position: absolute;
                                left: 0;
                                margin-top: 2px;
                            }
                        }
                        .item-1 {
                            &:before {
                                background-image: url('../../resources/img/verticals/healthcare/ec-icon.png');
                            }
                        }
                        .item-2 {
                            &:before {
                                background-image: url('../../resources/img/verticals/healthcare/ls-icon.png');
                            }
                        }
                        .item-3 {
                            &:before {
                                background-image: url('../../resources/img/verticals/healthcare/osha-lock-icon.png');
                            }
                        }
                        .block {
                            font-size: 1rem;
                            margin-top: -3px;
                        }
                    }
                }
            }
        }

        &.protection {
            .section-heading {
                padding: 3.125rem 3.75rem 2rem;
                @media only screen and (max-width: $screen-sm) {
                     padding: 1.25rem 1.25rem 0;
                }
                h2 {
                    border-bottom: 1px solid #00a6c9;
                    color: #55575a;
                    font-weight: 600;
                    padding-bottom: 0.625rem;
                    width: 93%;
                    @media only screen and (max-width: $screen-sm) {
                         width: 100%;
                    }
                }
                p {
                    color: #55575a;
                    font-size: 1rem;
                    margin-bottom: 0;
                    width: 93%;
                    line-height: normal;
                    @media only screen and (max-width: $screen-sm) {
                         width: 100%;
                    }
                }
            }
            .section-content {
                padding: 0 3.75rem 2rem;
                @media only screen and (max-width: $screen-sm) {
                    padding: 0 1rem;
                }
                .content-bluetooth {
                    padding: 0 30px 0 10px;
                    @media only screen and (max-width: $screen-sm) {
                         padding: 20px 0 40px;
                    }
                    .image-right img {
                        max-width: 70px;
                    }

                    .image-left img {
                        max-width: 80px;
                    }

                    .image-left, .image-right {
                        @media only screen and (max-width: 550px) {
                            display: inline-block;
                        }
                    }

                    .image-right {
                        @media only screen and (max-width: 550px) {
                            margin-top: 4.65rem;
                        }
                    }
                }
                .content-padlocks {
                    padding: 0 10px 0 30px;
                    @media only screen and (max-width: $screen-sm) {
                        padding: 0 0 80px;
                    }
                    @media only screen and (max-width: 550px) {
                        padding: 0 0 40px;
                    }

                    .resettable-padlocks {
                        position: relative;
                        margin-right: 6.5rem;
                    }
                     .image-right img {
                        max-width: 90px;
                        margin-right: 5px;
                    }

                    .image-left img {
                        max-width: 125px;
                        position: absolute;
                        bottom: -20px;
                        left: 45px;
                        @media only screen and (max-width: 550px) {
                             position: initial;
                        }
                    }

                    .image-left, .image-right {
                        @media only screen and (max-width: 550px) {
                            display: inline-block;
                        }
                    }

                    .padlock-title {
                        position: absolute;
                        bottom: -40px;
                        margin-left: 30px;
                        @media only screen and (max-width: 550px) {
                            position: initial;
                            margin-left: 0;
                        }
                    }
                }

                .content-key-padlocks {
                    padding: 0 10px 0 30px;
                    @media only screen and (max-width: $screen-sm) {
                         padding: 0 0 40px;
                    }
                     .image-right img {
                        max-width: 60px;
                        margin-right: 10px;
                    }
                    .image-left img {
                        max-width: 80px;
                    }
                    .image-left, .image-right {
                        @media only screen and (max-width: 550px) {
                            display: inline-block;
                        }
                    }
                    .image-right {
                        @media only screen and (max-width: 550px) {
                            margin-top: 1.7rem;
                        }
                    }
                }
                .content-key-laminated-padlocks {
                    padding: 0 10px 0 30px;
                    @media only screen and (max-width: $screen-sm) {
                         padding: 0 0 40px;
                    }
                     .image-right img {
                        max-width: 90px;
                        margin-right: 5px;
                    }

                    .image-left img {
                        max-width: 85px;
                    }

                    .image-left, .image-right {
                        @media only screen and (max-width: 550px) {
                            display: inline-block;
                        }
                    }
                    .image-right {
                        @media only screen and (max-width: 550px) {
                            margin-top: 1.7rem;
                        }
                    }
                }
                .content-left, .content-right {
                    display: -webkit-box;
                    display: -ms-flexbox;
                    display: flex;
                    -webkit-box-orient: horizontal;
                    -webkit-box-direction: normal;
                        -ms-flex-direction: row;
                            flex-direction: row;
                    @media only screen and (max-width: 550px) {
                        display: block;
                    }
                }
                .left-items {
                    margin-right: 1.5rem;
                    display: -webkit-box;
                    display: -ms-flexbox;
                    display: flex;
                    -webkit-box-align: baseline;
                        -ms-flex-align: baseline;
                            align-items: baseline;
                    text-align: center;
                    -webkit-box-flex: 1;
                    -ms-flex: 1 0 auto;
                        flex: 1 0 auto;
                    span {
                        font-weight: 600;
                        font-size: 0.8rem;
                        display: block;
                    }
                    @media only screen and (max-width: 550px) {
                        display: block;
                        width: 100%;
                        margin: 0 auto;
                    }
                }
                .right-items {
                    @media only screen and (max-width: 550px) {
                        clear: both;
                        display: block;
                        padding-top: 1rem;
                    }

                    h3 {
                        color: #00a6c9;
                        font-size: 1.125rem;
                        font-weight: 600;
                    }
                    ul {
                        color: #55575a;
                        list-style: none;
                        li {
                            &:before {
                                content: "\2022"; 
                                color: #00a6c9;
                                font-weight: bold;
                                display: inline-block;
                                width: 1rem;
                                margin-left: -1rem;
                                font-size: 1rem;
                            }
                        }
                    }
                }
            }
        }

        &.personal-protection {
            .section-heading {
                padding: 3.125rem 3.75rem 2rem;
                @media only screen and (max-width: $screen-sm) {
                     padding: 1.25rem 1.25rem 0;
                }
                h2 {
                    border-bottom: 1px solid #00a6c9;
                    color: #55575a;
                    font-weight: 600;
                    padding-bottom: 0.625rem;
                    width: 93%;
                    @media only screen and (max-width: $screen-sm) {
                         width: 100%;
                    }
                }
            }
            p {
                color: #55575a;
                font-size: 1rem;
                margin-bottom: 0;
                width: 93%;
                line-height: normal;
                @media only screen and (max-width: $screen-sm) {
                     width: 100%;
                }

            }

            .section-content {
                padding: 0 3.75rem 3.125rem;
                @media only screen and (max-width: $screen-sm) {
                     padding: 1.25rem 0.7rem 3rem;
                }
                span {
                    font-weight: 600;
                    font-size: 0.8rem;
                }
                .lock-content {
                    @media only screen and (max-width: $screen-sm) {
                        margin: 0 auto;
                        display: table;
                    }
                }
                .content-left {
                    padding: 0 50px 0 10px;
                    @media only screen and (max-width: $screen-sm) {
                        padding: 0 10px 50px 10px;
                    }
                    .lock-left, .lock-right {
                        text-align: center;
                        width: 105px;
                    }
                    .lock-left {
                        margin-top: 2.5rem;
                        margin-right: 1.5rem;
                    }
                }
                .content-right {
                    padding: 0 10px 0 50px;
                    @media only screen and (max-width: $screen-sm) {
                        padding: 0 10px 0 10px;
                    }
                    .lock-left {
                        text-align: right;
                        width: 225px;
                        margin-top: 2.79rem;
                        @media only screen and (max-width: 525px) {
                           margin-top: 9.5%;
                        }
                    }
                    .lock-right {
                        text-align: left;
                        width: 225px;
                    }

                    .lock-left, .lock-right {
                        @media only screen and (max-width: 525px) {
                           width: 47%;
                        }
                    }
                }
                h3 {
                    color: #00a6c9 !important;
                    font-size: 1.125rem;
                    font-weight: 600;
                    width: 80%;
                    @media only screen and (max-width: $screen-sm) {
                        width: 100%;
                    }
                }
                ul {
                    color: #55575a;
                    list-style: none;
                    width: 85%;
                    @media only screen and (max-width: $screen-sm) {
                        width: 100%;
                    }
                    li {
                        &:before {
                            content: "\2022"; 
                            color: #00a6c9;
                            font-weight: bold;
                            display: inline-block;
                            width: 1rem;
                            margin-left: -1rem;
                            font-size: 1rem;
                        }
                    }
                }

                ul ul {
                    margin: 0.5rem;
                    li {
                        &:before {
                            content: "\2013"; 
                            color: #000000;
                            font-weight: bold;
                            display: inline-block;
                            width: 1rem;
                            margin-left: -1rem;
                            font-size: 1rem;
                        }
                    }
                }
                .lock-left, .lock-right {
                    float: left;
                }
                .lock-left {
                    margin-right: 1rem;
                }
            }
        }

        &.equipment {
            .section-heading {
                padding: 3.125rem 3.75rem 2rem;
                @media only screen and (max-width: $screen-sm) {
                     padding: 1.25rem 1.25rem 0;
                }
                h2 {
                    border-bottom: 1px solid #00a6c9;
                    color: #55575a;
                    font-weight: 600;
                    padding-bottom: 0.625rem;
                    width: 93%;
                    @media only screen and (max-width: $screen-sm) {
                         width: 100%;
                    }
                }
            }
            p {
                color: #55575a;
                font-size: 1rem;
                margin-bottom: 0;
                width: 93%;
                line-height: normal;
                @media only screen and (max-width: $screen-sm) {
                     width: 100%;
                }

            }

            .section-content {
                padding: 0 3.75rem 3.125rem;
                @media only screen and (max-width: $screen-sm) {
                     padding: 1.25rem 0.7rem 3rem;
                }
                span {
                    font-weight: 600;
                    font-size: 0.8rem;
                }
                .lock-content {
                    @media only screen and (max-width: $screen-sm) {
                        margin: 0 auto;
                        display: table;
                    }
                }
                .content-left {
                    padding: 0 30px 0 10px;
                    @media only screen and (max-width: $screen-sm) {
                        padding: 0 10px 50px 10px;
                    }
                    .lock-left {
                        text-align: center;
                        width: 140px;
                        @media only screen and (max-width: 525px) {
                           width: 100px;
                        }
                    }
                    .lock-right {
                        text-align: center;
                        width: 200px;
                        @media only screen and (max-width: 525px) {
                           width: 160px;
                        }
                    }
                    .lock-left {
                        margin-top: 1.35rem;
                        margin-right: 4rem;
                        @media only screen and (max-width: 525px) {
                           margin-right: 1.5rem;
                           margin-top: 2.45rem;
                        }
                    }
                }
                .content-right {
                    padding: 0 10px 0 90px;
                    @media only screen and (max-width: $screen-sm) {
                        padding: 0 10px 0 10px;
                    }
                    .lock-left {
                        text-align: right;
                        width: 225px;
                        margin-top: 2.79rem;
                        @media only screen and (max-width: 525px) {
                           margin-top: 9.5%;
                        }
                    }
                    .lock-right {
                        text-align: right;
                        width: 100%;
                        margin-top: 1.5rem;
                        position: relative;
                        max-width: 530px;
                    }
                    span {
                        position: absolute;
                        bottom: 10px;
                        right: 80px;
                        @media only screen and (max-width: 485px) {
                            bottom: 5px;
                            right: 30px;
                        }
                    }
                }
                h3 {
                    color: #00a6c9 !important;
                    font-size: 1.125rem;
                    font-weight: 600;
                    width: 80%;
                    @media only screen and (max-width: $screen-sm) {
                        width: 100%;
                    }
                }
                ul {
                    color: #55575a;
                    list-style: none;
                    width: 85%;
                    @media only screen and (max-width: $screen-sm) {
                        width: 100%;
                    }
                    li {
                        &:before {
                            content: "\2022"; 
                            color: #00a6c9;
                            font-weight: bold;
                            display: inline-block;
                            width: 1rem;
                            margin-left: -1rem;
                            font-size: 1rem;
                        }
                    }
                }

                ul ul {
                    margin: 0.5rem;
                    li {
                        &:before {
                            content: "\2013"; 
                            color: #000000;
                            font-weight: bold;
                            display: inline-block;
                            width: 1rem;
                            margin-left: -1rem;
                            font-size: 1rem;
                        }
                    }
                }
                .lock-left, .lock-right {
                    float: left;
                }
                .lock-left {
                    margin-right: 1rem;
                }
            }
        }

        &.lockout {
            .section-heading {
                padding: 3.125rem 3.75rem 2rem;
                @media only screen and (max-width: $screen-sm) {
                     padding: 1.25rem 1.25rem 0;
                }
                h2 {
                    border-bottom: 1px solid #00a6c9;
                    color: #55575a;
                    font-weight: 600;
                    padding-bottom: 0.625rem;
                    width: 93%;
                    @media only screen and (max-width: $screen-sm) {
                         width: 100%;
                    }
                }
                p {
                    color: #55575a;
                    font-size: 1rem;
                    margin-bottom: 0;
                    width: 93%;
                    line-height: normal;
                    @media only screen and (max-width: $screen-sm) {
                         width: 100%;
                    }
                }
            }
            .section-content {
                padding: 0 3.75rem 12rem;
                @media only screen and (max-width: $screen-sm) {
                    padding: 0 1rem 2rem;
                }
                h3 {
                    background-color: #039ddb;
                    color: #ffffff !important;
                    padding: 1.3rem 2rem;
                    text-transform: uppercase;
                    width: 60%;
                    font-size: 1.2rem;
                    @media only screen and (max-width: $screen-sm) {
                        width: 100%;
                    }
                }
                .list-items {
                    width: 60%;
                    border-left: 1px solid #55575a;
                    border-bottom: 1px solid #55575a;
                    position: relative;
                    @media only screen and (max-width: $screen-sm) {
                        width: 100%;
                        border-right: 1px solid #55575a;
                    }
                    img {
                        position: absolute;
                        top: -79px;
                        right: -275px;
                        width: 58%;
                        @media only screen and (max-width: $screen-sm) {
                            position: initial;
                            margin: 2rem auto;
                            display: block;
                            min-width: 275px;
                        }
                    }
                }
                ul {
                    color: #55575a;
                    list-style: none;
                    margin-left: 2rem;
                    li {
                        font-size: 1.1rem;
                        &:before {
                            content: "\2022"; 
                            color: #00a6c9;
                            font-weight: bold;
                            display: inline-block;
                            width: 1rem;
                            margin-left: -1rem;
                            font-size: 1.1rem;
                        }
                    }
                }
            }
        }
        
        &.call-to-action {
            h3 {
                font-weight: 600;
            }
            
            &.blue {
                text-align: center;

                h3 {
                    display: inline-block;
                    font-size: 1.25rem;
                    margin: 0 1.25rem;
                    text-transform: uppercase;

                    @media only screen and (max-width: $screen-sm) {
                        display: block;
                        margin: 0 0 1.25rem;
                    }
                }

                .btn {
                    background: $white;
                    border-radius: 0;
                    color: $blue;
                    font-size: 1.25rem;
                    font-weight: 600;
                    padding: 0.5rem 0.9375rem;
                    text-transform: uppercase;

                    &:hover {
                        background: $dark-gray;
                        color: $white;
                    }
                }
            }
            
            &.dark-gray {
                .row {
                     padding: 1.875rem 3.75rem;
                    
                    h3 {
                        font-size: 1.4rem;
                        line-height: 2rem;
                    }
                    
                    .btn {
                        background: $blue;
                        border-radius: 0;
                        color: $white;
                        font-size: 1.125rem;
                        font-weight: 600;
                        margin-top: 0.625rem;
                        padding: 0.75rem 2.5rem;
                        text-transform: uppercase;
                        
                        &:hover {
                            background: $white;
                            color: $blue;
                        }
                        
                        @media only screen and (max-width: $screen-sm) {
                            margin-top: 0;
                        }
                    }
                    
                    @media only screen and (max-width: $screen-sm) {
                        padding: 1.25rem;
                    }
                }
            }
        }
        
        &.contact-form {
			position: relative;
			z-index: 2;
			
            .section-content {
                .eloqua {
                    margin: 0 auto;
                }
                
                p {
                    color: $white;
                    font-size: 1.375rem;
                    font-weight: 600;
                    padding: 0.375rem 0.6875rem;
                    text-transform: uppercase;

                    &.required-fields {
                        font-size: 0.8125rem;
                        font-weight: normal;
                    }
                }


                label {
                    color: $white;

                    &.hidden {
                        display: none;
                    }
                }

                input {
                    border: none;
                }

                .LV_invalid {
                    color: #cc0000;
                    display: block;
                    font-size: 0.875rem;
                    font-weight: 600;
                    padding: 0.1875rem 0.1875rem 0 0.1875rem;
                }

                .LV_invalid_field {
                    border: 0.125rem solid #cc0000;
                }
                
                .LV_valid {
                    color: $white;
                    display: none;
                }

                .btn {
                    background: $white;
                    border-radius: 0;
                    color: $blue;
                    font-size: 1.25rem;
                    font-weight: 600;
                    margin-top: 1.25rem;
                    padding: 0.5rem 3.125rem;
                    text-transform: uppercase;

                    &:hover {
                        background: $dark-gray;
                        color: $white;
                    }
                }
            }
        }
        
        
        
        &.dark-gray {
            .row {
                background: $dark-gray;
                
                &.section-content {
                    h3, p {
                        color: $white;
                    }
                }
            }
        }
        
        &.light-gray {
            .row {
                background: $light-gray;
                
                &.section-content {
                    h3, p, ul {
                        color: $dark-gray;
                    }
                    
                    .blue-title {
                        color: $blue;
                    }
                }
            }
            
            &.trademark {
                .row {
                    &.section-content {
                        padding: 0.625rem;
                    
                        p {
                            font-size: 0.625rem;
                        }
                    }
                }
            }
        }
        
        &.blue {
            .row {
                background: $blue;
                
                &.section-content {
                    h3, p {
                        color: $white;
                    }
                }
            }
        }
        
        &.access {
            img {
                display: inline-block;
                margin: 0 15px 0 0;
                vertical-align: middle;
                max-width: 230px;
                
                &:last-child {
                    margin: 0 0 0 45px;
                    
                    @media only screen and (max-width: $screen-sm) {
                        display: inline-block;
                        margin: 50px 0 0 20px;
                    }
                }
                
                @media only screen and (max-width: $screen-sm) {
                    display: block;
                    margin: 0 auto;
                }
            }
            
            .cellphone {
                max-width: 130px;
                
                @media only screen and (max-width: $screen-sm) {
                    margin-top: 0.625rem;
                }
            }
            
            .blue-title {
                display: inline-block;
                line-height: 27px;
                vertical-align: middle;
                width: 19%;
                
                .fa {
                    font-size: 65px;
                    margin: -34px 0 0 17px;
                    position: absolute;
                }
                
                @media only screen and (max-width: $screen-sm) {
                    margin-top: 66px;
                    width: 23%;
                }
            }
        }
        
        &.bluetooth {
            .section-content {
                .content-group {
                    .details {
                        p {
                            font-size: 0.8125rem;
                            width: 40%;
                            
                            @media only screen and (max-width: $screen-sm) {
                                margin-bottom: 0;
                                width: 100%;
                            }
                        }
                        
                        .lock-box {
                            margin-top: 0.9375rem;
                            
                            &:last-child {
                                margin-left: 1.563rem;
                                
                                @media only screen and (max-width: $screen-sm) {
                                    margin-left: 0;
                                }
                            }
                            
                            img {
                                display: block;
                                float: none;
                                margin: 0.625rem auto 1.25rem;
                            }

                            .portable {
                                margin-top: -88px;
                                
                                @media only screen and (max-width: $screen-sm) {
                                    margin-top: 0;
                                }
                                    
                            }

                            .blue-title {
                                font-size: 0.8125rem;
                                margin-bottom: 0.375rem;
                            }

                            .description {
                                margin-bottom: 0;
                                width: 100%;   
                            }
                        }
                        
                        .padlock {
                            margin-top: 3.625rem;
                            
                            &:last-child {
                                margin-left: 1.563rem;
                                
                                @media only screen and (max-width: $screen-sm) {
                                    margin-left: 0;
                                }
                            }
                            
                            img {
                                display: block;
                                float: none;
                                margin: 0.625rem auto 1.25rem;
                                max-width: 90px;
                            }

                            .outdoor {
                                margin-top: -53px;
                                
                                @media only screen and (max-width: $screen-sm) {
                                    margin-top: 0;
                                }
                                    
                            }

                            .blue-title {
                                font-size: 0.8125rem;
                                margin-bottom: 0.375rem;
                            }

                            .description {
                                margin-bottom: 0;
                                width: 100%;   
                            }
                            
                            @media only screen and (max-width: $screen-sm) {
                                margin-top: 0;
                            }
                        }
                    }
                }
            }
        }
        
        &.flexible {
            .content-group {
                margin-top: 2.5rem;
                
                img {
                    margin: 1.25rem 0 2.188rem;
                    max-width: 210px;
                }
                
                p {
                    font-size: 0.8125rem;
                    margin: 0 auto;
                    width: 65%;
                    
                    @media only screen and (max-width: $screen-sm) {
                        width: 100%;
                    }
                }
                
                .blue-border {
                    border-left: 1px solid $blue;
                    border-right: 1px solid $blue;
                    
                    @media only screen and (max-width: $screen-sm) {
                        border: none;
                    }
                }
                
                .col {
                    @media only screen and (max-width: $screen-sm) {
                        margin-bottom: 25px;
                    }
                }
            }
        }
        
        .details {
            margin-top: 2.813rem;
                        
            h3 {
                color: $dark-gray;
                font-size: 1.5rem;
                font-weight: 600;
            }
                        
            .blue-title {
                color: $blue;
            }
                        
            ul {
                color: $dark-gray;
                float: left;
                margin-top: 0.9375rem;
                width: 65%;
                            
                li {
                    margin: 0 0 1.2rem;
                                
                    &:last-child {
                        margin: 0;
                    }
                }
                            
                @media only screen and (max-width: $screen-sm) {
                    float: none;
                    width: 100%;
                }
            }
                        
            img {
                float: left;
                margin: 0.625rem 0 0 1.563rem;
                max-width: 135px;
                            
                @media only screen and (max-width: $screen-sm) {
                    float: none;
                    margin: 0.625rem auto 0;
                }
            }
                        
            @media only screen and (max-width: $screen-sm) {
                margin-top: 0;
            }
        }
        
        &.footer {
            .row {
                &.section-content {
                    padding: 0;
                    
                    #site-footer {
                        background: $light-gray;
                        border-top: 3px solid $gray-border;
                        color: $dark-gray;
                        padding: 0;
                        
                        a {
                            color: $dark-gray;
                        }
                        
                        .row {
                            padding: 1.25rem;
                            
                            .utility {
                                .list-inline {
                                    margin: 0;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}