/*======================== Page: Bluetooth Landing Page ======================= */
.mobile-nav-visible #container {
    -webkit-transform: translateX(260px);
	transform: translateX(260px);
    
    @media only screen and (min-width: 1025px) {
        -webkit-transform: none;
        transform: none;
    }
}

.bluetooth-elocks {
    background: #292929;
    overflow-x: hidden;
    
    #container {
        min-width: 320px;   
    }
    
    .lazy {
        opacity: 0;
    } 
    
    .fancybox-overlay {
        background: rgba(0,0,0,0.85);
    }
    
    #site-header {
        background: rgba(0,0,0,0.7);
        height: 93px;
        padding: 0;
        position: fixed;
        
        .logo-link {
            .logo {
                margin: 20px;
                width: 100%;
                max-width: 228px;

                @media only screen and (max-width: 1024px) {
                    margin: 13px 10px;
                    width: 95%;
                }
            }
            
            @media only screen and (max-width: 1024px) {
                float: none;
                padding: 0;
            }
        }
        
        #site-nav {
            float: left;
            position: relative;
            z-index: 100;
            top: inherit;
            left: inherit;
            margin: 0 auto;
            width: 80%;
            height: 100%;
            overflow: hidden;
            text-align: center;
            
            .nav {
                margin: 0 auto;
                overflow: visible;
                text-align: center;
                width: 100%;
                max-width: 970px;

                .nav-item {
                    &.intro-eu {
                        a {
                            padding-top: 34px !important;
                        }
                    }
                    
                    .nav-link {
                        color: #fff;
                        font-family: "Open Sans",sans-serif;
                        font-weight: 300;
                        height: 93px;
                        line-height: 23px;
                        padding: 26px 30px 0;
                        text-transform: none;

                        &.active {
                            background: rgba(0,126,251,0.85);
                            
                            &:hover {
                                background: rgba(0,126,251,0.85);
                            }
                        }
                        
                        &:hover{
                            background: rgba(0,126,251,0.5);
                        }
                        
                        @media only screen and (max-width: 1480px) {
                            font-size: 14px;
                            padding: 26px 15px 0;
                        }
                    }

                    span {
                        display: block;
                    }
                }
            }
            
            @media only screen and (max-width: 1480px) {
                width: 73%;   
            }
        }
        
        .app-bar {
            .fa-2x {
                font-size: 1.5em;
            }
            
            @media only screen and (max-width: 1024px) {
                border: none;
                float: left;
                padding-top: 15px;
                width: 100px;
            }
            
            @media only screen and (max-width: 380px) {
                width: 55px;
            }
            
            .col {
                @media only screen and (max-width: 1024px) {
                    background: none;   
                }
                
                .btn {
                    &:hover {
                        @media only screen and (max-width: 1024px) {
                            color: #fff;
                        }
                    }
                    
                    @media only screen and (max-width: 1024px) {
                        background: none;
                        border: none;
                        margin: 0;
                        padding: 0;
                    }
                }
            }
        }
        
        .btn {
            float: right;
            font-size: 28px;
            font-weight: 600;
            margin: 16px 10px 0 0;
            padding: 0.4em 0;
            text-transform: uppercase;
            width: 100%;
            max-width: 195px;
            
            &.btn-eu {
                float: right;
                font-size: 28px;
                font-weight: 600;
                margin: 16px 10px 0 0;
                padding: 0.4em 0;
                text-transform: uppercase;
                width: 100%;
                max-width: 235px;
            }
        }

        @media only screen and (max-width: 1024px) {
            background: #000;
            height: 80px;
            position: relative;
            text-align: center;
        }
    }
    
    #mobile-nav {
        background: #292929;
        border: none;
        text-align: right;
        
        .nav-link {
            padding: 22px 20px;
            vertical-align: top;
            
            &:after {
                content: "";
                display: inline-block;
                width: 0;
                height: 0;
                border-top: 12px solid transparent;
                border-bottom: 12px solid transparent;
                border-left: 12px solid #009be3;
                margin: -2px 0 0 15px;
            }
            
            &.return {
                &:before {
                    content: "";
                    display: inline-block;
                    width: 0;
                    height: 0;
                    border-top: 12px solid transparent;
                    border-bottom: 12px solid transparent;
                    border-right: 12px solid #009be3;
                    margin: -3px 15px 0 0;
                }
                
                &:after {
                    display: none;
                }
            }
        }
    }
    
    
    .section-content {
        z-index: 0;
        
        &.row {
            max-width: none;   
        }
        
        .col {
            @media only screen and (max-width: 1024px) {
                padding: 0 8%;
            }
        }
        
        h1, h2 {
            font-family: Arial, 'Helvetica Neue', Helvetica, sans-serif;
        }
        
        .content {
            height: 100vh;
            min-height: 100%;
            
            .testimonial {
                text-align: center;
                    
                p {
                    font-size: 19px;
                    font-style: italic;
                        
                    .quote {
                        font-family: Impact, Charcoal, sans-serif;
                        font-size: 40px;
                        font-style: normal;
                        position: absolute;
                            
                        &.first {
                            margin: -11px 0 0 -15px;
                        }
                            
                        &.last {
                            margin: -10px 0 0 5px;
                        }
                    }
                        
                    .author {
                        display: block;
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 700;
                        margin-top: 6px;
                    }
                }
            }
            
            @media only screen and (max-width: 1024px) {
                box-shadow: none !important;
                height: 100% !important;
            }
        }
        
        &#scene-1 {
            background: #000;
            z-index: 7;
            
            .content {
                width: 100%;
                
                video {
                    position: fixed !important;
                    height: auto !important;
                    width: 105% !important;
                    max-width: 105% !important;
                }
                
                .scroll-text {
                    bottom: 0;
                    position: absolute;
                    width: 100%;
                    
                    h3 {
                        color: #fff;
                        font-family: "Oswald",sans-serif;
                        font-size: 1.8em;
                        letter-spacing: 1px;
                        margin: 0;
                        text-shadow: 1px 1px 8px rgba(0,0,0,0.8);
                        text-transform: uppercase;
                    }
                    
                    .arrow-down {
                        height: 0; 
                        border-left: 43px solid transparent;
                        border-right: 43px solid transparent;
                        border-top: 43px solid #009be3;
                        margin: 9px auto 15px;
                        text-align: center;
                        width: 0; 
                    }
                }
            }
        }
        
        &#scene-2 {
            background: #fff;
            z-index: 12;
            
            .content {
                background: url('../img/bluetooth/bg-scene-2.jpg') no-repeat 0 0 fixed;
                -webkit-background-size: cover;
                -moz-background-size: cover;
                -o-background-size: cover;
                background-size: cover;
                
                .testimonial {
                    margin: -70px 0 45px 232px;
                    width: 45%;
                    
                    p {
                        font-size: 19px;
                    }
                }
                
                .col {                    
                    &.padlocks {
                        margin-top: 13%;
                        padding-right: 50px;
                        text-align: right;
                        
                        @media only screen and (max-width: 1024px) {
                            margin-top: 7%;
                            padding-right: 8%;
                            text-align: center;
                        }
                    }
                    
                    &.access {
                        margin: 15% 0 0;
                        padding-right: 130px;
                        
                        @media only screen and (max-width: 1024px) {
                            margin: 7% 0 0;
                            padding: 0;
                        }
                    }
                }
                
                
                h1 {
                    font-size: 90px;
                    font-weight: 600;
                    line-height: 96px;
                    text-transform: uppercase;
                    
                    span {
                        display: block;
                    }
                    
                    @media only screen and (max-width: 1024px) {
                        font-size: 50px;
                        line-height: 54px;
                        padding: 0 8%;
                    }
                    
                    @media only screen and (max-width: 450px) {
                        font-size: 40px;
                        line-height: 47px;
                    }
                }
                
                p {
                   font-size: 25px;
                    
                    @media only screen and (max-width: 1024px) {
                        font-size: 20px;
                        padding: 0 8%;
                    }
                }
                
                .video-link {
                    
                    .video {
                        background: url('../img/bluetooth/play-icon.png') no-repeat 0 0;
                        display: block;
                        float: left;
                        height: 148px;
                        margin: 35px 0 0 130px;
                        width: 148px;

                        @media only screen and (max-width: 1024px) {
                            float: none;
                            margin: 5% auto;   
                        }
                    }
                    
                    &:hover {
                        color: #009be3;
                        text-decoration: none;

                        .video {
                            background: url('../img/bluetooth/play-icon.png') no-repeat -148px 0;
                            cursor: pointer;
                            text-decoration: none;

                            @media only screen and (max-width: 1024px) {
                                background: url('../img/bluetooth/play-icon.png') no-repeat 0 0;
                            }
                        }

                        .video-text {
                            color: #009be3;
                            text-decoration: none;

                            @media only screen and (max-width: 1024px) {
                                color: #fff;
                            }
                        }
                    }
                    
                    @media only screen and (max-width: 1024px) {
                        background: url('../img/bluetooth/bg-mobile-watch-video.jpg') no-repeat top center;
                        -webkit-background-size: cover;
                        -moz-background-size: cover;
                        -o-background-size: cover;
                        background-size: cover;
                        display: block;
                        padding: 80px 0;
                        margin: 55px 0 0;
                    }
                }
                
                .video-text {
                    color: #000;
                    display: block;
                    float: left;
                    font-size: 22px;
                    line-height: 25px;
                    margin: 75px 0 0 0;
                    padding-left: 15px; 
                    text-align: center;
                    text-transform: uppercase;
                    width: 22%;
                    
                    span {
                        font-size: 32px;
                        font-weight: 600;
                    }
                    
                    @media only screen and (max-width: 1024px) {
                        color: #fff;
                        clear: both;
                        float: none;
                        line-height: 28px;
                        margin: 0 auto;
                        padding: 0;
                        width: 148px;
                    }
                }
                
                @media only screen and (max-width: 1024px) {
                    background: url('../img/bluetooth/bg-scene-2.jpg') no-repeat center top;
                    -webkit-background-size: auto;
                    -moz-background-size: auto;
                    -o-background-size: auto;
                    background-size: auto;
                }
            }
        }
        
        @media only screen and (max-width: 1024px) {
            padding: 0;
        }
        
        &#scene-3 {
            background: #000;
            z-index: 13;
            
            .content {
                background: url('../img/bluetooth/bg-scene-3.jpg') no-repeat 0 0 fixed #000;
                -webkit-background-size: cover;
                -moz-background-size: cover;
                -o-background-size: cover;
                background-size: cover;
                
                .col { 
                    &.no-icons {
                        margin: 10% 0 0;
                        padding-left: 120px;
                        text-align: center;
                        
                        .no-key {
                            margin-bottom: 45px;   
                        }
                        
                        h4 {
                            color: #fff;
                            font-size: 27px;
                            margin-top: 10px;
                            
                            @media only screen and (max-width: 1024px) {
                                color: #000;
                                font-size: 36px;
                                font-weight: 600;
                            }
                        }
                        
                        @media only screen and (max-width: 1024px) {
                            margin-top: 10%;
                            padding: 0;
                        }
                    }
                    
                    &.combos {
                        color: #fff;
                        margin-top: 20%;
                        padding-right: 280px;
                        
                        @media only screen and (max-width: 1024px) {
                            margin: 5% 0;
                            padding-right: 8%;
                        }
                    }
                }
                
                
                h2 {
                    font-size: 75px;
                    font-weight: 600;
                    line-height: 85px;
                    text-transform: uppercase;
                    
                    span {
                        display: block;
                    }
                }
                
                p {
                    font-size: 27px;
                    line-height: 38px;
                    
                    @media only screen and (max-width: 1024px) {
                        color: #000;   
                    }
                }
                
                .testimonial {
                    margin: 125px 0 0;
                    text-align: center;

                    p {
                        color: #95cce6;
                        font-size: 19px;
                        font-style: italic;
                        line-height: 1.4;

                        .quote {
                            font-family: Impact, Charcoal, sans-serif;
                            font-size: 40px;
                            font-style: normal;
                            position: absolute;

                            &.first {
                                margin: -11px 0 0 -15px;
                            }

                            &.last {
                                margin: -10px 0 0 5px;
                            }
                        }

                        .author {
                            display: block;
                            font-size: 16px;
                            font-style: normal;
                            font-weight: 700;
                            margin-top: 6px;
                        }
                    }
                }
                
                @media only screen and (max-width: 1024px) {
                    background: none;
                    background-color: #d7d7d7;
                }
            }
        }
        
        &#scene-4 {
            background: #000;
            z-index: 7;
            
            .content {
                width: 100%;
                
                video {
                    position: fixed !important;
                    height: auto !important;
                    width: 105% !important;
                    max-width: 105% !important;
                }
                
                .col {
                    color: #fff;
                    padding: 9% 0 0 3%;
                    text-shadow: 1px 1px 14px rgba(0,0,0,0.6);
                    
                    h2 {
                        font-size: 70px;
                        font-weight: 600;
                        line-height: 80px;
                        text-transform: uppercase;
                        
                        span {
                            color: #009be3;
                            display: block;
                            
                            @media only screen and (max-width: 1024px) {
                                display: inline;   
                            }
                        }
                        
                        @media only screen and (max-width: 1024px) {
                            font-size: 50px;
                            line-height: 54px;
                        }
                    }
                    
                    p {
                        font-size: 27px;
                        line-height: 38px;
                        width: 77%;
                        
                        @media only screen and (max-width: 1024px) {
                            font-size: 23px;
                            line-height: 36px;
                            margin: 700px 0 70px 0;
                            text-shadow: 1px 1px 5px rgba(0,0,0,1);
                            width: 100%;
                        }
                    }
                    
                    @media only screen and (max-width: 1024px) {
                        padding: 8% 8% 0;
                    }
                }
                
                @media only screen and (max-width: 1024px) {
                    background: url('../img/bluetooth/bg-mobile-scene-4.jpg') no-repeat center top #000;
                    -webkit-background-size: cover;
                    -moz-background-size: cover;
                    -o-background-size: cover;
                    background-size: cover;
                    width: 100%;
                }
            }
        }
        
        &#scene-5 {
            z-index: 5;
            
            .content {
                background: #e4e4e4;
                min-height: 100%;
                text-align: center;
                padding-bottom: 140%;
                
                .transition {
                    position: fixed;
                    top: 0;
                    
                    .equation {
                        padding-top: 11%;
                        text-align: right;

                        .inline {
                            display: inline-block;
                            margin: 0 2%;
                            vertical-align: middle;
                        }
                        
                        .lock-4401 {
                            opacity: 1;   
                        }
                        
                        .lock-4400 {
                            margin: 75px -230px;
                            opacity: 0;
                            position: absolute;
                        }
                    }

                    .rotator {
                        padding-top: 10%;

                        img {
                            display: block;
                            margin-bottom: 30px;
                        }
                        
                        .outdoors {
                            opacity: 1;   
                        }
                        
                        .indoors {
                            margin: -512px 0 0 28px;
                            opacity: 0;
                            position: absolute;
                            
                            img {
                                margin: 0 auto 30px;
                            }
                        }
                    }
                }
                
                .text-area {
                    margin: 0 auto;
                    position: relative;
                    width: 60%;
                    
                    h2 {
                        color: #212121;
                        font-size: 70px;
                        font-weight: 600;
                        line-height: 80px;
                        margin-top: 30px;
                        text-transform: uppercase;

                        span {
                            color: #009be3;
                        }
                    }

                    p {
                        font-size: 25px;
                        line-height: 36px;
                        margin-bottom: 0;
                        text-align: left;
                    }
                }
                
                @media only screen and (max-width: 1024px) {
                    padding-bottom: 0;
                }
            }
        }
        
        &#scene-6 {
            background: #000;
            z-index: 6;
            
            .text-area {
                color: #fff;
                position: fixed;
                padding: 31% 0 0 3%;
                text-shadow: 1px 1px 14px rgba(0,0,0,0.6);
                top: 0;
                z-index: 6;
                    
                h2 {
                    font-size: 70px;
                    font-weight: 600;
                    line-height: 70px;
                    text-transform: uppercase;

                    span {
                        color: #009be3;
                        display: block;
                    }
                }

                p {
                    font-size: 25px;
                    line-height: 36px;
                    margin-bottom: 0;
                    position: absolute;
                    text-align: left;
                    width: 100%;
                }
            }
                
            .hand {
                opacity: 0;
                position: fixed;
                right: 60px;
                bottom: 0;
                
                &.home-hand {
                    z-index: 7;   
                }
                
                &.beach-hand {
                    z-index: 6;
                }
                
                &.office-hand {
                    z-index: 5;
                }
                
                @media only screen and (max-width: 1024px) {
                    opacity: 1;
                }
            }
            
            .home-text {
                opacity: 0;
                position: absolute;
                z-index: 6;
            }
            
            .beach-text {
                opacity: 0;
                position: absolute;
                z-index: 6;
            }
            
            .office-text {
                opacity: 0;
                position: absolute;
                z-index: 6;
            }
            
            .content {
                background: #000;
                height: 300%;
                
                .text-area {
                    color: #fff;
                    position: absolute;
                    padding: 31% 0 0 3%;
                    text-shadow: 1px 1px 14px rgba(0,0,0,0.6);
                    
                    h2 {
                        font-size: 70px;
                        font-weight: 600;
                        line-height: 70px;
                        text-transform: uppercase;

                        span {
                            color: #009be3;
                            display: block;
                        }
                        
                        @media only screen and (max-width: 1024px) {
                            font-size: 38px;
                            line-height: 43px;
                            position: relative;
                            z-index: 1;
                        }
                    }

                    p {
                        font-size: 25px;
                        line-height: 36px;
                        margin-bottom: 0;
                        text-align: left;
                        width: 33%;
                    }
                    
                    @media only screen and (max-width: 1024px) {
                        position: relative;
                        padding: 31% 4% 1%;   
                    }
                }
                
                .hand {
                    display: none;
                    position: absolute;
                    right: 60px;
                    bottom: 0;
                    
                    @media only screen and (max-width: 1024px) {
                        right: 10px;
                        width: 43%;
                        z-index: 0;
                    }
                }
                
                .scene {
                    box-shadow: 0px -10px 30px rgba(0,0,0,0.2);
                    height: 100%;
                    
                    &.home {
                        background: url('../img/bluetooth/bg-scene-6-home.jpg') no-repeat 0 0 fixed #000;
                        -webkit-background-size: cover;
                        -moz-background-size: cover;
                        -o-background-size: cover;
                        background-size: cover;
                        position: relative;
                        height: 100vh;
                        min-height: 1000px;
                        
                        .hand {
                            z-index: 2; 
                        }
                        
                        @media only screen and (max-width: 1024px) {
                            background-attachment: inherit;
                            display: block;
                            height: 100%;
                            min-height: inherit;
                        }
                    }

                    &.beach {
                        background: url('../img/bluetooth/bg-scene-6-beach.jpg') no-repeat 0 0 fixed #000;
                        -webkit-background-size: cover;
                        -moz-background-size: cover;
                        -o-background-size: cover;
                        background-size: cover;
                        position: relative;
                        height: 104vh;
                        min-height: 1000px;
                        
                        .hand {
                            z-index: 1; 
                        }
                        
                        @media only screen and (max-width: 1024px) {
                            background-attachment: inherit;
                            display: block;
                            height: 100%;
                            min-height: inherit;
                        }
                    }

                    &.office {
                        background: url('../img/bluetooth/bg-scene-6-office.jpg') no-repeat 0 0 fixed #000;
                        -webkit-background-size: cover;
                        -moz-background-size: cover;
                        -o-background-size: cover;
                        background-size: cover;
                        position: relative;
                        height: 104vh;
                        min-height: 1000px;
                        
                        .hand {
                            z-index: 0; 
                        }
                        
                        @media only screen and (max-width: 1024px) {
                            background-attachment: inherit;
                            display: block;
                            height: 100%;
                            min-height: inherit;
                        }
                    }
                    
                    @media only screen and (max-width: 1024px) {
                        position: relative;
                    }
                }
                
                 @media only screen and (max-width: 1024px) {
                    margin-bottom: 0;   
                 }
            }
        }
        
        &#scene-7 {
            z-index: 17;
            
            .content {
                background: url('../img/bluetooth/bg-scene-7.jpg') no-repeat 0 0;
                -webkit-background-size: cover;
                -moz-background-size: cover;
                -o-background-size: cover;
                background-size: cover;
                box-shadow: 0px -10px 30px rgba(0,0,0,0.2);
                min-height: 100%;
                padding: 0 2% 2%;
                
                .flexslider {
                    color: #000;
                    height: 100%;
                    max-width: none;
                    
                    img {
                        display: inline-block;
                    }
                    
                    .flex-viewport {
                        min-height: 100%;
                        
                        .caption {
                            padding: 9% 0 7%;
                            text-align: center;
                            
                            h2 {
                                font-size: 60px;
                                font-weight: 600;
                                text-transform: uppercase;
                                
                                span {
                                    color: #009be3;
                                }
                                
                                @media only screen and (max-width: 1024px) {
                                    font-size: 52px;
                                    line-height: 61px;
                                }
                            }
                            
                            @media only screen and (max-width: 1024px) {
                                padding: 7% 0 6%;
                            }
                        }
                        
                        .col {
                            
                            &.image {
                                padding: 0.6% 9% 0 0;
                                text-align: right;
                                
                                @media only screen and (max-width: 1024px) {
                                    padding: 0 0 6%;
                                    text-align: center;
                                }
                            }

                            &.desc {
                                color: #000;
                                padding: 0;
                                position: relative;
                                text-align: left;
                                
                                ul {
                                    @media only screen and (min-width: 768px) and (max-width: 1024px) {
                                        margin: 0 auto;
                                        width: 60%;
                                    }
                                }
                                
                                li {
                                    font-size: 22px;
                                    list-style: none;
                                    padding-bottom: 1.7%;
                                    
                                    @media only screen and (max-width: 1024px) {
                                        font-size: 18px;
                                        padding-bottom: 2.5%;
                                    }
                                }
                                
                                span {
                                    font-weight: 700;
                                    margin-right: 10px;
                                }
                                
                                @media only screen and (max-width: 1024px) {
                                    padding: 5% 8%;  
                                }
                            }
                        }
                        
                        .slide-2 {
                            .caption {
                                 padding: 9% 0 4% 0;
                                
                                @media only screen and (max-width: 1024px) {
                                    padding: 7% 0 3% 0;
                                }
                            }
                            
                            .image {
                                padding: 0 9% 0 0;
                                
                                @media only screen and (max-width: 1024px) {
                                    padding: 0 0 6%;
                                    text-align: center;
                                }
                            }
                            
                            .desc {
                                padding-top: 3%;
                            }
                        }
                        
                        .slide-3 {
                            text-align: center;
                            
                            .caption {
                                 padding: 9% 0 2% 0;
                            }
                            
                            .desc {
                                color: #000;
                                margin: 0 auto;
                                padding-left: 0;
                                position: relative;
                                text-align: left;
                                
                                h3 {
                                    font-size: 31px;
                                    font-weight: 600;
                                    padding-top: 9%;
                                    
                                    @media only screen and (max-width: 1024px) {
                                        padding-top: 4%;   
                                    }
                                }
                                
                                p {
                                    font-size: 20px;
                                    margin: 17px 0 0 2px;
                                    width: 55%;
                                    
                                    @media only screen and (max-width: 1024px) {
                                        width: 100%;   
                                    }
                                }
                                
                                .images {
                                    margin-top: 25px;
                                    
                                    a {
                                        margin-right: 25px;
                                        
                                        @media only screen and (max-width: 1024px) {
                                            display: block;
                                            margin-bottom: 13px;
                                        }
                                    }
                                    
                                    @media only screen and (max-width: 1024px) {
                                        text-align: center;
                                    }
                                }
                                
                                @media only screen and (max-width: 1024px) {
                                    padding: 0 8% 12%;   
                                }
                            }
                        }
                        
                        .slide-4 {
                            .caption {
                                padding: 9% 0 1% 0;
                                
                                h2 {
                                    text-transform: none;
                                }
                            }
                            
                            .desc {
                                margin: 0 auto;
                                
                                dt {
                                    font-size: 25px;
                                    font-weight: 700;
                                    padding: 30px 0 5px;
                                }
                                
                                dd {
                                    font-size: 19px;
                                    line-height: 29px;
                                    
                                    @media only screen and (max-width: 1024px) {
                                        margin: 0; 
                                    }
                                }
                                
                                a {
                                    color: #009be3;
                                    text-decoration: underline;
                                    
                                    &:hover {
                                        text-decoration: none;
                                    }
                                }
                                
                                @media only screen and (max-width: 1024px) {
                                    padding: 0 12%;   
                                }
                            }
                            
                            .faqs {
                                margin: 3% auto 1%;
                                text-align: center;
                                
                                a {
                                    background: #009be3;
                                    color: #fff;
                                    font-size: 21px;
                                    font-weight: 600;
                                    padding: 10px 25px;
                                    text-transform: uppercase;
                                    
                                    &:hover {
                                        background: #000;
                                        text-decoration: none;
                                        
                                        @media only screen and (max-width: 1024px) {
                                            background: #009be3;
                                        }
                                    }
                                }
                                
                                @media only screen and (max-width: 1024px) {
                                    margin: 10% auto 17%;
                                }
                            }
                        }
                    }
                    
                    .flex-control-nav {
                        li {
                            a {
                                background-color: #dcdcdc;

                                &.flex-active {
                                    background-color: #009be3;
                                }
                                
                                @media only screen and (max-width: 1024px) {
                                    height: 20px;
                                    width: 20px;
                                }
                            }
                        }
                        
                        @media only screen and (max-width: 1024px) {
                            margin-bottom: 1em;
                        }
                    }
                    
                    .flex-direction-nav {
                        .flex-prev {
                            background-image: url("../img/bluetooth/flex-sprite.png");
                            background-position: 0 0;
                            height: 103px;
                            margin-top: 0.5%;
                            transition: none;
                            width: 103px;
                            
                            &:hover {
                                background-position: 0 -103px;
                            }
                        }
                        
                        .flex-next {
                            background-image: url("../img/bluetooth/flex-sprite.png");
                            background-position: -103px 0;
                            height: 103px;
                            margin-top: 0.5%;
                            transition: none;
                            width: 103px;
                            
                            &:hover {
                                background-position: -103px -103px;
                            }
                        }
                        
                        @media only screen and (max-width: 1024px) {
                            display: none;
                        }
                    }
                }
            }
            
                &.eu-scene-7 {
                    z-index: 17;

                    .content {
                        background: url('../img/bluetooth/bg-scene-7.jpg') no-repeat 0 0;
                        -webkit-background-size: cover;
                        -moz-background-size: cover;
                        -o-background-size: cover;
                        background-size: cover;
                        box-shadow: 0px -10px 30px rgba(0,0,0,0.2);
                        min-height: 100%;
                        padding: 0 2% 2%;

                        .flexslider {
                            color: #000;
                            height: 100%;
                            max-width: none;

                            img {
                                display: inline-block;
                            }

                            .flex-viewport {
                                min-height: 100%;

                                .caption {
                                    padding: 9% 0 3%;
                                    text-align: center;

                                    h2 {
                                        font-size: 60px;
                                        font-weight: 600;
                                        text-transform: uppercase;

                                        span {
                                            color: #009be3;
                                        }

                                        @media only screen and (max-width: 1024px) {
                                            font-size: 52px;
                                            line-height: 61px;
                                        }
                                    }

                                    @media only screen and (max-width: 1024px) {
                                        padding: 7% 0 6%;
                                    }
                                }

                                .col {

                                    &.image {
                                        padding: 0.6% 9% 0 0;
                                        text-align: right;

                                        @media only screen and (max-width: 1024px) {
                                            padding: 0 0 6%;
                                            text-align: center;
                                        }
                                    }

                                    &.desc {
                                        color: #000;
                                        padding: 0;
                                        position: relative;
                                        text-align: left;

                                        ul {
                                            @media only screen and (min-width: 768px) and (max-width: 1024px) {
                                                margin: 0 auto;
                                                width: 60%;
                                            }
                                        }

                                        li {
                                            font-size: 22px;
                                            list-style: none;
                                            padding-bottom: 1.7%;

                                            &.battery {
                                                width: 68%;

                                                img {
                                                    float: right;
                                                    margin-top: -18px;

                                                    @media only screen and (max-width: 1024px) {
                                                        display: none !important;    
                                                    }
                                                }

                                                @media only screen and (max-width: 1024px) {
                                                    width: auto;
                                                }
                                            }

                                            @media only screen and (max-width: 1024px) {
                                                font-size: 18px;
                                                padding-bottom: 2.5%;
                                            }
                                        }

                                        span {
                                            font-weight: 700;
                                            margin-right: 10px;
                                        }

                                        @media only screen and (max-width: 1024px) {
                                            padding: 5% 8%;  
                                        }
                                    }
                                }

                                .slide-2 {
                                    .caption {
                                         padding: 9% 0 4% 0;

                                        @media only screen and (max-width: 1024px) {
                                            padding: 7% 0 3% 0;
                                        }
                                    }

                                    .image {
                                        padding: 0 9% 0 0;

                                        @media only screen and (max-width: 1024px) {
                                            padding: 0 0 6%;
                                            text-align: center;
                                        }
                                    }

                                    .desc {

                                        li {
                                            &.battery {
                                                    width: 68%;

                                                    img {
                                                        float: right;
                                                        margin-top: -23px;

                                                        @media only screen and (max-width: 1024px) {
                                                            display: none !important;    
                                                        }
                                                    }

                                                    @media only screen and (max-width: 1024px) {
                                                        width: auto;
                                                    }
                                                }
                                        }
                                    }
                                }

                                .slide-3 {
                                    text-align: center;

                                    .caption {
                                         padding: 9% 0 2% 0;
                                    }

                                    .desc {
                                        color: #000;
                                        margin: 0 auto;
                                        padding-left: 0;
                                        position: relative;
                                        text-align: left;

                                        h3 {
                                            font-size: 31px;
                                            font-weight: 600;
                                            padding-top: 9%;

                                            @media only screen and (max-width: 1024px) {
                                                padding-top: 4%;   
                                            }
                                        }

                                        p {
                                            font-size: 20px;
                                            margin: 17px 0 0 2px;
                                            width: 55%;

                                            @media only screen and (max-width: 1024px) {
                                                width: 100%;   
                                            }
                                        }

                                        .images {
                                            margin-top: 25px;

                                            a {
                                                margin-right: 25px;

                                                @media only screen and (max-width: 1024px) {
                                                    display: block;
                                                    margin-bottom: 13px;
                                                }
                                            }

                                            @media only screen and (max-width: 1024px) {
                                                text-align: center;
                                            }
                                        }

                                        @media only screen and (max-width: 1024px) {
                                            padding: 0 8% 12%;   
                                        }
                                    }
                                }

                                .slide-4 {
                                    .caption {
                                        padding: 9% 0 1% 0;

                                        h2 {
                                            text-transform: none;
                                        }
                                    }

                                    .desc {
                                        margin: 0 auto;

                                        dt {
                                            font-size: 25px;
                                            font-weight: 700;
                                            padding: 30px 0 5px;
                                        }

                                        dd {
                                            font-size: 19px;
                                            line-height: 29px;

                                            @media only screen and (max-width: 1024px) {
                                                margin: 0; 
                                            }
                                        }

                                        a {
                                            color: #009be3;
                                            text-decoration: underline;

                                            &:hover {
                                                text-decoration: none;
                                            }
                                        }

                                        @media only screen and (max-width: 1024px) {
                                            padding: 0 12%;   
                                        }
                                    }

                                    .faqs {
                                        margin: 3% auto 1%;
                                        text-align: center;

                                        a {
                                            background: #009be3;
                                            color: #fff;
                                            font-size: 21px;
                                            font-weight: 600;
                                            padding: 10px 25px;
                                            text-transform: uppercase;

                                            &:hover {
                                                background: #000;
                                                text-decoration: none;

                                                @media only screen and (max-width: 1024px) {
                                                    background: #009be3;
                                                }
                                            }
                                        }

                                        @media only screen and (max-width: 1024px) {
                                            margin: 10% auto 17%;
                                        }
                                    }
                                }
                            }

                            .flex-control-nav {
                                li {
                                    a {
                                        background-color: #dcdcdc;

                                        &.flex-active {
                                            background-color: #009be3;
                                        }

                                        @media only screen and (max-width: 1024px) {
                                            height: 20px;
                                            width: 20px;
                                        }
                                    }
                                }

                                @media only screen and (max-width: 1024px) {
                                    margin-bottom: 1em;
                                }
                            }

                            .flex-direction-nav {
                                .flex-prev {
                                    background-image: url("../img/bluetooth/flex-sprite.png");
                                    background-position: 0 0;
                                    height: 103px;
                                    margin-top: 0.5%;
                                    transition: none;
                                    width: 103px;

                                    &:hover {
                                        background-position: 0 -103px;
                                    }
                                }

                                .flex-next {
                                    background-image: url("../img/bluetooth/flex-sprite.png");
                                    background-position: -103px 0;
                                    height: 103px;
                                    margin-top: 0.5%;
                                    transition: none;
                                    width: 103px;

                                    &:hover {
                                        background-position: -103px -103px;
                                    }
                                }

                                @media only screen and (max-width: 1024px) {
                                    display: none;
                                }
                            }
                        }
                    }
            }
        }
        
        &#scene-8 {
            z-index: 18;
            
            .content {
                background: #d1d1d0;
                background: -moz-linear-gradient(top,  #d1d1d0 0%, #ededec 100%);
                background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,#d1d1d0), color-stop(100%,#ededec));
                background: -webkit-linear-gradient(top,  #d1d1d0 0%,#ededec 100%);
                background: -o-linear-gradient(top,  #d1d1d0 0%,#ededec 100%);
                background: -ms-linear-gradient(top,  #d1d1d0 0%,#ededec 100%);
                background: linear-gradient(to bottom,  #d1d1d0 0%,#ededec 100%);
                filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#d1d1d0', endColorstr='#ededec',GradientType=0 );
                height: 100%;
                
                .caption {
                	 h2 {
                        font-size: 54px !important;
                        padding-top: 7%;
                        padding-left: 4%;
                        padding-right: 4%;
                        text-align: center;
                        font-size: 60px;
    					font-weight: 600;
    					text-transform: uppercase;

    					span {
    						color: #009be3;
    					}

                        @media only screen and (max-width: 1024px) {
                            padding-top: 4%;   
                        }
                    }
                }

                .testimonial {
                  	width: 70%;
                  	margin: -20px auto 30px auto;

                    @media only screen and (min-width: 1025px) {
              			margin: 10px 0 0 -20px;
                    	position: absolute;
                    }
                }
                
                .col {
                    padding: 2% 0 4% 15%;
                    text-align: center;
                    
                    a {
                        color: #000;
                    }
                    
                    &.padlock-4401 {
                        img {
                            margin-bottom: -13px;
                        }    
                    }
                    
                    &.padlock-4400 {
                        padding: 2% 15% 3% 0;
                        
                        img {
                            margin: 191px 0 -13px 0;
                            
                            @media only screen and (max-width: 1024px) {
                                margin: 0 0 -13px 0;   
                            }
                        }
                        
                        @media only screen and (max-width: 1024px) {
                            padding: 0 8% 7%;
                        }
                        
                    }
                    
                	&.col-explore-bussol {
                		padding-left: 20px;
                		padding-right: 20px;
                		padding-top: 0;
                	}

                    .price-note {
                        display: block;
                        font-weight: 700;
                        margin-top: 4px;
                    }
                    
                    .btn {
                        font-size: 28px;
                        font-weight: 600;
                        margin: 5px auto 0;
                        padding: 0.5em 2em;
                        text-transform: uppercase;
                        
                        @media only screen and (max-width: 440px) {
                            padding: 0.5em 0;
                            width: 100%;   
                        }
                    }
                    
                    h4 {
                        font-size: 28px;
                        font-weight: 600;
                        margin: 15px 0 6px;
                        text-transform: uppercase;
                        
                        span {
                            color: #009be3;
                        }
                    }
                    
                    h5 {
                        font-size: 28px;   
                    }
                    
                    @media only screen and (max-width: 1024px) {
                        padding: 7% 5%;
                    }
                }

            	.btn.btn-external {
                	color: #FFFFFF;
                	min-width: 200px;
                }
                
                @media only screen and (max-width: 1024px) {
                    background: none;
                    background-color: #e8e8e5;
                }
            }
            
                &.eu-scene-8 {
                z-index: 18;

                .content {
                    background: #d1d1d0;
                    background: -moz-linear-gradient(top,  #d1d1d0 0%, #ededec 100%);
                    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,#d1d1d0), color-stop(100%,#ededec));
                    background: -webkit-linear-gradient(top,  #d1d1d0 0%,#ededec 100%);
                    background: -o-linear-gradient(top,  #d1d1d0 0%,#ededec 100%);
                    background: -ms-linear-gradient(top,  #d1d1d0 0%,#ededec 100%);
                    background: linear-gradient(to bottom,  #d1d1d0 0%,#ededec 100%);
                    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#d1d1d0', endColorstr='#ededec',GradientType=0 );
                    padding-bottom: 20%;
                    text-align: center;
                    height: 100%;

                    .col {
                        padding: 8% 0 0;

                        &.caption {
                            h2 {
                                font-size: 60px;
                                font-weight: 600;
                                text-transform: uppercase;

                                span {
                                    color: #009be3;
                                }

                                @media only screen and (max-width: 1024px) {
                                    font-size: 52px;
                                    line-height: 61px;
                                }
                            }
                        }

                        &.desc {
                            display: table;
                            padding: 0 10%;
                            
                            &.wtb-french {
                                display: none;
                            }
                            
                            &.wtb-english {
                                display: table;
                            }
                            
                            .col {
                                display: table-cell;
                                float: none;
                                vertical-align: middle;

                                img {
                                    padding: 0 50px;
                                    width: 100%;
                                    max-width: 340px;

                                    @media only screen and (max-width: 1024px) {
                                        padding: 20px 0;   
                                    }
                                }
                                
                                @media only screen and (max-width: 1024px) {
                                    display: block;
                                    float: left;
                                }
                            }

                            @media only screen and (max-width: 1024px) {
                                display: block;
                                min-height: 0;
                            }
                        }

                    }

                    @media only screen and (max-width: 1024px) {
                        background: none;
                        background-color: #e8e8e5;
                    }
                }
            }
        }
    }
    
    footer {
        background: #e1e1e1;
        border-top: 3px solid #929292;
        line-height: 28px;
        padding: 15px 0 2px;
        position: relative;
        text-align: center;
        z-index: 19;
        
        .row {
            @media only screen and (max-width: 1024px) {
                margin: 0;
                padding: 0;
            }
        }
        
        .copyright {
            display: inline-block;
            padding-right: 10px;
            
            @media only screen and (max-width: 1024px) {
                display: block;
                margin: 0;
                padding-right: 0;
            }
        }
        
        .utility {
            display: inline-block;
            
            a {
                color: #000;
                
                @media only screen and (max-width: 1024px) {
                    font-size: 10px;
                }
            }
            
            .list-inline.piped li+li:last-child {
                @media only screen and (max-width: 1024px) {
                    border-left: 0;
                    display: block;
                    margin: 0;
                    padding: 0;
                }
            }
            
            @media only screen and (max-width: 1024px) {
                line-height: 20px;   
            }
        }
        
        @media only screen and (max-width: 1024px) {
            margin: 0;
            padding: 3% 1% 1%;
        }
    }
}


/*======================== Page: Bluetooth QR Code Page ======================= */
.bluetooth-qr {
    background: #fff;
    
    .section {
        overflow: hidden;
    }
    
    &.topics {
        .top-logos {
            margin: 0 auto;
            text-align: center;

            #site-title {
                display: inline-block;
                float: none;
                margin-right: 20px;

                @media only screen and (max-width: 767px) {
                    height: 52px;
                    margin: 0.25em 0 .5em 0;
                    position: relative;
                    width: 219px;
                    z-index: 3;
                    background-position: 0px -62px;
                    background-repeat: no-repeat;
                    background-size: auto;
                }
            }
        }

        .section-content {
            &.compatible {
                padding-bottom: 0;   
            }
            
            &.download {
                margin-top: 0;
                
                h2 {
                    margin-bottom: 15px;
                    
                    @media only screen and (max-width: 767px) {
                        font-size: 49px;
                        text-align: center;        
                    }
                }
                
                .ml-app-icon {
                    @media only screen and (max-width: 1024px) {
                        text-align: center;
                    }
                }

                .app-icon {
                    padding: 0 6px;
                    text-align: center;
                    
                    img {
                        width: 100%;
                        min-width: 80%;
                        
                        @media only screen and (max-width: 767px) {
                            max-width: 75%;
                        }
                    }
                    
                    @media only screen and (max-width: 1024px) {
                        margin-top: 35px;   
                    }
                }
            }
            
            .padlocks {
                text-align: center;
                
                img {
                    max-width: 300px;

                    @media only screen and (max-width: 1024px) {
                        padding: 15px 0 40px 0;
                        max-width: 95%;
                    }
                }
            }
            
            &.menu {
                padding-bottom: 0;
                
                ul {
                    list-style: none;
                    margin: 0;
                    padding: 0;
                    
                    li {
                        margin: 0;
                        padding: 0;
                        
                        a {
                            background: #0060f0;
                            border: 1px solid rgba(0,0,0,0.5);
                            color: #fff;
                            display: block;
                            font-size: 22px;
                            font-weight: 600;
                            padding: 10px 0;
                            text-align: center;
                            
                            &:hover {
                                text-decoration: none;   
                            }
                        }
                    }
                }
            }
            
            &.social {
                margin-top: 10px;
                text-align: center;
                
                ul {
                    list-style: none;
                    margin: 0;
                    padding: 0;
                    
                    li {
                        display: inline-block;
                        height: 33px;
                        margin: 0 10px;
                        width: 40px;
                        
                        a {
                            font-size: 50px;
                            
                            &.twitter {
                                color: #00aced;   
                            }
                            
                            &.youtube {
                                color: #bb0000;
                            }
                            
                            &.facebook {
                                color: #3b5998;   
                            }
                            
                            &.pinterest {
                                color: #cb2027;
                            }
                            
                            &.google-plus {
                                color: #dd4b39;
                            }
                            
                            &.linkedin {
                                color: #007bb5
                            }
                        }
                    }
                }
            }
        }
    }
}

.europe {
    .nav-link {
        padding: 26px 26px 0 !important;
        
        @media only screen and (max-width: 900px) {
            padding: 26px 20px 0 !important;
        }
    }
}