// Variables

$fa-font-path:         "../webfonts" !default;
//== brand colors

$brand-primary: #000000 !default; //black
$brand-secondary: #bd1829 !default; //red
$brand-tertiary: #b3b3b3 !default;
$brand-primary-light: #d3d3d3 !default;
$brand-info: #5bc0de !default;
$brand-success: #090 !default;
$brand-warning: #f0ad4e !default;
$brand-danger: #c00 !default;



//== scaffolding

$body-bg: #f5f5f5 !default; //charcoal gray
$color-base: #000000 !default;

$link-color: #bf0000 !default;
$link-hover-color: darken($link-color, 15%) !default;

$site-width: 1280px !default;
$container-bg: #fff !default;
$container-width: 1200px !default;
$container-inner-width: 1280px;


//== typography

$font-family-sans-serif: 'Open Sans', sans-serif !default;
$font-family-serif: Georgia, 'Times New Roman', Times, serif !default;
$font-family-monospace: Menlo, Monaco, Consolas, 'Courier New', monospace !default;
$font-family-base: $font-family-sans-serif !default; 
$font-family-narrow: 'Oswald', sans-serif !default;

$font-size-base: 14px !default;
$line-height-base: 1.4 !default;
$font-weight-base: 500 !default; 

$font-size-large: ceil($font-size-base * 1.29) !default;	// 18px
$font-size-small: ceil($font-size-base * 1) !default;		// 14px
$font-size-smaller: ceil($font-size-base * .86) !default;	// 12px

$font-size-h1: floor($font-size-base * 2.29) !default;		// 32px
$font-size-h2: floor($font-size-base * 2) !default;	// 28px
$font-size-h3: floor($font-size-base * 1.7) !default;	// 24px
$font-size-h4: $font-size-base !default;				// 14px
$font-size-h5: ceil($font-size-base * 0.86) !default;	// 12px
$font-size-h6: ceil($font-size-base * 0.71) !default;	// 10px

$headings-font-family: $font-family-sans-serif;
$headings-line-height: 1.2 !default;
$headings-font-weight: inherit !default;
$headings-color: inherit !default;
$headings-margin: 0 0 $font-size-base; 


//== buttons

$btn-font-weight: 400 !default;
$btn-font-size: $font-size-base !default;
$btn-color: #222 !default;
$btn-hover-color: $btn-color !default;
$btn-bg: #ddd !default;
$btn-hover-bg: darken($btn-bg, 10%) !default;
$btn-focus-bg: darken($btn-bg, 15%) !default;
$btn-active-bg: darken($btn-bg, 20%) !default;
$btn-padding: .75em 1.5em !default;
$btn-padding-lg: .75em 2em !default;
$btn-padding-sm: .35em .75em !default;
$btn-border: none !default;
$btn-border-radius: 2px !default;

$btn-primary-color: #fff !default;
$btn-primary-hover-color: $btn-primary-color !default;
$btn-primary-bg: $brand-secondary !default;
$btn-primary-hover-bg: darken($btn-primary-bg, 10%) !default;
$btn-primary-focus-bg: darken($btn-primary-bg, 15%) !default;
$btn-primary-active-bg: darken($btn-primary-bg, 20%) !default;
$btn-primary-border-color: transparent !default;

$btn-info-color: #fff !default;
$btn-info-hover-color: $btn-info-color !default;
$btn-info-bg: $brand-info !default;
$btn-info-hover-bg: darken($btn-info-bg, 10%) !default;
$btn-info-focus-bg: darken($btn-info-bg, 15%) !default;
$btn-info-active-bg: darken($btn-info-bg, 20%) !default;
$btn-info-border-color: transparent !default;

$btn-success-color: #fff !default;
$btn-success-hover-color: $btn-success-color !default;
$btn-success-bg: $brand-success !default;
$btn-success-hover-bg: darken($btn-success-bg, 10%) !default;
$btn-success-focus-bg: darken($btn-success-bg, 15%) !default;
$btn-success-active-bg: darken($btn-success-bg, 20%) !default;
$btn-success-border-color: transparent !default;

$btn-warning-color: #fff !default;
$btn-warning-hover-color: $btn-warning-color !default;
$btn-warning-bg: $brand-warning !default;
$btn-warning-hover-bg: darken($btn-warning-bg, 10%) !default;
$btn-warning-focus-bg: darken($btn-warning-bg, 15%) !default;
$btn-warning-active-bg: darken($btn-warning-bg, 20%) !default;
$btn-warning-border-color: transparent !default;

$btn-danger-color: #fff !default;
$btn-danger-hover-color: $btn-danger-color !default;
$btn-danger-bg: $brand-danger !default;
$btn-danger-hover-bg: darken($btn-danger-bg, 10%) !default;
$btn-danger-focus-bg: darken($btn-danger-bg, 15%) !default;
$btn-danger-active-bg: darken($btn-danger-bg, 20%) !default;
$btn-danger-border-color: transparent !default;

$btn-lg-font-size: floor($btn-font-size * 1.12) !default; // 18px
$btn-sm-font-size: ceil($btn-font-size * 0.83) !default;  // 12px

//== forms

$input-font-size: inherit !default;
$input-bg: #fff !default;
$input-padding: .5em !default;
$input-border-color: #bbb !default;
$input-border-radius: 0 !default;

$textarea-min-height: 10em !default;

$input-focus-bg: #fff !default;
$input-focus-shadow-color: #0063ff !default;
$input-focus-border-color: #497dd0 !default;

$input-placeholder-color: #aaa !default;
$input-placeholder-font-style: italic !default;

$input-disabled-bg: #e9e9e9 !default;
$input-disabled-color: #999 !default;
$input-disabled-border-color: #bbb !default;


//== tables

$table-background: #f5f5f5 !default;
$table-border: 1px solid #fff !default;
$table-border-light: 1px solid rgba(0,0,0,0.07) !default;

$table-th-background: $brand-primary !default;
$table-th-color: #fff !default;

$table-stripe-background: darken($table-background, 5%) !default;


//== panels

$panel-color: $color-base !default;
$panel-bg: #ffffff !default;
$panel-border-color: #ffffff !default;
$panel-border-radius: 0 !default;

$panel-primary-color: #fff !default;
$panel-primary-bg: $brand-primary !default;
$panel-primary-border-color: $brand-primary !default;


//== tooltips

$tooltip-trigger-color: inherit !default;
$tooltip-trigger-hover-color: $brand-secondary !default;

$tooltip-max-width: 341px !default;
$tooltip-color: #fff !default;
$tooltip-bg: #000 !default;
$tooltip-border-radius: 0 !default;

$tooltip-arrow-width: 7px !default;
$tooltip-arrow-color: $tooltip-bg !default;


//== breadcrumbs

$breadcrumb-separator-slash: "\002F\0020";	// "/ "
$breadcrumb-separator-arrow: "\003E\0020";	// "> "
$breadcrumb-separator: $breadcrumb-separator-arrow !default;

//== screens
$screen-lg:	768px !default;
$screen-sm:	767px !default;
$screen-xs:	479px !default;