.padlock-buying-guide {
	.hero {
		margin-bottom: 0;

		ul,
		li {
			margin-bottom: 0;
		}

		.section-header {
			@media only screen and (min-width: 768px) {
				padding-top: 130px;

				.section-title {
					max-width: 7em;
				}

				p {
					width: 100%;
				}
			}
		}
	}

	.section {
		font-size: 1rem;		
	}

	.callout {
		height: auto;
		max-width: none;

		.box-content {
			display: block;
			float: none;
			width: 100%;
			max-width: 750px;
			margin: 0 auto;
			padding: 20px;
			text-align: center;

			h2 {
				display: block;
				padding-top: 0;

				@media only screen and (max-width: 767px) {
					font-size: 20px;
				}
			}
		}
	}

	.heading {
		background: $brand-secondary;
		color: #fff;
		height: 55px;
		line-height: 55px;
		margin: 0 -15px 25px;
		font-size: 23px;
		text-align: center;
		max-width: 1280px;

		@media only screen and (min-width: 768px) {
			margin-right: auto;
			margin-left: auto;
		}
	}

	.product-info {
		min-height: 0;
	}

	.best-used-for {
		margin: 0 0 2em;

		ul.row {
			li {
				display: block;
			}
		}

		.title {
			display: flex;
			align-items: center;
			font-size: 0.875rem;

			.thumb {
				position: relative;
				flex-shrink: 0;

				img {
					position: absolute;
					top: 0;
					right: 0;
					bottom: 0;
					left: 0;
					margin: auto;
					max-height: 100%;
				}
			}
		}
	}
}