//== variables & mixins
@import "variables";
@import "mixins";

/* ===== Prepared Disaster ==================================== */

@font-face {
    font-family: 'bebasregular';
    src: url('#{$fa-font-path}/bebas-webfont.eot');
    src: url('#{$fa-font-path}/bebas-webfont.eot?#iefix') format('embedded-opentype'),
         url('#{$fa-font-path}/bebas-webfont.woff') format('woff'),
         url('#{$fa-font-path}/bebas-webfont.ttf') format('truetype'),
         url('#{$fa-font-path}/bebas-webfont.svg#bebasregular') format('svg');
    font-weight: normal;
    font-style: normal;
} 
@font-face {
    font-family: 'bebas_neueregular';
    src: url('#{$fa-font-path}/BebasNeue-webfont.eot');
    src: url('#{$fa-font-path}/BebasNeue-webfont.eot?#iefix') format('embedded-opentype'),
         url('#{$fa-font-path}/BebasNeue-webfont.woff') format('woff'),
         url('#{$fa-font-path}/BebasNeue-webfont.ttf') format('truetype'),
         url('#{$fa-font-path}/BebasNeue-webfont.svg#bebas_neueregular') format('svg');
    font-weight: normal;
    font-style: normal;
}

.prepared {
    #content {
        margin-bottom: 0;
        
        .bottom-image {
            margin-bottom: -5px;
        }
        
        .break {
            display: block;
            
            @media only screen and (max-width: $screen-sm) {
                display: inline;
            }
        }
		
		.product-rec {
			p {
				color: #6d6d6d;
				font-size: 20px;
				margin: 0;
			}
		}
	}
	
	.pdf-cta {
		margin: 50px auto;
		p {
			font-size: 20px;
		}
	}
}

.prepared section {
    background-image: -webkit-linear-gradient(top, #e5e5e5 0%, rgba(255,255,255,0) 40px);
    background-image: linear-gradient(to bottom, #e5e5e5 0%, rgba(255,255,255,0) 40px);
	font-size: 14px;
	font-family: Arial, sans-serif;
	color: #3d3e3e;
    padding: 0;

	@media only screen and (max-width: $screen-sm) {
		 .row {text-align: center;} 
		.line  { display: inline; }
	}

	.pink, a {
        color: #bf245d;
		z-index: 100;
		position: relative;
	}
    
    .red, a {
		color: #bd1829;
		z-index: 100;
		position: relative;
	} 

	a:hover {color:#3d3e3e;}

	h1 {
        color: #fff;
        font-family: 'bebas_neueregular', "Oswald", sans-serif;
        font-size: 70px;
        text-transform: uppercase;	
        
        span {
            font-weight: lighter;
        }
        
        .break {
            text-align: center;
            color: #9a101f;
            font-size: 130px;
            font-weight: lighter;
            letter-spacing: -1px;
            margin-top: 10px;
            padding: 0; 
            text-shadow: -4px 4px 0 rgba(255,255,255,0.8);
            
            @media only screen and (max-width: $screen-sm) {
                display: block !important;
                font-size: 85px;
            }
        }

		@media only screen and (max-width: $screen-sm) {
            color: #000;
			line-height: 1;
			font-size: 48px;
			padding-top: 20px;
		}
	}

	h2 {
		font-family: 'bebas_neueregular', "Oswald", sans-serif; 
		text-align: center;
		text-transform: uppercase;	
		color: #3d3d3e;
		font-size: 30px;
		font-weight: normal;
		letter-spacing:1px;
		padding: 0;
		line-height: 100%;
		margin: 0;
	}

	h3 {
		font-family: 'bebas_neueregular', "Oswald", sans-serif;
		text-align: left;
		color: #fff;
		font-size: 22px;
		font-weight: bold;
		letter-spacing:3px;
		padding: 0;
		margin: 0;
	}

	h4 {
		color: #3D3D3E;
	    font-family: 'bebas_neueregular',"Oswald",sans-serif;
	    font-size: 25px;
	    font-weight: lighter;
	    letter-spacing: 1px;
	    margin: 0;
	    padding: 0;
	    text-align: left;
	}

	p {
		margin: 7px 0 20px 0;
	    line-height: 140%;
	}
 	.top-image img, .bottom-image img {width: 100%;}
    
    .top-image {
        position: absolute;
        width: 100%;
        
        @media only screen and (max-width: $screen-sm) {
            position: relative;
        }
    }

	.footer-image { 
	    margin-top: -47px;
	    position: relative;
	    z-index: 200;

	    img{width: 100%;}	
	}
    
    .top-section {
        margin: 0 auto;
        padding-top: 110px;
        position: relative;
        text-align: center;
        
        h1 {
            line-height: 0.9;
            margin: 0;
        }
        
        h2 {
            margin: 0 0 35px;
        }
        
        p {
            color: #6d6d6d;
            font-size: 20px;
        }
        
        @media only screen and (max-width: $screen-sm) {
            padding-top: 0;
        }
    }

	@media only screen and (min-width: $screen-sm) {
		.hexagon { 
			background: url("#{$cdnPath}/img/back-to-campus/banner-to-background.png") no-repeat scroll 0 0 transparent;
		 	display: inline-block;
		    height: 85px;
		    left: -6px;
            margin-right: -15px;
		    position: relative;
		    top: 5px;
		    width: 70px;
		}
		.hexagon-to {
		 	color: #fff;
			font-size: 50px;
			font-weight: lighter;
			left: 649px;
			letter-spacing: 0px;
			line-height: normal;
			position: absolute;
			text-shadow: none;
			top: 56px;
			z-index: 2;
		} 
	}
    
    .banners {
        margin-bottom: 20px;
        
        @media only screen and (max-width: $screen-sm) {
            margin-bottom: 10px;
        }
    }
    
	.top-left {
		background: url("/img/prepared/disaster-bg.jpg") no-repeat scroll 0 0 transparent;
	    color: #FFFFFF;
	    display: inline-block;
	    height: 364px;
	    position: relative;
	    vertical-align: top; 
	    font-size: 13px;
        width: 100%;

    	@media only screen and (max-width: $screen-sm) {
			width: 100%;
		}

	    p {margin: 5px 0;}
	    a {color: #fff;}
	    a:hover {color:#ccc;}

	    .combination-lock { 
		    margin-top: 4px;
		    float: left;

		    @media only screen and (max-width: $screen-sm) {
				float: none;
			}
		}
	} 

	.organized {
		background: url("#{$cdnPath}/img/back-to-campus/master-lock-vault.png") no-repeat scroll 420px center #FFFFFF;
	 	padding: 20px 200px 10px 45px; 
		width: 73%;
		margin-left:190px;
		box-shadow: 1px 3px 6px 1px #000000;
		color: #3e3e3f;

		@media only screen and (max-width: $screen-sm) {
			margin-left: 0;
		    padding: 20px 5% 10px 10px;
		    width: 100%; 
		    background: #ffffff;	 	
		}

	    p, 
		h4 {
		    position: relative; 
		    z-index: 300;
		    
		    @media only screen and (max-width: $screen-sm) {
		    	text-align: center;
		    }
		}

		a {
			background: #990000;
			padding: 10px;
			text-decoration: none;
			text-align: center;
			font-family: 'bebasregular', Impact, sans-serif; 
			margin: 5px 0 5px 30px;
			display: inline-block;
			letter-spacing: 0.5px;
		    line-height: 100%;
		}

		a:hover {
			background:#434141;
			color: #ffffff;
		}
	}

	.lost-combination {
        bottom: 25px;
        position: absolute;
        width: 80%;
        left: 35px;

		@media only screen and (max-width: $screen-sm) {
            width: 96%;
            left: 13px;
            bottom: 20px;
		}

		> h3 {
	    font-size: 38px;
	    font-weight: lighter;
		line-height: 39px;
		text-transform: uppercase;
	    	@media only screen and (max-width: $screen-sm) {
				text-align: left;			
			}
	    }
	}

	.top-right { 
		float: right;
	}
    
    .video {
        padding-left: 20px;
        width: 34%;
        
        &:hover {
            opacity: 0.8;
            
            @media only screen and (max-width: $screen-sm) {
                opacity: 1;
            }
        }
        
        @media only screen and (max-width: $screen-sm) {
            margin: 10px 0 0;
            padding: 0;
            width: 100%;
        }
    }

	.tips {
		background: #dbdbdb;
		margin: 95px 0 25px -30px;
		padding: 40px 0;

		@media only screen and (max-width: $screen-sm) {
			margin: 0;
		}
		
		h1 {
			font-size: 98px;
			color: #bd1829;
			
			@media only screen and (max-width: $screen-sm) {
				font-size: 70px;
			}
		}
		
		h2 {
			margin: -14px 0 6px;
			font-size: 38px;
			color: #494a4c;
			text-shadow: 2px 2px 2px #FFFFFF, 2px 2px 0 #aeb0b3;
			@media only screen and (max-width: $screen-sm) {
				font-size: 30px;
			}
		}

		h3 { 
			color: #bd1829;
			font-family: 'Oswald',sans-serif; 
			text-transform: normal;
			font-weight: inherit;
			letter-spacing: 0; 
		}

		@media only screen and (min-width: 768px) {
			.hexagon-grey { 
				/*background: url("/img/back-to-campus/banner-to-background-grey.png") no-repeat scroll 0 0 transparent;*/
			 	display: inline-block;
			    height: 65px;
			    left: 0;
			    position: relative;
			    top: 5px;
			    width: 25px;
			 }
			.hexagon-grey-to {
			 	color: #000;
				font-size: 35px;
				font-weight: lighter;
				left: 157px;
				letter-spacing: 0px;
				line-height: normal;
				position: absolute;
				text-shadow: none;
				top: 42px;
				z-index: 2;
			}
		} 
		 
		.content {
			background: #ffffff;
			border: 1px solid #a8a8a8; 
			margin: 0 auto;
			padding: 60px 60px 5px 60px;
		  	box-shadow: 3px 2px 4px 0px #abaeb2;
		  	
		  	@media only screen and (max-width: $screen-sm) {
				padding: 10px;
			}
		}

		.section {
			position: relative;
			margin: 0 auto;
			padding: 0;

			img {max-width: 100%;}
			+ .section {border:none;}
			
			&:after {
			    clear: both;
			    content: "";
			    display: table;
			}
		}

		.number {
			float: left;	
			border-radius: 50% 50%;
		 	background: #bd1829;
		 	width: 42px;
		 	height: 42px;
		 	text-align: center;
		 	position: relative;
			margin: 0 15px 0 0;
		
			span {
				left: 16px;
			    padding-top: 2px;
			 	font-family: 'Oswald',sans-serif; 
			 	font-size: 24px; 
			 	color: #ffffff;
			 	position: absolute;
			}
		}

		.tips-content {
			float: left;
			width: 70%;

			@media only screen and (max-width: $screen-sm) {
				text-align: left;
			    width: 58%;
			}
		}

		.backup .tips-content,
		.backpack .tips-content {
			width: 60%;

			@media only screen and (max-width: $screen-sm) {  
			    width: 58%;
			}
		}

		.tips-content,
		.backup .tips-content,
		.backpack .tips-content {
			@media only screen and (max-width: $screen-sm) {
				text-align: left;
			    width: 100%;
			    padding-left: 57px;
			    margin-left: -57px;
			}
		}

		.image-right { float: right; }

		.image-left { float: left; }

		@media only screen and (max-width: $screen-sm) { 
			.image-left,
			.image-right { display: none; }
		}

		.travel .image-right {
			margin-top: -60px;

			@media only screen and (max-width: $screen-sm) {
				margin: 0;
				float: right;
			    width: 22%;
			}
		}
		.backup .image-left {
			margin: -26px 0 20px 32px;

			@media only screen and (max-width: $screen-sm) {
				margin: 0;
				float: right;
			    width: 22%;
			}
		}
		.combination-number .image-right {
			margin-top: -22px;

			@media only screen and (max-width: $screen-sm) {
				margin-top: 0;
				float: right;
			    width: 22%;
			}
		}

		.backpack .image-left {
			margin: -70px 0 0 32px;

			@media only screen and (max-width: $screen-sm) {
				margin: 0;
				float: right;
			    width: 22%;
			}
		}

		.border {
			border: 2px solid #a8a8a8;
			width: 610px;
			margin: -130px auto 30px;
			position: relative;

			@media only screen and (max-width: $screen-sm) {
				width: 90%;
				margin-top: -40px;
			}
		}

		.header {
			border: 1px solid #494a4c;
			outline: 1px solid #ffffff;
			background: #ffffff;	
		}	
	}

	.sweepstakes {
		width: 100%;
		height: 154px; 
		clear: both;
		text-align: center;
		padding: 0;
		margin: 0 0 50px 0;
		
		@media only screen and (max-width: $screen-sm) {
			height: auto;
			margin: 0;
		}

	}

	.products {		
		
		&:after { 
		   content: ""; 
		   visibility: hidden; 
		   display: block; 
		   height: 0; 
		   clear: both; 
		}

		> div {
			width: 50%;
			display: block;  
		    position: relative; 
		    z-index: 102;
			float: left;
			list-style: none;
			background: none;
			margin: 50px 0 0;
			height: 450px;
			padding: 20px 10px 30px 30px;
			
			@media only screen and (max-width: $screen-sm) {
				width: 100%;
				padding: 0 10px; 
				height: 100%;
			}
			&.left {
				background: url(#{$cdnPath}/img/back-to-campus/product-background-left-blank.jpg) 100% 100% no-repeat;
				@media only screen and (max-width: $screen-sm) {
					background: none;
				}
			}

			&.right {
				background: url(#{$cdnPath}/img/back-to-campus/product-background-right-blank.jpg) 0 100% no-repeat;
				@media only screen and (max-width: $screen-sm) {
					background: none;
				}
			}

			&.bg-left {
				background: url(#{$cdnPath}/img/back-to-campus/product-background-left.jpg) 100% 100% no-repeat;
				@media only screen and (max-width: $screen-sm) {
					background: none;
				}
			}

			&.bg-right {
				background: url(#{$cdnPath}/img/back-to-campus/product-background-right.jpg) 0 100% no-repeat;
				@media only screen and (max-width: $screen-sm) {
					background: none;
				}
			}
		}
		
		.laptop-locks .copy img {
			position: absolute;
			left: 23px;
			bottom: 0;
			z-index: 1;
			@media only screen and (max-width: $screen-sm) {
				position: relative;
				left: inherit;
				bottom: inherit;
				width: 100%;
			}
		}

		.laptop-locks .lock-selector-colors {
			padding-top: 273px;
			@media only screen and (max-width: $screen-sm) {
				padding-top: 0;
			}
		}
		
		.copy {
			display: inline-block; 
		    vertical-align: top;
		    text-align: left;
			margin-top: 25px;
			width: 55%;
			p {margin-bottom: 25px;}
			.btn-primary {
				margin-top: 25px; 
				background: #222;
				color: #fff;
				font-size: 1.2rem;
				padding: 3px 30px 5px;
				border-radius: 20px;
				font-family: Oswald, sans-serif;
				text-transform: uppercase;
				&:after {
					content: ">";
					position: relative;
					top: 2px;
					color: $brand-secondary;
					display: inline-block;
					padding-left: 20px;
					font-size: 2rem;
					line-height: 1.2rem;
				}
				&:hover {
					text-decoration: none;
				}
			} 
			@media only screen and (max-width: $screen-sm) {
				width: 100%;
			}
		}

		.product-image {
			float: right; 
		    vertical-align: top; 
		    text-align: center;
		    position: relative;
			z-index: 201;
			width: 45%;

		    @media only screen and (max-width: $screen-sm) {
				float: none;
				width: 100%;
                max-width: 205px;
                margin: 20px auto 0;
                padding: 0;
			}
		} 

		.lock-selector-colors > li{ 
		    display: inline-block;    
		    margin-right: 10px;
		    box-sizing: inherit;
		}

		.lock-selector-colors > li label {
			height: 30px;
		    width: 30px;
		    border-radius: 50%; 
		    display: inline-block;
		    padding-top: 6px;
		    position: relative;
		}

		.lock-selector-colors > li input[type="radio"] {
			clip: rect(1px, 1px, 1px, 1px);
		    position: absolute !important;
		} 

		.lock-selector-colors label {cursor: pointer;}

		.lock-selector-colors input[type="radio"]:checked + label span {
		    border: 3px solid #000;
		    border-radius: 50%;
		    height: 40px;
		    left: -5px;
		    position: absolute;
		    top: -5px;
		    width: 40px;
		}
        
        .security-bar, .pistol-safe {
            margin: 20px 0 0;
        }

		.product-image ul li:after {content:"";}

		.numeric,
		.alpha  {color: #bd1829;}
		.black {background-color: #000;}
		.lock-selector-colors > li label.white {background: url("#{$cdnPath}/img/back-to-campus/white-label-background.jpg") no-repeat scroll bottom left transparent;}
		.purple {background-color: #5c2f71;}
		.purple-percision {background-color: #6f2060;}
		.pink {background-color: #ef61dd;}
		.light-pink {background-color: #fb5f90;}
		.red {background-color: #ca1a23;}
		.blue {background-color: #13458f;}
		.blue-percision {background-color: #282e74;}
		.blue-set-own-locks {background-color: #223575;}
		.pink-set-own-locks {background-color: #d0255e;}
        .nickel-tsa-locks {background-color: #8d867d;}
        .blue-tsa-locks {background-color: #0072ce;}
        .red-tsa-locks {background-color: #a4343a;}
        .pink-tsa-locks {background-color: #f57eb6;}
		.light-blue {background-color: #1b9cda;}
		span.selected {border: 2px solid #000;}

		h3 {
			font-family: 'Oswald',sans-serif; 
		    text-align: left;
		    color: #be202b;
		    font-size: 19px;
		    font-weight: 600;
		    letter-spacing: 1px;
		    line-height: 120%;
            text-transform: uppercase;
		}

		li{ 
			padding: 0;	
			margin: 10px 0;
			background: none;
			line-height: 120%;
		}

		li:after{
			content: ">";
			margin-left: 4px;
			color: #bd1829;
		}
	}
}

.tsa-locks img {
    max-width: 185px;
}

.magnification img {
    max-width: 130px;
}

.lte8 .prepared .products .lock-selector-colors > li label.white {
	background: #fff;
	border: 1px solid #ccc;
}

.lte8 .prepared .products .lock-selector-colors > li label { border: 1px solid #ccc; }

@media print {
	
	.top-image,
	.combination-lock,
	.organized a,
	.top-right .play-button,
	.tips .backup .image-left,
	.tips .travel .image-right,
	.tips .combination-number .image-right,
	.tips .backpack .image-left,
	.products .product-image,
	.products .laptop-locks .copy img,
	.sweepstakes,
	.products .copy ul,
	.footer-image { display: none !important; }

	h1,
	h2,
	p,
	.lost-combination,
	.organized,
	.top-right,
	.top-left,
	.tips,
	.tips .border,
	.tips .header,
	.tips .section,
	.products > div,
	.products .copy {
		position: relative !important;
		float: none !important;
		padding: 0 !important;
		margin: 0 0 20px 0 !important;
		width: auto !important;
		height: auto !important;
		text-align: left !important;
		min-height: 1px !important;
		min-width: 1px !important;
		top: 0 !important;
		left: 0 !important;
		border: 0 !important;
		box-shadow: none !important;
		
	}

	.tips .content {
		border: 0 !important;
		box-shadow: none !important;
		padding: 0 !important;
	}

	.prepared section h1,
	.prepared section .tips h1 { font-size: 65px; }

	.pdf-cta, .btn-primary {
		display: none;
	}
}