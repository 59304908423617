@import "variables";
@import "mixins";
@import "defaults";
@import "base";
@import "fancybox";
@import "fonts";
@import "lists";
@import "grid";

body {
	font: 16px/1.4 $font-family-sans-serif;
	font-weight: 500;
	color: $color-base;
	background-color: #fff;
}

.featherlight {
	.featherlight-content{
		border-bottom: 0;
	}

	.caption {
		background: #111;
		color: #fff;
		padding: 10px;
		text-align: center;
		font-weight: 700;
	}

	.featherlight-close-icon {
		background: #333333;
		color: #fff;
		font-weight: bold;
		width: 30px;
		height: 30px;
		font-size: 20px;
	}

	.featherlight-next,
	.featherlight-previous {
		background: none;

		span {
			color: #fff;
			display: block !important;
			width: 35px;
			height: 35px;
			font-size: 16px;
			line-height: 30px;
			margin-top: -30px;
			left: 3px;
			background: #333;
			border-radius: 50%;
			border: 2px solid #fff;
			text-shadow: none;
			box-shadow: 0 4px 5px black;
		}
	}

	.featherlight-previous {
		left: 0;
	}

	.featherlight-next {
		right: 3px;

		span {
			right: 0;
			left: auto;
		}
	}
}

.modal {

	.featherlight-content {
		width: 800px;
		height: auto;

		@media only screen and (max-width: 590px) {
			width: 80%;
			margin: 0 20px;
		}
	}
}

.modal-testimonial {
	.featherlight-content {
		width: auto;
		max-height: 80%;
		padding: 15px;
	}
}

.modal-timeline {
	padding: 25px 40px;
	overflow: hidden;
	text-align: left;
	@media only screen and (max-width: $screen-sm) {
		padding: 15px 35px;
	}

	h2 {
		font-size: 28px;
		margin: 0 0 18px 0;
		font-weight: 500;

		@media only screen and (max-width: $screen-sm) {
			font-size: 22px;
		}
	}

	h3 {
		font-size: 21px;
		font-weight: 700;
		margin: 0 0 8px;
		@media only screen and (max-width: $screen-sm) {
			font-size: 18px;
		}
	}

	h4 {
		font-size: 16px;
		font-weight: 700;
		margin: 0 0 8px;
	}

	p {
		font-size: 14px;
		line-height: 1.4;
		margin: 0 0 15px 0;
	}

	img {
		margin: 10px 0;
		max-width: 400px;
		max-height: 300px;

		@media only screen and (max-width: $screen-sm) {
			max-width: 100%;
			max-height: 100%;
		}
	}

	ul {
		width: 50%;
		margin: 10px 0;
		padding-left: 20px;

		@media only screen and (max-width: $screen-sm) {
			width: 100%;
		}

		li {
			margin-bottom: 10px;
			font-size: 14px;
		}
	}

}

