.retired-products {

	main#content {
		margin-bottom: 0;
	}

	.hero {
	    margin-bottom: 0px;
	    .section-title {
	    	font-size: 65px;
	    	@media only screen and (max-width: $screen-sm) {
		    	font-size: 35px;
		    }
	    }
	}

	.light-gray .row {
	    background: $light-gray;
	}

	.section  {
		padding: 0;
		.row {
	    	padding: 3.125rem 3.75rem;
		    max-width: 1280px;
		    @media only screen and (max-width: $screen-sm) {
		    	padding: 1.25rem;
		    }
		}
	}

	.row.section-content {
		display: flex;
    	align-items: center;
    	@media only screen and (max-width: $screen-sm) {
	    	display: block;
	    }
		p {
		    font-size: 1.1em;
		}
	}

	.row.download-section {
		padding: 3.125rem 3.75rem 1em;
		@media only screen and (max-width: $screen-sm) {
			padding: 1.25rem;
		}
		ul {
			display: flex;
			margin-top: 4em;
			@media only screen and (max-width: $screen-sm) {
				margin-top: 0;
				display: block;
				padding: 0;
			}
		}
		li {
			flex:1;
			padding: 0 1em;
			@media only screen and (max-width: $screen-sm) {
				margin: 2.5em 0 0;
			}
		}

		.link-title {
		    font-size: 1.2em;
		}
		.description {
		    margin-top: 0.5em;
		}
		.icon-image {
		    display: block;
		    margin: 0 auto 1em;
		}
	}

	.row.contact-us-section {
		padding: 1rem 3.75rem;
		@media only screen and (max-width: $screen-sm) {
	    	padding: 1.25rem;
	    }
		p {
			font-size: 1.2em;
		}
	}
}
	