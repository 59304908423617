
/* Video Library Category Pages */

.video-library .section.first {
	//@extend .gradient-gray-lighter;
	margin-top: 1em;
	padding-top: 3.5em;
	
	@media only screen and (max-width: $screen-sm) {
		padding-top: 1.5em;
	}
}

.list-video-library {

	.col-video-library {
		width: 290px;
		text-align: center;
		margin-top: 1em; 
		margin-right: 0.75%;

		a {
			display: block;  
			width: 100%;
			height: 100%;
			padding: 0;
			overflow:hidden; 
			text-decoration: none;
			background-color: #FFFFFF;

			h2 {
				width: 100%;
				background-color: $brand-secondary;
				color: #FFFFFF;
				margin: 0;
				padding: 0.6em 0 0 0;
				height: 2.5em; 
			}

			.video-library-thumb { 
				width: 100%;
				border: solid 1px $brand-primary-light;
			}

			.video-library-summary {
				color: initial; 
				text-align: left;
				height: 75px;
				padding: 0.5em 0.5em 0 0.75em;
                
                &.align-center {
                    text-align: center !important;
                }

				@media only screen and (max-width: $screen-sm) {
					height: auto;
				}

			}

			&:hover {
 
				h2 {
					background-color: $brand-secondary;
					text-decoration: underline;
				}
				.video-library-thumb {
					opacity: 0.85;
				}

			}

		}

		@media only screen and (max-width: $screen-xs) {
			width: 100%;
			height: auto;
			padding: 0; 
		}

	}

}

/* Video Library Player Pages */
 
.video-list-container .resp-tab-content {
	padding-bottom: 0;

	@media only screen and (max-width: $screen-xs) {
		padding-left: 0;
		padding-right: 0;
	}
}

.current-video-meta-container h2{
	padding-top: 20px;
}

.video-container {
	padding: 0 20px 10px 0;

	@media only screen and (max-width: $screen-sm) {
		border: none;
		padding-bottom: 20px;
	}

	iframe {
		width: 100%;
		height: 324px;
		border: solid 1px $color-base;
	}

	.video-download-container {
		border-top: 1px solid #cccccc;
   		font-size: 0.875em;
    	margin-top: 30px;
    	padding: 0.5em 0;
	}

}

.list-video-player {
	.video-item {
		width: 100%;
		clear: both;
		margin: 0;
		padding: 0;
		border-bottom: solid 1px $brand-primary-light; 

	&:last-child {
		border: none;
	}

	a.video-play {
		display: block;
		width: 100%;
		height: 100%;
		padding: 3% 2%;
		overflow: hidden;
		text-decoration: none;
	 
			&:hover {
				background-color: #F5F5F5;
				text-decoration: none;
			} 
		
			.video-item-thumb {
				width: 22%;
				display: inline-block;
				vertical-align: top;
			}
 
			.video-meta-container {
				width: 76%;
				padding-left: 2%;
				display: inline-block; 
				vertical-align: top;
 
				.video-title { 
					font-size: 1.125em;
					margin-bottom: 0.25em;

					@media only screen and (max-width: $screen-sm) {
						font-size: 1em;
					}
				}
				.video-description {
					color: #000000;
					margin-bottom: 0;
					font-size: 0.875em;

					@media only screen and (max-width: $screen-sm) {
						font-size: 0.75em;
					}
					@media only screen and (max-width: $screen-xs) {
						display: none;
					}

				}
			}
		}
	}
  
	a.video-play.current-video, 
	a.video-play.current-video:hover { 
		background-color: #F5F5F5;
	}	 

}

@media only screen and (max-width: 620px) {
	html.mobile .list-video-player a.video-play.current-video,
	html.mobile .list-video-player a.video-play:hover {background-color: #FFFFFF !important;	}	  
}


/* Video Featherlight */

.featherlight .featherlight-content {
	padding: 0;
}
.video-download-modal-container {
	max-width: 600px;
	padding: 10px;
}