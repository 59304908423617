ol,
ul,
dl { 
	margin: 0 0 $font-size-base;
}

ol,
ul {
	padding: 0 0 0 1.25em;
}

li { margin: 0 0 .40em; }

.reduced {
	padding-left: 0;
	list-style: none;
}

.list-unstyled {
	@extend .reduced;
}
  
.list-inline {
	@extend .reduced;

	li {
		display: inline;

		& + li { margin: 0 0 0 .75em; }
	}

	&.piped li + li {
		border-left: 1px solid #999;
		margin-left: .5em;
		padding-left: .75em;
	}
}
   
.list-inline-block {
	@extend .reduced;

	li {
		display: inline-block;
		margin: 0 0 0 .75em;
	}
}