$screen-sm:	767px !default;
$black: #000000 !default;
$blue: #00a6c9 !default;
$red: #cc0000 !default;
$dark-gray: #55575a !default;
$gray-border: #c8c8c8 !default;
$light-gray: #e9e9e9 !default;
$white: #ffffff !default;

.vertical {
    &.category {
        .site-nav-bkgd {
            opacity: 1;
        }

        #content {
            margin-bottom: 0;
            top: auto;

            .hero {
                line-height: 0;
                margin-bottom: 0;
                top: -110px;

                .section-header {
                    padding: 145px 0 0 100px;

                    .sub {
                        border-top: 2px solid $blue;
                        font-size: 20px;
                        font-weight: 600;
                        margin-top: 1.25rem;
                        padding-top: 1.25rem;
                        text-transform: uppercase;
                        width: 65%;

                        @media only screen and (max-width: $screen-sm) {
                            color: $black;
                            width: 80%;
                        }
                    }

                    @media only screen and (max-width: $screen-sm) {
                        padding: 1.25rem 1.25rem 0;
                    }
                }

                @media only screen and (max-width: $screen-sm) {
                    top: 0;
                }
            }

            .expandable {
                cursor: pointer;
            }
        }
    }

    .section {
        padding: 0;

        &.first {
            margin-top: -110px;

            @media only screen and (max-width: $screen-sm) {
                margin-top: 0;
            }
        }

        .row {
            padding: 3.125rem 3.75rem;
            max-width: 1280px;

            &.section-content {
                h2 {
                    border-bottom: 1px solid $blue;
                    color: $dark-gray;
                    font-weight: 600;
                    padding-bottom: 0.625rem;
                    width: 93%;

                    @media only screen and (max-width: $screen-sm) {
                        width: 100%;
                    }
                }

                p {
                    color: $dark-gray;
                    font-size: 1rem;
                    margin-bottom: 0;
                    width: 93%;

                    @media only screen and (max-width: $screen-sm) {
                        width: 100%;
                    }
                }

                .detail {
                    float: left;
                    width: 80%;
                    @media only screen and (max-width: $screen-sm) {
                        width: 100%;
                        float: none;
                    }
                }

                a.buy {
                    float: right;
                    background: $red;
                    border-radius: 0;
                    color: $white;
                    font-size: 1.125rem;
                    font-weight: 600;
                    margin-top: 0.7rem;
                    text-transform: uppercase;
                    padding: 0.75rem 2rem;
                    &:hover {
                        background: $dark-gray;
                        color: $white;
                    }

                    @media only screen and (max-width: $screen-sm) {
                        float: none;
                    }
                }

                .blue-title {
                    color: $blue;
                    font-size: 1.125rem;
                    font-weight: 600;
                    text-transform: uppercase;
                }

                h4.blue-title {
                    &:hover {
                        color: $blue;
                        text-decoration: underline;
                    }
                }

                h3.blue-title a {
                    color: $blue;
                }

                @media only screen and (max-width: $screen-sm) {
                    padding: 1.25rem;
                }
            }

            @media only screen and (max-width: $screen-sm) {
                padding: 0;
            }
        }

        &.call-to-action {
            h3 {
                font-weight: 600;
            }

            &.blue {
                text-align: center;

                h3 {
                    display: inline-block;
                    font-size: 1.25rem;
                    margin: 0 1.25rem;
                    text-transform: uppercase;

                    @media only screen and (max-width: $screen-sm) {
                        display: block;
                        margin: 0 0 1.25rem;
                    }
                }

                .btn {
                    background: $white;
                    border-radius: 0;
                    color: $blue;
                    font-size: 1.25rem;
                    font-weight: 600;
                    padding: 0.5rem 0.9375rem;
                    text-transform: uppercase;

                    &:hover {
                        background: $dark-gray;
                        color: $white;
                    }
                }
            }

            &.dark-gray {
                .row {
                     padding: 1.875rem 3.75rem;

                    h3 {
                        font-size: 1.5rem;
                        line-height: 2rem;
                    }

                    .btn {
                        background: $blue;
                        border-radius: 0;
                        color: $white;
                        font-size: 1.125rem;
                        font-weight: 600;
                        margin-top: 0.625rem;
                        padding: 0.75rem 2.5rem;
                        text-transform: uppercase;

                        &:hover {
                            background: $white;
                            color: $blue;
                        }

                        @media only screen and (max-width: $screen-sm) {
                            margin-top: 0;
                        }
                    }

                    @media only screen and (max-width: $screen-sm) {
                        padding: 1.25rem;
                    }
                }
            }
        }

        &.contact-form {
			position: relative;
			z-index: 2;

            .section-content {
                .eloqua {
                    margin: 0 auto;
                }

                p {
                    color: $white;
                    font-size: 1.375rem;
                    font-weight: 600;
                    padding: 0.375rem 0.6875rem;
                    text-transform: uppercase;

                    &.required-fields {
                        font-size: 0.8125rem;
                        font-weight: normal;
                    }
                }

                .privacy-link a {
                    color: #FFF;
                    padding: 1em 0;
                    display: inline-block;
                    text-decoration: underline;
                    @media only screen and (min-width: $screen-sm) {
                        padding: 1.35em 0;
                    }
                    &:hover {
                        text-decoration: none;
                    }
                }

                label {
                    color: $white;

                    &.hidden {
                        display: none;
                    }
                }

                input {
                    border: none;
                }

                .LV_invalid {
                    color: #cc0000;
                    display: block;
                    font-size: 0.875rem;
                    font-weight: 600;
                    padding: 0.1875rem 0.1875rem 0 0.1875rem;
                }

                .LV_invalid_field {
                    border: 0.125rem solid #cc0000;
                }

                .LV_valid {
                    color: $white;
                    display: none;
                }

                .btn {
                    background: $red;
                    border-radius: 0;
                    color: $white;
                    font-size: 1.25rem;
                    font-weight: 600;
                    margin-top: 1.25rem;
                    padding: 0.5rem 3.125rem;
                    text-transform: uppercase;

                    &:hover {
                        background: $dark-gray;
                        color: $white;
                    }
                }
            }
        }

        &.image-banner {
            position: relative;

            .row {
                &.section-content {
                    line-height: 0;
                    padding: 0;

                    .col {
                        padding: 0;

                        &.content-box {
                            background: $white;
                            line-height: normal;
                            padding: 2.813rem 0 0;

                            .top {
                                padding: 0 3.5rem 2rem;

                                h2 {
                                    border-bottom: 1px solid $blue;
                                    color: $dark-gray;
                                    width: 100%;
                                }

                                p {
                                    color: $dark-gray;
                                    font-size: 0.85rem;
                                    line-height: 1.375rem;
                                    margin-bottom: 0;
                                    width: 100%;
                                }

                                @media only screen and (max-width: $screen-sm) {
                                    padding: 0 1.875rem 1.875rem;
                                }
                            }

                            .features {
                                background: $white;
                                padding: 0 3.5rem;

                                h3 {
                                    color: $blue;
                                    font-size: 1rem;
                                    font-weight: 600;
                                    text-transform: uppercase;
                                }

                                ul {
                                    color: $dark-gray;
                                    margin: 0rem 0rem 0rem 0.9rem;
                                    padding: 0;

                                    li {
                                        font-size: 0.85rem;
                                        padding: 0 0.625rem 0.625rem 0.1rem;
                                        vertical-align: top;
                                        width: 49%;
                                        position: relative;

                                        &:before { 
                                            display: block;
                                            font-size: 1.4rem;
                                            margin-top: -0.25rem;
                                            position: absolute;
                                            left: 0;
                                        }

                                        @media only screen and (max-width: $screen-sm) {
                                            display: block;
                                            width: 100%;
                                        }
                                    }
                                }

                                @media only screen and (max-width: $screen-sm) {
                                    padding: 0 1.875rem;
                                }
                            }

                            @media only screen and (max-width: $screen-sm) {
                                padding: 1.875rem 0;
                                position: relative;
                                width: 100%;
                            }
                        }
                    }
                }
            }
        }

        &.dark-gray {
            .row {
                background: $dark-gray;

                &.section-content {
                    h3, p {
                        color: $white;
                    }
                }
            }
        }

        &.light-gray {
            .row {
                background: $light-gray;

                &.section-content {
                    h3, p, ul {
                        color: $dark-gray;
                    }

                    .blue-title {
                        color: $blue;
                    }
                }
            }

            &.trademark {
                .row {
                    &.section-content {
                        padding: 0.625rem;

                        p {
                            font-size: 0.625rem;
                        }
                    }
                }
            }
        }

        &.blue {
            .row {
                background: $blue;

                &.section-content {
                    h3, p {
                        color: $white;
                    }
                }
            }
        }

        &.access {
            img {
                display: inline-block;
                margin: 0 15px 0 0;
                vertical-align: middle;

                @media only screen and (max-width: $screen-sm) {
                    &.last {
                        display: inline-block;
                        margin: 50px 0 0 0;
                    }
                }

                @media only screen and (max-width: $screen-sm) {
                    display: block;
                    margin: 0 auto;
                }
            }

            #access-schematic {
                margin: 2rem 0 0 0;

                @media only screen and (max-width: $screen-sm) {
                    margin: 0;
                }
            }

            .cellphone {
                max-width: 130px;

                @media only screen and (max-width: $screen-sm) {
                    margin-top: 0.625rem;
                }
            }

            .blue-title {
                display: inline-block;
                line-height: 27px;
                vertical-align: middle;
                width: 110px;

                .fa {
                    position: absolute;
                    font-size: 65px;
                    margin: -34px 0 0 17px;
                }

                @media only screen and (max-width: $screen-sm) {
                    margin: 2rem 0 0 0;
                }
            }
        }

        &.simplicity {
            .diagram {
                margin-top: 2.5rem;
            }
        }

        &.bluetooth {
            .section-content {
                .content-group {
                    .details {
                        p {
                            font-size: 0.8125rem;
                           
                            @media only screen and (max-width: $screen-sm) {
                                margin-bottom: 0;
                            }
                        }

                        p.category-description {
                            height: 60px;
                            overflow: visible;
                        }

                        p#bluetooth-lock-boxes {
                            @media only screen and (min-width: $screen-sm) {
                            width: 50%;
                            }
                        }

                        .lock-box {
                            margin-top: 0.9375rem;

                            &:last-child {
                                margin-left: 1.563rem;

                                @media only screen and (max-width: $screen-sm) {
                                    margin-left: 0;
                                }
                            }

                            img {
                                display: block;
                                float: none;
                                margin: 0.625rem auto 1.25rem;
                            }

                            .portable {
                                margin-top: -88px;

                                @media only screen and (max-width: $screen-sm) {
                                    margin-top: 0;
                                }

                            }

                            .blue-title {
                                font-size: 0.8125rem;
                                margin-bottom: 0.375rem;
                            }

                            .description {
                                margin-bottom: 0;
                                width: 100%;
                            }
                        }

                        .padlock,
                        .door-controller {
                            margin-top: 3.625rem;

                            &:last-child {
                                margin-left: 0;

                                @media only screen and (max-width: $screen-sm) {
                                    margin-left: 0;
                                }
                            }

                            img {
                                display: block;
                                float: none;
                                margin: 0.625rem auto 1.25rem;
                                max-width: 90px;
                            }

                            .outdoor {
                                margin-top: -53px;

                                @media only screen and (max-width: $screen-sm) {
                                    margin-top: 0;
                                }

                            }

                            .blue-title {
                                font-size: 0.8125rem;
                                margin-bottom: 0.375rem;
                            }

                            .description {
                                margin-bottom: 0;
                                width: 100%;
                            }

                            @media only screen and (max-width: $screen-sm) {
                                margin-top: 0;
                            }
                        }

                        .door-controller {
                            img {
                                margin-top: -70px;

                                @media only screen and (max-width: $screen-sm) {
                                    margin-top: -30px;
                                }
                            }
                        }
                    }
                }
            }
        }

        &.flexible {
            .content-group {
                margin-top: 2.5rem;

                @media only screen and (max-width: $screen-sm) {
                    margin-top: 0;
                }

                img {
                    margin: 1.25rem 0 2.188rem;
                }

                p {
                    font-size: 0.8125rem;
                    margin: 0 auto;
                    width: 65%;

                    @media only screen and (max-width: $screen-sm) {
                        width: 100%;
                    }
                }

                .blue-border {
                    border-left: 1px solid $blue;
                    border-right: 1px solid $blue;

                    @media only screen and (max-width: $screen-sm) {
                        border: none;
                    }
                }

                .col {
                    @media only screen and (max-width: $screen-sm) {
                        margin-bottom: 25px;
                    }
                }
            }
        }

        .details {
            margin-top: 2.813rem;

            h3 {
                color: $dark-gray;
                font-size: 1.5rem;
                font-weight: 600;
            }

            .blue-title {
                color: $blue;
            }

            ul {
                color: $dark-gray;
                float: left;
                margin-top: 0.9375rem;
                width: 65%;

                li {
                    margin: 0 0 1.2rem;

                    &:last-child {
                        margin: 0;
                    }
                }

                @media only screen and (max-width: $screen-sm) {
                    float: none;
                    width: 100%;
                }
            }

            img {
                float: left;
                margin: 0.625rem 0 0 1.563rem;
                max-width: 135px;

                @media only screen and (max-width: $screen-sm) {
                    float: none;
                    margin: 0.625rem auto 0;
                }
            }

            @media only screen and (max-width: $screen-sm) {
                margin-top: 0;
            }
        }

        &.footer {
            .row {
                &.section-content {
                    padding: 0;

                    #site-footer {
                        background: $light-gray;
                        border-top: 3px solid $gray-border;
                        color: $dark-gray;
                        padding: 0;

                        a {
                            color: $dark-gray;
                        }

                        .row {
                            padding: 1.25rem;

                            .utility {
                                .list-inline {
                                    margin: 0;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    &.vertical-vault, 
    &.verical-showing-time,
    &.vertical-locksmiths,
    &.vertical-construction,
    &.vertical-manufacturing,
    &.vertical-university   {
        span.required-fields {
            display: block;
            color: #ffffff;
            padding: 0.8em 0 0 0.85em;
            text-transform: uppercase;
        }

        .form-group {
            margin-bottom: 0;
            margin-top: 1.5em;
            @media only screen and (max-width: $screen-sm) {
                margin-top: 0;
            }
        }

        .opt-in {
            margin-top: 1em;
            @media only screen and (max-width: $screen-sm) {
                margin-top: 0;
            }
        }

        textarea {
            min-height: 5em;
        }
    }

	&.verical-showing-time {

		.section.image-banner .row.section-content .col.content-box .top {
			padding-bottom: 1rem;
		}

        .section.image-banner {
            .features ul li {
                font-size: 1rem !important;
            }
        }

		.section.bluetooth {
			.details {
				ul {
					font-size: 1rem;
				}
			}
			.lock-box img {
				max-width: 160px;
				margin: -10px 0 20px 0;
			}
		}

		.light-gray,
		.bluetooth {
			.btn {
				background: $blue;
				border-radius: 0;
				color: $white;
				font-size: 1.125rem;
				font-weight: 600;
				margin-top: 0.625rem;
				padding: 0.75rem 2.5rem;
				text-transform: uppercase;

				&:hover {
					background: $white;
					color: $blue;
				}
			}

			.lock-box {

				.btn {
					border: solid 1px #ffffff;

					&:hover {
						border: solid 1px $blue;
					}
				}
			}

		}

        .mobile-hide {
            display: none;
            @media only screen and (min-width: $screen-sm) {
                display: block;
            }
        }
	}

    &.where-to-buy {

        .hero .section-header {
            margin-top: 2.5em;
            @media only screen and (max-width: $screen-sm) {
                margin-top: 0;
            }
        }

        .section {
            .row.section-content h2 {
                border-bottom: 1px solid #00a6c9;
            }
        }

        .distributor-products {
            margin-top: 2em;
        }

        .padlock-product-spacer {
          min-width: 300px; 
        }
        .padlock-product {
            text-align: left;
            img {
                width: 100%;
                max-width: 175px;
                display: block;
                padding-bottom: 1em;
                margin: 0 auto;

            }
            img.first {
                max-width: 108px;
                @media only screen and (min-width: $screen-sm) {
                    padding-top: 3.35em;
                }
            }
            img.second {
                max-width: 105px;
                @media only screen and (min-width: $screen-sm) {
                    padding-top: 8.6em;   
                }
            }
            img.third {
                max-width: 142px;
            }
            img.forth {
                max-width: 140px;
                @media only screen and (min-width: $screen-sm) {
                    padding-top: 6.35em;
                }
            }

            img.fifth {
                max-width: 90px;
                @media only screen and (min-width: $screen-sm) {
                    padding-top: 5em;
                }
            }
            img.sixth {
                max-width: 90px;
                @media only screen and (min-width: $screen-sm) {
                    padding-top: 5em;
                }
            }
            ul {
                list-style: none;
                padding: 0;
                text-align: center;
                @media only screen and (min-width: $screen-sm) {
                    margin-bottom: 0;
                    text-align: left;
                    padding: 0 0 0 1em;
                }
            }
            a {
                font-size: 1.1em;
            }
        }

        h3 {
            color: #55575a;
            font-weight: 600;
            font-size: 1.25em;
            text-align: center;

            span {
                font-size: .8em;
                display: block;
            }

            @media only screen and (min-width: $screen-sm) {
                text-align: left;
            }
        }

        h4 {
            font-weight: bold;
            padding-top: 20px;
            padding-left: 13px;
            font-size: 1.25em;
        }

        .distributor {
            margin-top: 1em;
            a {
                font-size: 1.1em;
            }
        }

        .distributor-items {
            list-style: none;
            padding: 0 0 0 1em;
            @media only screen and (min-width: $screen-sm) {
                -webkit-column-count: 4;
                -moz-column-count: 4;
                column-count: 4;
            }
        }
    }
}

.vertical-vault,
.vertical-locksmiths,
.vertical-construction,
.vertical-manufacturing,
.vertical-university {

    #content .btn-primary {
        background: $red;
        border-radius: 0;
        font-size: 1.25rem;
        font-weight: 600;
        padding: 0.5rem 2rem;
        text-transform: uppercase;
        transition: background-color 150ms linear;

        &:hover {
            background: darken($red,10%);
        }
    }

    .hero {
        .section-header .section-title {
            @media (min-width: 768px) {
                font-size: 68px;
                width: 378px;
            }
        }

        .btn {
            line-height: 1.4;
            margin-bottom: 14px;

            @media (min-width: 768px) {
                margin: 0 0 0 115px;
            }
        }
    }

    .section.image-banner {
        .row.section-content {
            @media (min-width: 768px) {
                .col:first-child .fancybox-brightcove {
                    display: block;
                    height: 418px;
                    overflow: hidden;

                    img {
                        max-width: none;
                        width: 110%;
                        height: 100%;
                        margin-left: -4%;
                    }
                }

                .col.content-box {
                    padding-top: 1.25rem;

                    .top {
                        padding-bottom: 1rem;
                        padding-right: 1.5rem;
                        padding-left: 1.5rem;
                    }

                    .features {
                        padding: 0 1.5rem;

                        h3 {
                            margin-bottom: 0.5rem;
                        }

                        ul {
                            li {
                                padding-bottom: 0.25rem;
                            }
                        }
                    }

                    #vault-buy-now-3 {
                        margin: 0 0 2rem 0;
                    }
                }
            }
        }
    }

    .contact-form .section-content {
        @media (min-width: 768px) {
            padding-top: 1.25rem;
            padding-bottom: 1.75rem;
        }
    }

    .privacy-link a {
        color: #FFF;
        padding: 1em 0;
        display: inline-block;
        text-decoration: underline;
        @media only screen and (min-width: $screen-sm) {
            padding: 1.35em 0;
        }
        &:hover {
            text-decoration: none;
        }
    }

    .mobile-hide {
        display: none;
        @media only screen and (min-width: $screen-sm) {
            display: block;
        }
    }


   #door-controller-group {
        padding: 0;
    }

   .padlock,
   .lock-box,
   .door-controller {
        margin-left: 0 !important;
        padding: 0 !important;
    }

    h4.blue-title {
        text-align: center;
    }
}

.vertical-locksmiths {
    .first p {
        padding: 0 0 1rem 0;
    }

    .hero .btn {
        @media (min-width: 768px) {
            margin: 0;
        }
    }

    .hero p {
        @media (min-width: 768px) {
            //width of sub so it looks centered on the text
            width: 337px;
        }
    }

    .btn-secondary {
        background-color: #FFF;
        text-transform: uppercase;
        color: #00A6C9;
        font-weight: bold;

        &:hover {
            background-color: #CCC;
            color: #FFF;
        }
    }

    .eloqua p {
        padding-top: 0!important;
    }

}

.vertical.airport {
    nav.utility-bar {
        .row {
            display: none;
        }
    }
    #content {
        .hero {
            .section-header {
                @media only screen and (min-width: $screen-sm) {
                    padding: 175px 0 0 90px;
                }
                h1.section-title {
                    @media only screen and (min-width: $screen-sm) {
                        font-size: 58px;
                        width: 100%;
                    }
                }
            }
        }

        .flex {
            display: flex;
            flex-wrap: wrap;
            @media only screen and (min-width: $screen-sm) {
                flex-wrap: nowrap;
            }
        }

        .content-box {
            background: #e9e9e9;
            padding: 2em;
            @media only screen and (min-width: $screen-sm) {
                padding: 4em 5.25em;
            }

            a.vault-airport {
                margin-top: 2.85em;
                border: 2px solid #c00000;
                width: 215px;
                &:hover {
                    border: 2px solid #900000;
                }
            }
        }

        .top {
            padding: 0;
            h2 {
                border-bottom: none;
                padding-bottom: 0;
                @media only screen and (min-width: $screen-sm) {
                    font-size: 2rem;
                }
            }
        }

        .features {
            background: none;
            padding: 0 1em;
            ul {
                padding: 1em 0 0 0;
            }

            li {
                display: inline-block;
                font-size: 0.85rem;
                padding: 0 0.625rem 0.625rem 1rem;
                vertical-align: top;
                width: 100%;
                position: relative;
            }
        }

        .contact-form {
            background-color: #00a6c9;
            padding: 2em;
            line-height: normal;
            @media only screen and (min-width: $screen-sm) {
                padding: 4em 8em 4em 6em;
            }

            p {
                color: #ffffff;
                font-size: 1.2rem;
                font-weight: 600;
                padding: 0;
                text-transform: uppercase;
                width: 100%;
                margin-bottom: 0.5em;
            }

            .name-first {
                @media only screen and (min-width: $screen-sm) {
                    padding-right: 5px;
                }
            }

            .name-last {
                @media only screen and (min-width: $screen-sm) {
                    padding-left: 5px;
                }
            }

            label {
                color: #ffffff;
            }

            input {
                margin-top: 1em;
                @media only screen and (min-width: $screen-sm) {
                    margin-top: 0;
                }
            }

            input[type="checkbox"] {
                margin-bottom: 0;
            }

            .LV_valid {
                display: none;
            }

            .LV_invalid_field {
                border: 0.125rem solid #c00;
            }

            button.vault-airport {
                background: #ffffff;
                color: #00a6c9;
                -webkit-transition: background-color 150ms linear;
                transition: background-color 150ms linear;
                border: 2px solid #ffffff;
                width: 215px;
                &:hover {
                    color: #ffffff;
                    background: #00a6c9;
                    border: 2px solid #ffffff;
                }
            }

            .LV_invalid {
                color: #c00;
                display: block;
                font-size: 0.875rem;
                font-weight: 600;
                @media only screen and (min-width: $screen-sm) {
                    padding: 0.1875rem 0.1875rem 0 0.1875rem;
                }
            }

            .required-fields {
                padding: 0.5em 0 0 0;
            }

            .privacy-link a {
                padding: 1em 0;
            }
        }

        .featherlight-video {
            @media (min-width: 768px) {
                display: block;
                height: auto;
                overflow: hidden;

                img {
                    max-width: 1280px;
                    width: 100%;
                    height: 100%;
                    margin-left: 0;
                }
            }

            .fa-play {
                @media only screen and (min-width: $screen-sm) {
                    font-size: 46px;
                }
            }
        }

        .bluetooth {
            span.fa.fa-angle-down {
                position: absolute;
                top: 13px;
                margin-left: 11.25em;
                font-size: 2em;
                color: #00a6c9;
                @media only screen and (min-width: $screen-sm) {
                    top: 4px;
                    margin-left: 11em;
                    font-size: 2.5em;
                }
            }
        }

        .lock-box {
            @media only screen and (min-width: $screen-sm) {
                padding: 0 2.3em;
            }
        }

        .lock-box:last-child {
            @media only screen and (min-width: $screen-sm) {
                padding: 0 1.5em;
            }
        }

        .lock-box h4.blue-title,
        .details h4.blue-title {
            text-align: center;
        }

        @media only screen and (min-width: $screen-sm) {
            .padlock {
                padding: 0 2em;
            }

            .padlock:last-child {
                padding: 0 2.5em;
            }
        }

        .call-out {
            .flex {
                display: flex;
                justify-content: center;
                align-items: center;
            }

            h2 {
                border-bottom: none;
                color: #fff;
                font-weight: 600;
                width: auto;
                text-transform: uppercase;
                padding: 0;
                margin: 0 0 1em 0;
                font-size: 1.2rem;
                letter-spacing: 1px;
                text-align: center;
                margin: 0.5em 1em 0.5em 0; 
                @media only screen and (min-width: $screen-sm) {
                    font-size: 1.4rem;
                    margin: 0 2em 0 0; 
                }
            }

            .btn-primary {
                padding: 0.5em 2em;
                margin: 0;
            }
        }

    }

    .grecaptcha-badge {
        z-index: 1;
    }

}

.vertical-manufacturing,
.vertical-university,
.vertical-construction {
    .hero {

        .section-header {
            max-width: 900px;

            .btn-primary{
                margin-left: 0.25em !important;
                margin-top: 0.5em !important;
            }
        }
    }

    .section-content-full-width {
        padding-bottom: 0 !important;
    }

    .section-content-secondary {
        padding-top: 0.5rem !important;

        p {
            margin: 0 0 1rem 0 !important;
        }
    }

    .cta-try {
        background-color: #363636;
        color: #FFF;
        text-align: center;
        text-transform: uppercase;

        h3 {
            display: block;
            margin: 0 0 2rem 0;
            @media only screen and (min-width: $screen-sm) {
                display: inline-block;
                margin: 0 3rem 0 0;
            }
        }
    }

    #content {
        .contact-form {
            padding: 0 !important;
            background: none !important;
        }
    }
}

.brightcove-video-container {
    position: relative;
    width: 65%;
    margin: 2rem 0 3rem 0;

    .fa-play {
        position: absolute;
        top: 50%;
        left: 50%;
        font-size: 36px;
        color: #fff;
        background-color: $red;
        width: 2.75em;
        height: 2.75em;
        line-height: 2.75em;
        text-align: center;
        border-radius: 50%;
        padding-left: 0.2em;
        margin: -1.375em 0 0 -1.375em;
        transition: background-color 150ms linear;
    }

    &:hover {
        .fa-play {
            background-color: #000;
        }
    }
}

.bluetooth-grid{
    @media only screen and (min-width: $screen-sm) {
        display: grid;
        grid-template-columns: repeat(5, 1fr);
        grid-template-rows: 80px auto auto;
        .title {
            grid-row: 1;
        }
        .col-span-3 {
            grid-column: 1 / span 3;
        }
        .row-1 {
            grid-row: 1 / span 2;
        }
        .row-2 {
            grid-row: 2;
        }
        .image {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: flex-end;
            a {
                display: block;
            }
        }
        .product {
            grid-row: 3;
            align-self: start;
        }
    }
     .blue-title {
        font-size: 0.8125rem !important;
        margin-bottom: 0.375rem !important;
     }
     .product-description {
        font-size: 0.8125rem !important;
        color: #55575a;
        margin-bottom: 0;
     }
     .product-image {
        display: block;
        margin: auto;
        padding-top: 15px;
     }
     .category-title {    
        color: #55575a;
        font-size: 1.3rem;
        font-weight: 600;
        margin-top: 10px;
        margin-bottom: 14px;
     }
     .category-description {
        font-size: 0.8125rem !important;
     }
}