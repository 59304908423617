/*======================== General: Content Styles ======================= */
#content {
	margin-top: 20px;
	margin-bottom: 20px;
	
	@media only screen and (max-width: $screen-sm) {
		margin: 0;
	}
}

.home #content,
.home-segment #content,
.category #content,
.service-support-home #content {
	position: relative;
	top: -110px;

	@media only screen and (max-width: $screen-sm) {
		top: auto;
	}
}

.section-content {

	.section-content-title-lg {
		font-size: 40px;
		
		@media only screen and (max-width: $screen-sm) {
			font-size: 30px;
		}
	}

	.section-content-title-sm {
		font-size: 26px;
		display: table-row;

		.icon-header,
		.icon {
			display: table-cell;
			vertical-align: middle;
		}

		.icon-header {
			padding-left: 30px;
		}

		.icon {
			width: 68px;
			height: 71px;
		}
	}
}

.breadcrumb {
	margin: 1.5em 0 0 0;

	@media only screen and (max-width: $screen-sm) {
		display: none;
	}

	ol {
		@extend .reduced;
		margin: 0;
	}

	li {
		display: inline;
		font-size: .9em;

		& + li:before {
			content: $breadcrumb-separator;
			padding: 0 .25em;
		}
	}
	a { color: $brand-secondary;}
}

.page-header {
	padding: 0 0 .75em 0;
	@media only screen and (max-width: $screen-sm) {
		padding: 0;
	}
}

.page-title {
	font-size: floor($font-size-base * 2.9); // 40px
	margin-bottom: 30px;

	@media only screen and (max-width: $screen-sm) {
		font-size: floor($font-size-base * 2);
		margin: 0 0 15px 0;
	}
}

.page-description {
	font-size: $font-size-large;
}

.section {
	padding: 1em 0;

	&.no-divider {
		padding-top: 0;
		border-top: none;
	}

	.section {
		padding: 0;

		& + .section {
			padding-top: 1.5em;
			border-top: none;
		}
	}
}

// Full width header section
header.centered-red {
	h2 {
		background: $brand-secondary;
		color: #fff;
		height: 55px;
		line-height: 55px;
		margin: 0 auto;
		font-size: 23px;
		text-align: center;
		width: 1280px;

		@media only screen and (max-width: $screen-sm) {
			width: 100%;

		}
	}
}

/*======================== General Layout ======================= */

.section-general, .general .section:first-of-type {
	//@extend .gradient-gray-lighter;
	margin-top: 1em;
	padding-top: 3.5em;

	@media only screen and (max-width: $screen-sm) {
		padding-top: 1.5em;
		margin-top: 0.4em;
	}
}

.general & {
	.two-col {
		.md-3.first {
			padding-right: 40px;

			@media only screen and (max-width: $screen-sm) {
				padding-right: 0;
			}

			img {
				padding-bottom: 30px;
			}
		}

		.md-6 {
			width: 47%;
			
			@media only screen and (max-width: $screen-sm) {
				width: 100%;
			}
		}
	}

	h2 {
		margin-bottom: $font-size-h2;
		
		@media only screen and (max-width: $screen-sm) {
			margin: 10px 0;
		}
	}

	main .btn {
		margin: 5px 0;
	}

	.content-padding {
		padding: 20px 0;
	}
	
	.content-padding-left {
		padding: 5px 10px 5px 0;
	}
	
	.content-padding-right {
		padding: 5px 0 5px 10px;
	}

	.right.content-padding-right,
	.left.content-padding-left {
		@media only screen and (max-width: $screen-sm) {
			float: none;
			padding: 10px 0;
		}
	}

	.section .row .row {
		border-bottom: 1px solid #cccccc;
		padding: 20px 0;
		
		@media only screen and (max-width: $screen-sm) {
			margin: 0;
		}

	}
	.section .row fieldset .row {  // resets general styles for form field rows
		border-bottom: none;
		padding: inherit;
		
		@media only screen and (min-width: $screen-lg) {
			margin: 0 -10px;
		}
	}
	.section .row .row:last-child {
		border: 0;
		padding: 20px 0 10px 0;
	}

	.section .md-6.first {
		padding-right: 2%;
		
		@media only screen and (max-width: $screen-sm) {
			padding: 0;
		}
	}
	.section .md-6.last {
		padding-left: 2%;
		
		@media only screen and (max-width: $screen-sm) {
			padding: 0;
		}
	}

	.panel-primary {
		@extend .gradient-gray-dark;
		border: 0;
	}

	.panel-content {
		background-color: transparent;
		color: white;

		a {
			color: white;
			text-decoration: underline;
		}

		a:hover {
			color: darken(white,10)
		}
	}
	.panel-header {
		border-bottom: 1px solid rgba(250, 250, 250, 0.3);
	}

	.horizontal-line {
		border-top: 1px solid #a8a7a7;
		margin-top: 20px;
		padding-top: 20px;

		&:before {
			content: '';
			clear: both;
			display: table;
		}
	}
}

.form-container {
	max-width: 350px;
	margin-right: auto;
	margin-left: auto;
}

.section.first {
	padding-top: 2em;
	
	@media only screen and (max-width: $screen-sm) {
		padding-top: 1.5em;
	}
}

.section.last {
	padding-bottom: 3em;
	
	@media only screen and (max-width: $screen-sm) {
		padding-bottom: 1.5em;
	}
}

/*======================== General: Dropshadows ======================= */

img.box-shadow {
	box-shadow: 0 2px 5px 1px #ccc;
	margin-bottom: 10px;
}

/*======================== Social Nav ======================= */

.social-nav {
	@extend .reduced;

	li {
		display: inline-block;
		height: 40px;
		width: 33px;
		margin: 0;
	}

	a {
		display: block;

		&:hover { opacity: 0.8; }
	}
}

/*======================== Section Callouts ======================= */

.callout {
	@extend .gradient-callout-1;
	height: 189px;
	width: 100%;
	overflow: hidden;
	margin: 5px 0;
	position: relative;

	@media only screen and (max-width: $screen-sm) {
		max-width: 390px;
		height: 235px;
		margin: 0 auto 20px;
	}

	.callouts-primary & {
		@extend .gradient-callout-2;
	}

	.callouts-secondary & {
		@extend .gradient-callout-3;
	}

	.callouts-small & {
		height: 200px;
	}

	&.callout-wide {
		@media only screen and (min-width: $screen-sm+1) {
			.box-content {
				width: 65%;
			}
		}

		.img-callout-wide {
			@media only screen and (max-width: $screen-sm) {
				top: 19%;
			}
		}
	}

	a {
		&:hover {
			text-decoration: none;
		}
	}

	img {
		position: absolute;
		left: 0;
		top: 0;

		@media only screen and (max-width: $screen-sm) {
			max-width: 150px;
		}

		&.image-bottom {

			@media only screen and (max-width: $screen-sm) {
				max-width: 180px;
				top: auto;
				bottom: 45px;
			}
		}
	}

	.align-right {
	    right: 10%;
	    left: auto;
	    top: 13px;
	    @media only screen and (max-width: $screen-sm) {
			display:none;
		}
	}

	.box-content {
		position: relative;
		display: flex;
		flex-direction: column;
		justify-content: center;
		width: 47%;
		padding: 10px 10px 10px 0;
		margin-left: auto;
		color: #414141;
		height: 100%;

		@media only screen and (max-width: $screen-sm) {
			padding-bottom: 48px;
		}

		h2 {
			text-transform: uppercase;
			font-family: $font-family-narrow;
			font-size: 24px;
			margin-bottom: 0;

			@media only screen and (max-width: $screen-sm) {
				font-size: 18px;
			}
		}

		@media only screen and (min-width: $screen-sm+1) {
			&.box-content-wide {
				width: 67%;
			}
		}
	}
}

@media only screen and (max-width: $screen-sm) {
	.callouts-primary .callout {
		height: 212px;
	}
}

.ie {
	.callout .box-content {
		h2 {
			margin-top: 100px;
		}
	}

	.callouts-small .callout .box-content h2 {
		margin-top: 70px;
	}
}

.overlay-effect {
	overflow: hidden;
	height: 100%;

	@media only screen and (max-width: $screen-sm) {
		.link-title{
			background: none repeat scroll 0 0 #a40000;
			bottom: 0;
			clear: both;
			display: table;
			height: 48px;
			position: absolute;
			width: 100%;
		}
	}

	.link-title:before {
		position: absolute;
		bottom: 0;
		left: 0;
		width:  100%;
		height: 48px;
		background: #a40000;
		content: '';
		opacity: 0;
		transform: translate3d(0,100%,0);
		transition: opacity 0.2s ease-in-out, transform 0.2s;

		@media only screen and (max-width: $screen-sm) {
			background: transparent;
		}
	}

	span {
		position: absolute;
		bottom: 15px;
		line-height: 100%;
		font-weight: bold;
		left: 0;
		text-align: center;
		font-size: 16px;
		opacity: 0;
		width: 100%;
		color: #ffffff;
		transform: translate3d(5px,0,0);
		transition: opacity 0.4s, transform 0.4s;

		@media only screen and (max-width: $screen-sm) {
			display: table-cell;
			font-size: 14px;
			position: relative;
			line-height: 120%;
			vertical-align: middle;
			clear: both;
			padding: 0 10px 0 0;
			bottom: 0;
		}
	}
	span.assistive-text {
		opacity: 0;
		display: none;
	}

	@media only screen and (min-width: $screen-sm+1) {
		&:hover .link-title:before,
		&:hover span {
			opacity: 1;
			transform: translate3d(0,0,0);
		}
	}

	@media only screen and (max-width: $screen-sm) {
		.link-title:before,
		span {
			opacity: 1;
		}
	}

}

.ie8 .callout {

	.link-title {
		opacity: 1;
		display: block !important;
		position: absolute;
		bottom: -48px;
		left: 0;
		width:  100%;
		height: 48px;
		background: #a40000;

		span {
			position: absolute;
			bottom: 15px;
			line-height: 100%;
			font-weight: bold;
			left: 0;
			text-align: center;
			font-size: 16px;
			width: 100%;
			color: #ffffff;
		}
	}

	&:hover .link-title {
		bottom: 0;
	}
}

/*======================== Section Product Item List ======================= */

.item-list {

	.item {
		float: left;
		padding: 0 10px;
		margin: 10px 0;
		height: 442px;
		@extend .clearfix;

		@media only screen and (max-width: $screen-xs) {
			border-bottom: solid 1px #CCC;
			padding-bottom: 14px;
			height: auto;
			overflow: hidden;
		}
	}

	a {
		display: block;
	}

	.item-image {
		display: block;
		width: 100%;
		height: 135px;
		text-align: center;
		margin-bottom: 1em;
		text-decoration: none;

		&:hover {cursor: pointer;}

		&:before {
		  content: '';
		  display: inline-block;
		  height: 100%;
		  vertical-align: middle;
		  margin-right: -0.25em; /* Adjusts for spacing */
		}

		.item-image-src {
			max-width: 135px;
			max-height: 135px;
			display: inline-block;
			vertical-align: middle;
		}

		.image-no-script {
			display: inline-block;
			max-height: 135px;
			max-width: 135px;
			vertical-align: middle;
		}
	}

	.item-compare {
		margin-bottom: 1em;
		font-size: 12px;
		height: 24px;
		text-align: center;

		.compare,
		.btn,
		label  {
			display: inline-block;
			vertical-align: middle;
			margin: 0 2px;
		}

		.compare,
		.btn {
			margin: 0 2px;
		}

	}

	.item-content  {
		text-decoration: none;

		&:hover {
			cursor: pointer;
			.item-name-title {
				color: $brand-secondary;
				text-decoration: underline;
			}
		}
	}

	.item-name {
		height: 122px;
		overflow: hidden;

		.item-name-title {
			font-size: 15px;
			height: 82px;
			overflow: hidden;
			color: $brand-primary;
			margin-bottom: .25em;
			line-height: 1.08;
		}

		.item-name-model {
			font-size: 20px;
			font-weight: 500;
			margin-bottom: 10px;
		}
	}

	.item-price {

		@media only screen and (max-width: $screen-xs) {
			text-align: center;
		}

		.item-price-amount { margin: 0;}

		.item-price-large {
			display: inline-block;
			margin: 0 0 10px 0;
			height: 45px;
			color: #000;
			font-family: $font-family-narrow;
		}

		.item-price-large {
			font-size: 28px;
			sup { font-size: 60%; }

			.small { font-size: 13px; }

			@media only screen and (max-width: $screen-sm) {
				font-size: 30px;
			}
		}

		.item-price-title {
			display: inline-block;
			font-size: 14px;
			font-weight: 500;
			color: $brand-primary;
			@extend .border;
			border-width: 0 0 0 1px;
			padding: 0 0 0 .5em;
			margin: 0 0 0 .5em;
		}
	}

	.btn {
		margin-bottom: 1em;
		@media only screen and (max-width: $screen-xs) {
			display: block;
		}
	}

	.item-link-micro {
		font-size: 13px;
		margin-top: 1px;
		&:hover {
			color: $brand-secondary;
				text-decoration: underline;
		}
	}
}

/*======================== Content Filters ======================= */

.plus-minus {
	position: absolute;
	right: 10px;
	top: 0;
	font-size: 24px;
	font-weight: 500;
}

.plus-minus:after{
	content: "+";
}

.open .plus-minus:after {
	content:"-";
}

.zebra-accordion {
	* {
		box-sizing: content-box;
		-moz-box-sizing: content-box;
	}

	.list-accordion {
		display: none;
		padding: 10px 10px 24px 12px;
		margin: 1px 0;

		li {margin-bottom: 0.25em;}
	}

	.trigger {
		cursor: pointer;
		position: relative;
		margin: 0;
	}
}

.filter-content {

	@media only screen and (min-width: $screen-sm+1) { padding-right: 40px; }

	.selected-filters {
		display: block;
		padding: 0 0 10px;
		@extend .clearfix;

		@media only screen and (max-width: $screen-sm) {
			padding: 0 0 10px 12px;
		}

		&:hover {
			text-decoration: none;
		}

		.fa-times {
			top: 1px;
			position: relative;
		}

		.filter-name {
			width: 100%;
			margin-left: -14px;
			padding-left: 20px;
		}

	}

	.zebra-accordion {

		label, a { display: block; }

		a { padding: 5px 0; }

		.trigger {
			@extend .gradient-gray-dark;
			color: #ffffff;
			font-weight: 600;
			font-size: 14px;
			padding: 5px 12px;
		}
	}

}

/*======================== FAQ Pages ======================= */
.faq {

	.section {
		padding: 0 0 1em;
	}

	.page-title {
		margin-bottom: 15px;
	}

	.faq-contact {
		clear: both;
		border-top: 1px solid #cfcfcf;
		border-bottom: 1px solid #cfcfcf;
		margin: 55px 0 40px;
		padding: 18px 0;

		@media only screen and (max-width: $screen-sm) {
			margin: 2em 0;
		}

		.col {
			float: none;
			margin: 0 auto;

			&.text-center {
				text-align: center;
			}
		}

		@media only screen and (max-width: $screen-sm) {
			.col {
				padding: 0 20px;
			}
		}

		h2 {
			margin-bottom: 5px;
			font-size: 27px;

			span {
				color: $link-color;
				font-size: 26px;
				font-weight: 600;
				text-transform: uppercase;
			}
		}
	}
}

.section-content {

	.faq-search-bar {
		background: #444;
		padding: 15px 20px;
		margin: 0 0 2em 0;

		.faq-search {
			position: relative;
		}

		.search-button {
			position: absolute;
			right: 0;
		}

		.search-field {
			margin-right: 9px;
			width: 82%;
		}

		@media only screen and (max-width: $screen-sm) {
			.search-field {
				width: 100%;
			}

			.btn-primary {
				margin-top: 15px;
				width: 100%;
			}
		}

	}

	.answer{
		float: left;
		padding-right: 10px;
	}

	.zebra-accordion {

		padding-bottom: 55px;

		.question-container{
			padding: 15px;
			background: #eee;
			margin-bottom: 7px;
		}

		.trigger {

			font-weight: 500;
			color: #000;
			padding: 0 30px 0 0;

			&.faq-question {
				font-size: 18px;
				margin: 0;
			}

			.faq-question {
				font-size: 18px;
			}
		}

		.faq-category {
			font-size: 12px;
			background: #eee;
			padding-top: 10px;
			margin: 0;
		}

		.filter-content {
			margin-top: 0.7em;

			a {
				border-bottom: 1px solid #e3e3e3;
				padding: 10px 0;
			}
		}


	}
}

/*======================== Product Compare ======================= */
.product-comparison {

	@media only screen and (max-width: 767px) {

		#content {
			overflow-x: scroll;
		}
/*
		#site-header {
			overflow-x: hidden;
		}
*/
		.compare-table {
			margin: 0 !important;
			width: auto !important;

			.category {
				width: 120px !important;
			}

			.item {
				min-width: 300px !important;
			}
		}
	}

	@media only screen and (max-width: 420px) {
		.compare-table {

			.item {
				min-width: 220px !important;
			}
		}
	}

	#content {
		margin-bottom: 0;
	}

	.compare-table {
		margin: 0 auto 60px;
		width: 1200px;

		tr:nth-child(even) {
			background: $table-background;
		}

		.model-num {
			color: $brand-secondary;
			font-size: 20px;
		}

		figure {
			margin: 1em 0;
			min-height: 160px;
			text-align: center;

			figcaption {
				font-size: 12px;
				line-height: 15px;
			}
		}

		.price-main {
			text-align: center;
		}

		.price-large {
			display: block;
			font-family: $font-family-narrow;
			font-size: 20px;
			padding-top: 5px;

			sup {
				top: -0.3em;
			}
		}

		.block {
			display: inherit;
		}

		.category {
			font-weight: 700;
			padding: 12px 20px;
			text-align: right;
			max-width: 185px;
		}

		.item {
			border-left: $table-border-light;
			padding: 12px 25px;
			vertical-align: middle;
			max-width: 350px;

			&:last-child {
				border-right: $table-border-light;
			}

			ul {
				margin: 0;
				padding: 0 0 0 19px;

				li {
					padding: 2px 0;
				}
			}
			&.top {
				vertical-align: top;
			}
		}
	}

	.bold-unique {font-weight: bold;}
}

/*======================== Section Tough Under Fire ======================= */
.full-testimonial {

	@media only screen and (min-width: $screen-sm+1) {
		padding: 30px;
	}
}

.tough-under-fire-testimonials {



	h2 {
		background: #ab0000;
		color: #ffffff;
		text-align: center;
		font-size: 24px;
		height: 55px;
		line-height: 55px;
		width: 1280px;
		margin: 0 auto;
		@media only screen and (max-width: $screen-sm) {
			width: 100%;
		}
	 }

	.read-more {
		display: block;
		margin: 10px 0 0 20px;
	}

	blockquote {
		margin: 0 20px;
		padding: 0;

		@media only screen and (min-width: $screen-sm+1) {
			margin: 0;
		}

		p {
			font-size: 14px;
			padding: 0 20px;
			margin: 0;
			position: relative;
		}

		footer {
			margin: 1em 0 0 20px;

			@media only screen and (max-width: $screen-sm) {
				margin: .5em 0 1em 20px;
			}
		}

		.gradient-fade {
			@extend .gradient-fade-bottom-white;
			display: block;
			position: relative;
			height: 20px;
			margin: -20px 20px 0 0;
		}
	}

	.quote {
		position: absolute;
		display: inline-block;
		font-size: 30px;
		width: 20px;
		height: 20px;
		color: #d9d9d9;
		font-family: $font-family-narrow;
	}

	.top-quote {
		top: -10px;
		left: 0;
	}

	.bottom-quote {
		bottom: 0;
		right: 0;
	}

	.section-content {
		padding: 3em 0;

		@media only screen and (max-width: $screen-sm) {
			padding: 1em 0;
		}

	}

	.icon-tough-under-fire-dark {
		width: 132px;
		height: 129px;
		margin: 0 auto 1em;
	}

	.read-all { text-align: center; }
}
/*======================== Page: Custom Locks, Safety ======================= */

.safety-solutions {
	.hero .section-header .section-title {
		width: 520px;
		@media only screen and (max-width: $screen-sm) {
			width: 100%;
		}
	}

	.safety-products {
		text-align: center;

		.btn-safety {
			border-radius: 20px;
			box-shadow: 2px 3px 10px rgba(#000,0.4);
			font-size: 16px;
			padding: 0.75em 4em;
		}
	}

	.new-products {
		img {
			top: -24px;
			left: 60px;

			@media only screen and (max-width: 767px) {
				top: -15px;
				left: 27px;
			}
		}
	}

	.lockout-services {
		img {
			left: 35px;
			top: 20px;
			width: 40%;

			@media only screen and (max-width: 767px) {
				left: 23px;
				top: 25px;
			}
		}
	}

	.choose-padlock {
		img {
			top: 19px;
			left: 14px;
			width: 45%;
		}
	}

	.keying {
		img {
			top: 10px;
			left: 25px;
			width: 40%;
		}
	}

	.knowledge {
		img {
			top: -31px;
		}

		.link-title {
			span {
				font-size: 14px;
			}
		}
	}
}

.keying-critical-component {
	.keying-extra {
		background: #e8e8e8;
		clear: both;
		display: none;
		padding: 30px;
	}
}

.safety {
	.safety-news {
		border: 1px solid #eee;
		margin-bottom: 30px;
		width: 96%;

		h2 {
			background: #eee;
			font-size: 19px;
			font-weight: 700;
			padding: 13px 0;
			text-align: center;
		}

		ul {
			list-style: none;
			margin-bottom: 25px;
			padding: 0 30px;

			li {
				&.featured-article {
					border-bottom: 2px solid #eee;
					margin-bottom: 17px;
					padding-bottom: 7px;

					.date {
						float: none;
						font-weight: 600;
						height: auto;
						margin-bottom: 5px;
					}

					.thumb {
						float: left;
						margin-right: 18px;
						max-width: 150px;
					}

					a {
						font-weight: 600;
					}

					.publisher {
						color: #555;
						display: block;
						font-size: 13px;
						font-style: italic;
						margin: 5px 0 15px;
					}

					@media only screen and (max-width: 767px) {
						margin-top: 20px;
					}
				}

				.date {
					display: block;
					float: left;
					height: 45px;
					margin-right: 5px;
					min-width: 105px;
				}

				a {
					font-weight: 600;
				}

				.publisher {
					color: #555;
					display: block;
					font-size: 13px;
					font-style: italic;
					margin: 5px 0 15px;
				}
			}
		}

		@media only screen and (max-width: 767px) {
			width: 100%;
		}
	}

	.knowledge-center {
		.page-title {
			margin-bottom: 6px;
		}

		.zebra-accordion {
			@media only screen and (max-width: 767px) {
				padding: 20px 0 15px;
			}
		}

		h2 {
			margin-bottom: 15px;
		}
	}

	.industry-news {
		margin: 0;
		padding: 0 !important;

		ul {
			list-style: disc;
			padding: 5px 25px;

			li {
				margin-bottom: 12px;

				.date {
					display: block;
					float: left;
					height: 50px;
					min-width: 130px;
				}

				a {
					font-weight: 600;
				}

				.publisher {
					color: #555;
					display: block;
					font-size: 13px;
					font-style: italic;
					margin: 5px 0 15px;
				}
			}
		}

		.news {
			margin-top: -30px;
		}
	}
}

.kina-repp-img {
	margin-bottom: 5px;
}

/* Kina Repp Page */
.kina-repp {
	.section {
		&:first-of-type {
			padding-top: 2em !important;
		}

		.row {
			&.content-section {
				.page-title {
					font-size: 25px;
					font-weight: 600;
					margin-bottom: 15px;
				}

				&.safety-awareness {
					.content {
						margin: 70px 0 0 50px;

						@media only screen and (max-width: $screen-sm) {
							margin: 0;
						}
					}
				}

				&.kina-video {
					padding: 40px 0;

					.content {
						margin-top: 30px;
						padding-right: 120px;

						@media only screen and (max-width: $screen-sm) {
							margin: 0;
							padding: 0;
						}
					}

					.video-download-container {
						font-size: 12px;
						line-height: 18px;
						padding: 8px;

						#downloadLink {
							margin-right: 3px;
						}
					}

					@media only screen and (max-width: $screen-sm) {
						padding: 20px 0;
					}
				}

				&.speaking-sessions {
					.content {
						margin-top: 50px;

						.btn {
							font-weight: 600;
							margin-top: 20px;
							text-transform: uppercase;

							@media only screen and (max-width: $screen-sm) {
								width: 100%;
							}
						}

						.quote {
							font-size: 27px;
							font-style: italic;
							font-weight: 600;
							margin-bottom: 25px;

							span {
								color: #929292;
								display: block;
								font-size: 19px;
								margin: 3px 0 0 525px;

								@media only screen and (max-width: $screen-sm) {
									margin: 10px 0 0 0;
								}
							}

							@media only screen and (max-width: $screen-sm) {
								font-size: 22px;
							}
						}

						@media only screen and (max-width: $screen-sm) {
							margin: 0;
						}
					}
				}

				.kina-profile {
					position: relative;
					bottom: -48px;
					right: -120px;

					img {
						margin-top: 35px;
						max-height: 525px;

						@media only screen and (max-width: $screen-sm) {
							margin-top: 0;
						}
					}

					@media only screen and (max-width: $screen-sm) {
						right: 0;
						text-align: center;
					}
				}
			}

			&:last-child {
				 border-bottom: none;
			}
		}
	}
}

.custom-locks &,
.safety &,
.merchandising &,
.customization-options &,
.locker-customization-options &,
.locker-design-resource-center &,
.locker-installation-maintenance &,
.locker-innovation-technologies &,
.door-hardware-innovation &,
.door-hardware-keying-service &,
.door-hardware-tools-resources & {

	.section-content .zebra-accordion .trigger {
		font-size: 18px;
		padding: 10px 25px 10px 15px;
		margin-bottom: 7px;
	}

	.open + .list-accordion {
		height: 100% !important;
	}

	.list-accordion {

		.md-9 {
			width: 66%;
			@media only screen and (max-width: $screen-sm) {
				width: 100%;
			}
		}

		.md-4 {
			width: 30.333%;
			@media only screen and (max-width: $screen-sm) {
				width: 100%;
			}
		}
	}

	.trigger {
			background: none repeat scroll 0 0 #eee;
			margin-bottom: 7px;
			padding: 15px;
		}

	.list-accordion:after {
		clear: both;
		content: "";
		display: table;
	}

}

/*======================== Page: Customize Locks ======================= */
.customization-options, .locker-customization-options {
	.color-option {
		display: inline-block;
		width: 33px;
		margin-top: 10px;
		@media only screen and (max-width: $screen-sm) {
			font-size: 12px;
		}
	}

	.color-block {
		height: 33px;
		width: 33px;
		display: block;
	}

	.details {
		text-align: left;

		ul {
			min-height: 130px;

			li {
				list-style-type: disc;
			}
		}
	}

	.red {background: #ed1c24;}
	.yellow {background: #fff200;}
	.yellow-gold {background: #d1b800;}
	.green {background: #00a651;}
	.green-smoothspin {background: #089584;}
	.blue {background: #0054a6;}
	.black {background: #000000;}
	.teal {background: #00a99d;}
	.purple {background: #92278f;}
	.orange {background: #f7931d;}
	.brown {background: #602e31;}
	.gold {background: #dec6a0;}
	.grey {background: #999999;}
	.white {
		border: 1px solid #cccccc;
		box-sizing: border-box;
	}
	.clear-color {
		border: 1px solid #cccccc;
		box-sizing: border-box;
		background: rgb(200,201,203);
		background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIxMDAlIiB5Mj0iMCUiPgogICAgPHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iI2M4YzljYiIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjEyJSIgc3RvcC1jb2xvcj0iI2ZmZmZmZiIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjIwJSIgc3RvcC1jb2xvcj0iI2ZmZmZmZiIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjEwMCUiIHN0b3AtY29sb3I9IiNjOGM5Y2IiIHN0b3Atb3BhY2l0eT0iMSIvPgogIDwvbGluZWFyR3JhZGllbnQ+CiAgPHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEiIGhlaWdodD0iMSIgZmlsbD0idXJsKCNncmFkLXVjZ2ctZ2VuZXJhdGVkKSIgLz4KPC9zdmc+);
		background: -webkit-gradient(linear, left top, right top, color-stop(0%,rgba(200,201,203,1)), color-stop(12%,rgba(255,255,255,1)), color-stop(20%,rgba(255,255,255,1)), color-stop(100%,rgba(200,201,203,1)));
		background: -webkit-linear-gradient(left, rgba(200,201,203,1) 0%,rgba(255,255,255,1) 12%,rgba(255,255,255,1) 20%,rgba(200,201,203,1) 100%);
	}
}

/*======================== Page: Customize Locks ======================= */
.locker-design-resource-center & {

	.callout-copy {
		font-size: 25px;
		margin: 45px 0;
		border-right: 3px solid rgba(191, 0, 0, .8);
		padding: 10px 25px 10px 0;
		text-align: right;
		color: #333;
		font-family: $font-family-narrow;

		@media only screen and (max-width: $screen-sm) {
			margin: 10px 0;
			text-align: left;
			padding: 0;
			border: 0;
			font-size: 20px;
		}
	}

	.image-left {
		width: 38.667%;
		padding:0;
		@media only screen and (max-width: $screen-sm) {
			width: 100%;
		}
	}

	.two-col .md-6{
		width: 45%;
		@media only screen and (max-width: $screen-sm) {
			width: 100%;
			padding: 0;
			margin:0;
		}

		a {
			width: 80%;
			@media only screen and (max-width: $screen-sm) {
				width: 50%;
			}
		}
	}

	.md-3.first .btn {
		margin: 30px 0;
	}

	.electronic-locks {
		.content {
			margin-top: 30px;

			@media only screen and (max-width: $screen-sm) {
				margin-top: 0;
			}
		}
	}

}
/*======================== Page: Testimonials ======================= */

.testimonials {

	.section-content {
		max-width: 900px;
		margin: 0 auto 4em auto;

		@media only screen and (max-width: $screen-sm) {
			margin-bottom: 2em;
		}

		h1 {
          margin: 1em 0 .25em;
		}
	}
}

/*======================== Page: Connect ======================= */

.connect {
	.callout-links {
		margin: 2em 0 3em 0;
	}

	.md-7 h2:after, h3.panel-title:after {
		display: table;
		content:"";
		clear: both;
	}

	.col-sm .col:nth-child(odd), .md-4 .col-sm .col {
		padding-left: 0;
		@media only screen and (max-width: $screen-sm) {
			padding-left: 5px;
		}
	}

	.md-4 {
		border-left: 1px solid #ccc;
		padding-left: 3%;

		@media only screen and (max-width: $screen-sm) {
			padding-left: 0;
			border: 0;
		}
	}
}

/*======================== Page: Electronic Locker Lock ======================= */
.electronic-locker-lock {
	.btn {
		font-weight: 600;
		text-transform: uppercase;
	}

	h1 {
		text-shadow: 4px 6px 9px rgba(#000,0.7);
		font-size: 50px !important;
		@media only screen and (max-width: $screen-sm) {
			text-shadow: none;
		}
	}

	.title {
		color: #bd1829;
		font-size: 30px;
		font-weight: 700;
		margin: 40px 0 30px;
		text-transform: uppercase;

		&.icons {
			font-size: 22px;
			margin: 60px 0 15px;

			@media only screen and (max-width: $screen-sm) {
				margin: 30px 0 15px;
			}
		}
	}

	.lock-content {
		padding-left: 45px;

		h2 {
			color: #bd1829;
			font-size: 40px;
			font-weight: 700;
			margin-bottom: 15px !important;
			text-transform: uppercase;
		}

		ul {
			padding-left: 8px !important;

			li {
				list-style: none;

				&:before {
					color: #bd1829;
					content: '\2022';
					font-size: 22px;
					margin-right: 4px;
					vertical-align: middle;
				}
			}
		}

		@media only screen and (max-width: $screen-sm) {
			padding: 0 5px;
		}
	}

	.BrightcoveExperience {
		@media only screen and (max-width: $screen-sm) {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: auto;
		}
	}

	.animation {
		margin-top: 20px;

		.btn {
			font-weight: 600;
			text-transform: uppercase;
			width: 100%;
		}

		@media only screen and (max-width: $screen-sm) {
			margin-top: 0;
			text-align: center;
		}
	}

	.video {
		background: #e1e1e1;
		margin: 20px auto 40px;
		padding: 0 20px;
		position: relative;
		text-align: center;
		@media only screen and (max-width: $screen-sm) {
			margin: 0 auto 0;
		}
		.flex {
			display: flex;
    		align-items: center;
    		margin: 0;
    		@media only screen and (max-width: $screen-sm) {
    			display: block;
    		}

    		h2 {
    			color: #bd1829;
    			text-transform: uppercase;
    			font-weight: 600;
    			margin-bottom: 0.6em;
    		}
		}

		.content-left {
		    text-align: left;
		}
	}

	.features {
		margin-top: 30px;

		.display-icons {
			width: 100%;
			max-width: 950px;
		}
	}

	.list {
		margin-top: 40px;

		.content {
			background: #e7e7e8;
			margin-left: 15%;
			padding: 25px 30px;

			.title {
				color: #bd1829;
				font-size: 20px;
				font-weight: 700;
				margin: 10px 0;
				text-transform: uppercase;
			}

			@media only screen and (max-width: $screen-sm) {
				margin-left: auto;
				padding: 20px;
			}
		}

		.image {
			padding: 0;

			img {
				max-width: 301px;

				@media only screen and (max-width: $screen-sm) {
					max-width: none;
				}
			}
		}

		.button {
			margin: 1% 0 0 15%;

			@media only screen and (max-width: $screen-sm) {
				margin: 1% auto 0;
				text-align: center;
			}
		}

		@media only screen and (max-width: $screen-sm) {
			margin-top: 15px;
		}
	}

	.additional {
		margin: 70px auto 0;
		max-width: 950px;

		.title {
			color: #bd1829;
			font-size: 20px;
			font-weight: 700;
			margin: 10px 0 10px 20px;
			text-transform: uppercase;
		}

		.info {
			background: #e7e7e8;
			margin-bottom: 10px;
			padding: 30px 25px;

			.title {
				color: #000;
				font-size: 15px;
				margin: 0 0 8px;
				text-transform: initial;
			}

			.image {
				img {
					margin-top: -113px;
					position: absolute;
					width: 100px;

					@media only screen and (max-width: $screen-sm) {
						margin: 0;
						position: relative;
					}
				}

				@media only screen and (max-width: $screen-sm) {
					text-align: center;
				}
			}

			&.last {
				padding: 30px 25px 30px 0;

				.border {
					border-left: 2px solid #bd1829;
					display: block;
					padding-left: 30px;

					@media only screen and (max-width: $screen-sm) {
						border: none;
					}
				}

				.image {
					img {
						margin-top: 0;
						position: absolute;
						width: 110px;

						@media only screen and (max-width: $screen-sm) {
							margin: 0 0 15px;
							position: relative;
						}
					}

					@media only screen and (max-width: $screen-sm) {
						text-align: center;
					}
				}
			}

			@media only screen and (max-width: $screen-sm) {
				margin-bottom: 0;
			}
		}
	}
}

/*======================== Page: Multi User ======================= */

.multi-user {
	.md-9 li:after {clear: both; display: table; content:"";}
	.multi-user-locks {
		.lock {
			border-bottom: 1px solid #cccccc;
			padding: 5px 0;
			margin: 10px 0;
			img {float: left; padding: 0 10px 10px 0;}
			&:after {clear: both; display: table; content:"";}
			&:last-child {border: 0;}
		}
	}
	.section .row .row{
			border-bottom: none;
	}
}

.mult-user {
	.md-9 img { float: left; padding: 0 10px 10px 0;}
	.md-9 li:after {clear: both; display: table; content:"";}
	.md-3 img  {float: right; padding: 0 0 10px 10px;}
}

/*======================== Page: Manuals ======================= */

.manuals a.no-pdf[href$=".pdf"]:after  {
	content: "";
}

/*======================== Page: ADA ======================= */

.ada-benefits .panel-primary  {
	height: 260px;
	@media only screen and (max-width: $screen-sm) {
		height: inherit;
	}
}

/*======================== Page: Featured Products ======================= */

.featured-products main{

	.md-10 {margin: 0 auto;}

	.md-8 { padding: 0; }
	.col.md-4 {
		padding: 0 20px;
		@media only screen and (max-width: $screen-sm) {
			padding: 0 10px;
		}
	}

	.col.md-6 {
		padding:0;
		margin: 10px 2% 10px;
		min-height: 445px;

		@media only screen and (max-width: $screen-sm) {
			margin: 10px 0;
			min-height: inherit;
		}
	}
	.md-6 {
		width: 46%;

		@media only screen and (max-width: $screen-sm) {
			width: 100%;
		}

		h3, p {
			padding: 10px 10px 0 10px;
			margin: 0;
		}

		.btn {margin: 20px 0 30px 10px;}
	}
}

.featured-top {
	padding: 0 10px 40px 10px;
	margin: 10px 0 40px 0;
	border-bottom: 1px solid #ccc;

	@media only screen and (max-width: $screen-sm) {
		margin: 10px 0;
	}
}

.featured-top:after{
	clear: both;
	display:table;
	content:"";
}

/*======================== Page: Events ======================= */

.events {
	.tradeshows {

		h4 {font-size: 16px;}
		li {
			padding: 20px 10px;
			border-top: 1px solid #ccc;
			margin: 0;

			&:last-child{border-bottom: 1px solid #ccc;}
			&:after {
				clear: both;
				display: table;
				content: "";
			}
		}

		li:nth-child(even) {
			background: #eee;
		}
		.col {
			 padding: 0 10px 0 0;
			 width: 20%;

			 @media only screen and (max-width: $screen-sm) {
				width: 100%;
				padding: 10px 0;
			}
		}
	}
}
/*======================== Page: Global Sites ======================= */

.global-sites {

		.col.md-12 {
			padding: 20px 10px;
			border-top: 1px solid #ccc;
			margin: 0;

			&:last-child{border-bottom: 1px solid #ccc;}
			&:after {
				clear: both;
				display: table;
				content: "";
			}
		}

		.col.md-12:nth-child(even) {
			background: #eee;
		}


}

/*======================== Fragment: Product Selector ======================= */

.home .callout .product-selector img {
	max-width: 62%;
	top: 0;
}

.link-product-selector .product-selector {
	height: 100%;
}

.product-selector-form {
	@extend .gradient-red;
	width: 100%;
	padding: 30px;

	a, h4, legend, label, .steps { color: #fff; }

	.product-selector-nav .steps {
		font-family: $font-family-narrow;
		text-align: center;
		display: inline-block;
		color: #FFFFFF;

		.number {
			background: #fff;
			color: $brand-secondary;
			border-radius: 50%;
			height:28px;
			width: 28px;
			line-height: 28px;
			margin: 0 auto;
		}

		.step {
			font-size: 11px;
			padding-top: 5px;
			text-transform: uppercase;
			letter-spacing: .09em;
			color: #FFFFFF;
			white-space: nowrap;
		}
	}

	.product-selector-nav ul {
		margin-bottom: 1.75em;

		li {
			margin-left: 0;
		}
	}

	.product-selector-questions {

		@extend .clearfix;

		h4 {
			font-family: $font-family-narrow;
			font-size: 18px;
			letter-spacing: .09em;
			text-transform: uppercase;
		}

		input[type=radio] {
			background-color: transparent;
		}
	}

	label { display: block; }

	&.page {

		padding: 20px 0 20px 20px;

		@media only screen and (max-width: $screen-sm) {
			padding: 0;
		}

		.product-selector-nav {
			@media only screen and (max-width: $screen-sm) {
				margin: 0;
			}
			.col {
				@extend .clearfix;
				border: 1px solid #b94d4d;
				border-width: 0 0 0 1px;
				padding: 0 20px;
				height: 160px;

				@media only screen and (max-width: $screen-sm) {
					border-width: 1px 0 0 0;
					padding: 1em 20px;
					height: auto;
					width: 100%;
				}

				&:first-child {
					border: 0;
				}

				&.step4 {
					text-align: center;
					padding-top: 4.5%;
				}

				fieldset {
					width: 100%;
					padding: 1em 0 0 10px;
				}

				label { font-size: 13px; }

				.btn { margin: 0 auto; }

				.steps, h4 {
					float: left;
				}


				h4 {
					width: 100%;
					margin: 6px 0 0 -35px;
					padding-left: 55px;
				}
			}
		}

		.step1 { width: 21%; }
		.step2 { width: 32%; }
		.step3 { width: 32%; }
		.step4 { width: 15%; }

	}

	&.modal {
		width: 425px;
		min-width: 276px;
		height: 300px;

		@media only screen and (max-width: $screen-sm) {
			width: 100%;
			padding: 1em;

			h4 { font-size: 17px; }
		}

		.steps { opacity: .5; }

		#setPrefs, .active { opacity: 1; }

		.back {
			text-decoration: underline;
			cursor: pointer;
			opacity: 1;
		}

		.product-selector-nav .steps {
			margin: 0 auto;
			width: 32%;
		}
	}
}

.product-search .filter-content .zebra-accordion a.search-category {
	padding-bottom: 0;
}
.product-selector-results .category-name,
.product-search .category-name{
	font-size: 12px;
	margin-left: 3px;
}

.product-selector-results {
	width: 90%;
	margin: 0 auto;

	.product-list-item {
		float: left;
		height: 320px;
		padding: 15px;
		margin: 1em 0;

		@media only screen and (max-width: $screen-xs) {
			width: 100%;
			height: auto;
		}
	}

	.product-image {
		height: 135px;
		width: 135px;
		text-align: center;
		margin: 0 auto 1em auto;

		img {
			max-width: 100%;
			max-height: 100%;
		}
	}

	.product-description {
		h3 {
			font-size: 20px;
			margin-bottom: 12px;

			@media only screen and (max-width: $screen-sm) {
				font-size: 18px;
			}
		}

		p, a { font-size: 14px; }

		.category-name {
			color: $color-base;

			&:hover {
				color: $link-color;
			}
		}
	}
}

/*======================== Fragment: More from Master Lock ======================= */

.list-site {

	text-align: center;

	img {
		width: 100%;
		max-width: 240px;
		max-height: 179px;
	}

	.list-site-name {
		font-size: 15px;
		margin-bottom: 15px;
	}

	.list-site-description {
		text-align: left;
		font-size: 13px;
		margin: 0 auto 20px auto;
		color: $color-base;
		max-width: 240px;
		height: 32px;

		@media only screen and (max-width: $screen-sm) {
			text-align: center;
			height: auto;
		}
	}

	a:hover {
		text-decoration: none;
	}

	.btn { margin-bottom: 2em; }

}

.more-from-masterlock {
	@extend .gradient-gray-bottom;
	padding: 0;
	margin: 2em auto 0;
	width: 1280px;
	@media only screen and (max-width: $screen-sm) {
			width: 100%;
		}

	.section-content {
		margin: 0;
		padding: 3em 0 0;

		@media only screen and (max-width: $screen-sm) {
			padding: 0;
		}
	}

	.list-site {

		img {
			width: 175px;
			height: 131px;
		}

		.list-site-description {
			width: 180px;
		}
	}

	.panel {
		height: 390px;
		position: relative;

		@media only screen and (max-width: $screen-sm) {
			height: auto;
		}

		.see-all {
			position: absolute;
			bottom: 25px;
			left: 36%;

			@media only screen and (max-width: $screen-sm) {
				position: relative;
				bottom: 0;
				left: 0;
				text-align: center;
			}
		}
	}

	.master-lock-websites {
		@media only screen and (min-width: $screen-sm+1) {
			padding-left: 20px;
		}

	}

	.testimonials {

		&.col {
			padding: 0;
		}
		@media only screen and (min-width: $screen-sm+1) {
			padding-right: 20px;
		}

		@media only screen and (max-width: $screen-sm) {

			.tough-logo { text-align: center; }

		}

		.tough-under-fire-testimonials {

			.md-4 { width: 100%; }

			@media only screen and (max-width: $screen-sm) {
				height: auto;
			}
		}
	}
}

.home-segment.business .more-from-masterlock .panel .see-all {
	left: 41%;

	@media only screen and (max-width: $screen-sm) {
		position: relative;
		bottom: 0;
		left: 0;
		text-align: center;
	}
}

.home-segment .more-from-masterlock {
	.md-2 {
		width: 20%;

		@media only screen and (max-width: $screen-sm) {
			width: 100%;

		}
	}
}

/*======================== Page: Site Search ======================= */
@media only screen and (min-width: $screen-sm+1) {
	.product-search.search h1 { margin-bottom: 1.5em; }
}

/*======================== Page: Door Hardware Tools and Resources ======================= */
.door-hardware-tools-resources {
	.choosing, .terms {
		.col {
			display: inline-block;
			vertical-align: top;
			float: none;
			margin-bottom: 20px;
		}
	}
}


/*======================== Page: Section ======================= */

.hero {
	position: relative;
	width: 1280px;
	margin: 0 auto;
	overflow: hidden;
	text-align: center;
	background: #ffffff;
	z-index: 0;

	@media only screen and (max-width: $screen-sm) {
		width: 100%;
		height: auto;
	}

	.home &,
	.home-segment &,
	.category &,
	.service-support-home & {
		margin-bottom: 10px;
	}

	&.first {
		margin-bottom: 1.25em;
		padding-top: 10px;

		.section-header {
			padding: 75px 0 0 150px;
		}

		@media only screen and (max-width: $screen-sm) {
			margin-bottom: 0;
			padding: 0;

			.section-header {
				padding: 15px;
			}
		}
	}

	.hero-image {
		display: block;
	}

	.hero-image,
	.section-header {
		text-align: center;
		z-index: 0;

		@media only screen and (max-width: $screen-sm) {
			height: auto;
		}
	}

	.section-header {
		@extend .clearfix;
		position: absolute;
		top: 0;
		max-width: 700px;
		padding: 200px 0 0 100px;
		text-align: left;
		color: #fff;
		z-index: 3;

		@media only screen and (max-width: $screen-sm) {
			padding: 15px;
			position: relative;
			p {
				color: $color-base;

				.line {display: inline;}
			}
		}

		.section-title {
			font: 72px/1.1 $font-family-narrow;
			text-transform: uppercase;

			@media only screen and (max-width: $screen-sm) {
				font-size: 35px;
				color: $color-base;
			}


			.line {
				display: block;

				@media only screen and (max-width: $screen-sm) {
					display: inline;
				}
			}

			.line1 {
				font-size: 35px;
			}
		}

		p {
			font-size: 18px;
			width: 78%;
			@media only screen and (max-width: $screen-sm) {
				width: 100%;
			}
		}
	}
}

.hero-title {
	color: #fff;
	font: 60px/1.1 Oswald,sans-serif;
	text-transform: uppercase;
	position: absolute;
	right: 70px;
	bottom: 35px;
	@media only screen and (max-width: $screen-sm) {
		font-size: 22px;
		bottom: 15px;
		right: 25px;
	}
}

.hero-brands {
  position: relative;
  max-width: 1280px;
  margin: 0 auto;
  padding: 2.5rem;
  background: #363636;
  text-align: center;

  @media only screen and (max-width: $screen-sm) {
    padding: 1rem
  }

  .img-brand {
    max-width: 400px;
    margin: 0 auto;

    @media only screen and (max-width: $screen-sm) {
      max-width: 250px;
    }
  }
}


.section-header {
	@media only screen and (max-width: $screen-sm) {
		padding: 0 15px;
	}
}

.seo-content {
	@extend .gradient-callout-2;
	@extend .clearfix;
	padding: 30px 20px;

	h3 {
		font-size: 20px;
		text-transform: uppercase;
		font-family: $font-family-narrow;
	}

	.col {
		@extend .clearfix;
		&:first-child {
			border: none;
		}
	}

	.seo-copy {
		text-align: center;
		background: #fff;

		padding: 20px;

		p {
			text-align: left;
			min-height: 200px;
		}

		h3 {text-align: left;}
	}

	.seo-image {
		overflow: hidden;
		margin-bottom: 20px;

		img { max-width: 100%; }
	}

	@media only screen and (min-width: $screen-sm+1) {

		.seo-image { height: 200px; }

		// global.js adds the grid class based on number of li's in seo-content container

		.md-12 {
			max-width: 80%;
			margin: 0 auto;
			float: none;

			.seo-image {
				height: auto;
				max-width: 40%;
				padding-right: 20px;
				float: left;
			}

			.seo-copy {
				float: left;
				width: 100%;
			}

			&.image .seo-copy { width: 60%; }
		}

		.md-4 .seo-image { text-align: center; }
	}
}

.side-nav-arrow {
	content: ">";
	color: #999;
	display: block;
	position: absolute;
	left: -3px;
	top: -1px;
	font-size: 14px;
	font-weight: 700;
}

.side-nav {
	padding: 0;
	width: 100%;

	.section-content {
		background: #fff;
		padding: 0;

		@media only screen and (max-width: $screen-sm) {
			background: none;
			padding: 0;
			height: auto;
		}
	}

	.side-nav-results {
		position: relative;
		margin: 0;

		@media only screen and (max-width: $screen-sm) {
			margin: 0;
			width: 100%;
		}

		.heading {
			background: $brand-secondary;
			color: #fff;
			height: 55px;
			line-height: 55px;
			margin: 0 auto;
			font-size: 23px;
			text-align: center;
			width: 1280px;
			@media only screen and (max-width: $screen-sm) {
				width: 100%;
			}
		}

		.results {
			margin: 0 auto;
			min-height: 175px;
			padding: 55px 0 25px;
			width: 1030px;
			@extend .clearfix;
			@media only screen and (max-width: $screen-sm) {
				background: #ffffff;
				padding: 35px 0 0;
				width: 100%;
			}

			li {
				display: inline-block;
				position: relative;
				font-size: 14px;
				font-weight: 600;
				margin: 0 40px;
				min-height: 55px;
				vertical-align: top;
				width: 100%;
				max-width: 260px;
				padding-left: 10px;

				@media only screen and (max-width: $screen-sm) {
					margin: 0 25px;
					width: 100%;
					min-height: 25px;
				}

				&:after { @extend .side-nav-arrow; }
			}
		}
	}

	.side-nav-results-images {
		.results {
			width: 1100px;
			@media only screen and (max-width: $screen-sm) { width: 100%; }

			li {
				max-width: none;
				width: 320px;
				height: 115px;
				margin: 0 20px 10px;
				padding: 0;
				@media only screen and (max-width: $screen-sm) {
					height: auto;
					width: 100%;
					margin: 0 auto;
				}

				&:after {content: none;}

				a {
					display: block;
					height: 110px;
					overflow: hidden;
					@media only screen and (max-width: $screen-sm) { height: auto; }
					&:hover {text-decoration: none;}

					.img-nav {
						width: 100px;
						height: 100px;
						max-height: 100px;
						overflow: hidden;
						text-align: center;
						display: table-cell;
						@media only screen and (max-width: $screen-sm) { display: inline-block;	}
						vertical-align: middle;
						padding-bottom: 10px;

						img {
							height: auto;
							max-height: 85px;
							max-width: 90px;
							display: inline-block;
						}
					}

					.text-nav {
						display: table-cell;
						vertical-align: middle;
						position: relative;
						width: 215px;
						padding: 0 0 20px 10px;
						border-bottom: solid 1px $brand-primary-light;

						&.no-border {
							border-bottom: 0px;
						}

						@media only screen and (max-width: $screen-sm) {
							width: 63%;
							display: inline-block;

							&.no-border {
								border-bottom: solid 1px $brand-primary-light;
							}
						}

						span {
							display: inline-block;
							position: relative;
							padding-left: 10px;
							margin-left: 10px;
							&:before {
								@extend .side-nav-arrow;
								display: table-cell;
								text-decoration: none;
							}
							&.view-all-faqs:before {
								top: 2px;
							}

							&:hover {text-decoration: underline;}
						}
					}
				}
			}
		}
	}
}

.service-and-support .side-nav-results .results li strong {font-size: 16px;}


//Hero Images with Side Nav
.personal, .business {
	.hero.first {
			margin-bottom: 0.1em;
		}
	.secure-senior {
		.overlay-effect {
			text-align: center;

			img {
				position: relative;

				@media only screen and (max-width: $screen-sm) {
					position: relative;
					max-width: 180px;
				}
			}

			@media only screen and (max-width: $screen-sm) {
					text-align: center;
			}
		}
	}
}

// Door Knob Promotions - Edge Key Control
.edge-key-control {

	&.category {
		.site-nav-bkgd {
			background: #f5f5f5;
			opacity: 1;
		}
	}

	.hero {
		.section-title {
			font-size: 63px;
			margin-top: 18px;

			sup {
				font-size: 19px;
				top: -2.2em;
				left: 0.1em;
			}
			span {
				display: block;
				font-size: 33px;
				line-height: 43px;
				margin-top: 6px;
				width: 90%;
			}
		}
	}


	.side-content {

		h1 {
			border-bottom: 1px solid #ccc;
			line-height: 1.3;
			padding-bottom: 12px;
		}

		p {
			line-height: 1.7;
			margin: 18px 25px 18px 0;
		}
	}

	.radio-inline {
		padding: 10px 10px 0 0;
	}

	.promo-form {
		.row {

			@media only screen and (min-width: $screen-lg) {
				&:last-child {
					padding: 0 10px !important;
				}
			}
		}

		.split {
			.col {
				padding: 0 10px 0 0;

				@media only screen and (max-width: $screen-sm) {
					padding: 0 0 20px;
				}

				&:last-child {
					padding: 0 0 0 10px;

					@media only screen and (max-width: $screen-sm) {
						padding: 0;
					}
				}
			}
		}
	}
}

//Service and Support

.service-and-support {
	.hero.first {
		margin-bottom: 0.1em;
	}

	.popular-videos {
		padding-top: 30px;

		h3 { line-height: 300%; }
		.md-8 {
			@media only screen and (min-width: $screen-lg) {
				border-right: 1px solid #cfcfcf;
				padding-right: 2%;
			}
		}

		.video-categories {
			@media only screen and (min-width: $screen-lg) {
				margin: 0 0 0 30px;
			}
		}

	}

	.list-popular-videos {
		margin-top: 40px;

		li.col {
			img {
				width: 100%;
				max-width: 160px;
			}

			h4 {height: 40px;}
		}
		@media only screen and (max-width: $screen-xs) {
			margin-top: 20px;

			li.col {
				width: 100%;
				clear: both;

				img {max-width: none;}
			}
		}
	}

	.side-nav {
			margin: 2em auto 0;
	}
}
// Where to Buy

.where-to-buy {

	.page-title {
		border-bottom: 1px solid #CCC;
		padding: 5px 0;
	}

	.locations li {
		margin-bottom: 1em;
	}

	.list-inline-block {
		text-align: center;
		li {margin: 1em;}
	}

	.required {
		padding: 0 0 10px 0;
	}

	.smaller {
		&:first-of-type { 
			padding-top: 1em;
		}
	}

	.form-group {

		.checkbox { 
			display: inline-block; 
		}
	}

	.key {

		p {
			margin: 7px 0 0 0;
		}
	}

	.stores {
		text-align: center;
	}

	.office-locations {
		li {
			background: #f4f4f4;
			background: -moz-linear-gradient(top, #f4f4f4 0%, #efefef 100%);
			background: -webkit-linear-gradient(top, #f4f4f4 0%, #efefef 100%);
			background: linear-gradient(to bottom, #f4f4f4 0%, #efefef 100%);
			border: 1px solid rgba(#000,0.15);
			margin-bottom: 20px;
			min-height: 250px;
			padding: 20px;
			text-align: left;
			vertical-align: top;
			width: 250px;

			@media only screen and (max-width: $screen-xs) {
				border: none;
				margin: 0 0 20px;
				min-height: 100px;
				text-align: left;
				vertical-align: initial;
				width: 100%;
			}
		}
	}
}

// Video section
.videos {

	max-width: 800px;
	width: 100%;
	margin: 0 auto;

	.vid-container {
		position: relative;
		padding-bottom: 52%;
		padding-top: 30px;
		height: 0;
	}

	.vid-container iframe,
	.vid-container object,
	.vid-container embed {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}

	.vid-list-container {
		width: 92%;
		overflow: hidden;
		margin-top: 20px;
		margin-left:4%;
		padding-bottom: 20px;
	}

	.vid-list {
		width: 1344px;
		position: relative;
		top:0;
		left: 0;
	}
	.vid-item {
		display: block;
		width: 148px;
		height: 148px;
		float: left;
		margin: 0;
		padding: 10px;
		cursor: pointer;
	}

	.thumb {
		overflow:hidden;
		height: 84px;
	}

	.thumb img {
		width: 100%;
		position: relative;
		top: -13px;
	}

	.vid-item .desc {
		color: #21A1D2;
		font-size: 15px;
		margin-top:5px;
	}
	.arrows {
		position:relative;
		width: 100%;
	}

	.arrow-left {
		color: #fff;
		position: absolute;
		background: #777;
		padding: 15px;
		left: -25px;
		top: -130px;
		z-index: 99;
		cursor: pointer;
	}

	.arrow-right {
		color: #fff;
		position: absolute;
		background: #777;
		padding: 15px;
		right: -25px;
		top: -130px;
		z-index:100;
		cursor: pointer;
	}

	@media only screen and (max-width: $screen-sm) {
		.arrows {
			position:relative;
			margin: 0 auto;
			width:96px;
		}
		.arrow-left {
			left: 0;
			top: -20px;
		}

		.arrow-right {
			right: 0;
			top: -20px;
		}
	}
}

// Goverment

.general.government .section .row {

	.row.row-or {
		padding: 10px 0 15px 0;
		border: none;

		b {
			position: relative;
			left: 45%;
		}
	}

	.form-nsn .row.row-or b { left: 20%; }

	.form-govt-lock-dealer .btn-submit {
		margin: 13px 0 0px 0;
	}

	.form-govt-lock-dealer .row,
	.form-nsn .row {
		border: none;
		padding: 5px 0;

		@media only screen and (max-width: $screen-sm) {
			margin-left: 0;
			margin-right: 0;
		}
	}
}

.government {

	&.nsn {

		.page-title {
			margin: -3px 0 0px;
			padding: 0 5px 5px;
			@media only screen and (max-width: $screen-sm) {
				margin: 20px 0;
			}

			.smaller {font-size: 24px;}
		}

		ul.results {
			margin-top: 10px;
			border-top: 1px solid #cccccc;
		}

		.md-6.lock-result {
			display: inline-block;
			float: none;
			vertical-align: top;
			margin-bottom: 50px;
			border-top: 1px solid #cccccc;
			padding-top: 70px;

			&:nth-child(odd) {
				padding-right: 30px;
				@media only screen and (max-width: $screen-sm) {
					padding-right: 0;
				}
			}

			&:nth-child(even) {
				border-left: 1px solid #cccccc;
				padding-left: 30px;
				@media only screen and (max-width: $screen-sm) {
					border-left: none;
					padding-left: 0;
				}
			}

			&:nth-child(1), &:nth-child(2) {
				border-top: 0;
			}

			@media only screen and (max-width: $screen-sm) {
				border: 0;
				border-bottom: 1px solid #cccccc;
				margin-bottom: 10px;
			}

			.row {
				border-bottom: none;
			}
		}

		.md-9.lock-result {
			display: inline-block;
			float: none;
			vertical-align: top;
			margin-bottom: 50px;
			padding-top: 70px;
			.row {
				border-bottom: none;
			}
		}

		img {
			width: 100%;
			max-width: inherit;

			@media only screen and (max-width: $screen-sm) {
			  width: inherit;
			}
		}

		.nsn-search {
			padding: 10px 10px 0;
			border: 1px solid #cccccc;
			background: #eee;
			margin-top: -20px;
			position: absolute;
			right: 0;
			top: 0;

			.content-padding {padding: 15px 0;}
			.col {padding: 0;}
			.btn-submit {
				margin: -5px 0 0 5px;
				 @media only screen and (max-width: $screen-sm) {
					margin: 5px 0;
				}
			}
			h4 {padding: 0 0 5px 0; margin: 0;}
			p {padding: 0px 0 8px; margin: 0;}
			input[type="text"], input[type="password"], input[type="date"], input[type="datetime"], input[type="datetime-local"], input[type="month"], input[type="week"], input[type="email"], input[type="number"], input[type="search"], input[type="tel"], input[type="time"], input[type="url"], textarea, select { color: #000000;}
		}
		@media only screen and (max-width: $screen-sm) {
			.nsn-search {display: none;}
		}

		.legacy-nsn,
		.replacement-nsn {
			display: block;
			padding: 0 0 10px 0;
		}

		.bullet-copy {
			list-style: disc;
			padding: 10px 0 0 1.25em;
		}

		h3 {
			border-bottom: 1px solid #eeeeee;
			padding: 30px 0 5px;
		}

		@media only screen and (max-width: $screen-sm) {
			.not-phone {
				color: $color-base;
				text-decoration: none;
			}
		}

	}

	.form-nsn .btn-submit {
		@media only screen and (min-width: $screen-lg) {
			margin-top: 15px;
		}
	}
	.section .row .dealer-results .row {
		padding: 15px 0;
	}



	.specs-list {
		padding: 1em 0;
	}

	figure.image-zoom {
		margin: 0;
		padding: 0 10px 10px 0;
	}

	.government-theme-img {
		@media only screen and (max-width: $screen-sm) {
			width: 50%;
			min-width: 200px;
		}
	}
}

.featherlight-video {
	position: relative;
	display: block;
	height: auto;
	overflow: hidden;

	.fa-play {
		position: absolute;
		top: 50%;
		left: 50%;
		font-size: 36px;
		color: #fff;
		background-color: rgba(#000,0.5);
		width: 2.75em;
		height: 2.75em;
		line-height: 2.75em;
		text-align: center;
		border-radius: 50%;
		padding-left: 0.2em;
		margin: -1.375em 0 0 -1.375em;
		transition: background-color 150ms linear;
	}

	&:hover .fa-play {
		background-color: $brand-secondary;
	}
}

// Bluetooth Lock Box Landing Page
.bluetooth-landing {
	.hero {
		.section-header {
			@media only screen and (min-width: 768px) {
				max-width: 920px;
			}

			.section-title {
				@media only screen and (min-width: 768px) {
					font-size: 36px;
				}

				.small {
					display: block;
					font-size: 0.7em;
					margin-bottom: 0.325em;
				}
			}
		}
	}

	.section {
		padding: 0;
	}

	.callouts {
		@media only screen and (max-width: $screen-sm) {
			text-align: center;
		}

		h2 {
			background: #e1e1e1;
			text-align: center;
			max-width: 1280px;
			margin: 0 auto;
			padding: 14px 10px;
			text-transform: uppercase;
			font-weight: 700;

			@media only screen and (min-width: $screen-lg) {
				font-size: 28px;
			}
		}

		& > .col {
			& + .col {
				@media only screen and (min-width: $screen-lg) {
					border-left: 2px solid #ebebeb;
				}
			}
		}

		#product-6440ENT-image {
			border-left: none;

			p {
				font-weight: 700;
				font-size: 22px
			}
		}

		img {
			@media only screen and (min-width: $screen-lg) {
				float: left;
				margin-right: 13px;
			}

			&.wall-mount {
				@media only screen and (min-width: $screen-lg) {
					margin-top: 146px;
				}
			}
		}

		.image {
			position: relative;
			overflow: hidden;

			@media only screen and (min-width: $screen-lg) {
				float: left;
				margin-right: 13px;
				width: 272px;
				height: 547px;
			}

			img {
				@media only screen and (min-width: $screen-lg) {
					position: absolute;
					left: 50%;
					bottom: 0;
					max-width: none;
					transform: translateX(-50%);
				}
			}
		}

		.info {
			margin-top: 181px;

			@media only screen and (max-width: $screen-sm) {
				margin: 0 0 40px;
			}

			h3 {
				font-size: 32px;
				font-weight: 700;
				line-height: 32px;
				padding: 0 50px;
				text-transform: uppercase;

				span {
					display: block;
					font-size: 25px;
				}
			}

			h4 {
				font-size: 22px;
				font-weight: 700;
				text-transform: uppercase;
			}
		}

		.product-4400ent,
		.product-4401lhent {
			.image {
				@media only screen and (min-width: 768px) {
					height: 570px;
				}

				img {
					@media only screen and (min-width: 768px) {
						bottom: 70px;
						width: 150%;
					}
				}
			}
		}

		.product-5441d,
		.product-5441ent {
			.image {
				img {
					@media only screen and (min-width: 768px) {
						bottom: 20px;
					}
				}
			}
		}

		.product-6440ENT {
			.image {
				img {
					@media only screen and (min-width: 768px) {
						height: 80%;
						top: 1rem;
					}

					@media only screen and (max-width: $screen-sm) {
						height: 300px;
					}
				}
			}
		}

		.btn {
			background: #1f74b1;
			text-transform: uppercase;
			font-family: Oswald, sans-serif;
			font-size: 18px;
			padding: 8px 25px;
			border: 1px solid #000;
			letter-spacing: 0.05em;
			box-shadow: inset 0 0 20px rgba(#000,0.5);
			border-radius: 5px;
			color: #fff;

			&:hover {
				background: darken(#1f74b1,10%);
			}
		}
	}

	.feature-icons {
		background: #ebebeb;
		padding: 25px 0;
		text-align: center;

		img {
			width: 40%;
		}

		h3 {
			font-size: 20px;
			font-weight: 700;
			letter-spacing: -1px;
			margin-bottom: 12px;
			text-transform: uppercase;
		}

		p {
			padding: 0 25px;
		}
	}

	.thumbnails {
		@media only screen and (min-width: 768px) {
			display: flex;
			max-width: 1204px;
			margin: 0 auto;
		}

		.col {
			padding: 2px 0;

			@media only screen and (min-width: 768px) {
				padding: 4px 2px;
			}

			&:first-child {
				padding-top: 4px;
			}

			&:last-child {
				padding-bottom: 4px;
			}
		}

		img {
			display: block;
			width: 100%;
		}
	}

	.access {
		background: #e1e1e1;
		padding: 30px 50px 25px;

		h4 {
			font-size: 28px;
			font-weight: 700;
			letter-spacing: -1px;
			margin: 0 0 8px;
			text-transform: uppercase;
		}

		ul {
			margin: 0;
			padding: 0;

			li {
				display: inline-block;
				font-size: 21px;
				font-weight: 600;
				letter-spacing: -1px;
				list-style: none;
				margin: 0 3px 0 0;
				text-transform: uppercase;

				&:after {
					margin-left: 7px;
					content: '|';
				}

				&.last {
					&:after {
					   display: none;
					}
				}
			}
		}

		@media only screen and (max-width: $screen-sm) {
			padding: 10px;
		}
	}

	.features,
	.simplicity {
		padding: 30px 50px 25px;

		h4 {
			font-size: 28px;
			font-weight: 700;
			letter-spacing: -1px;
			margin: 0 0 15px;
			text-transform: uppercase;
		}

		h5 {
			font-size: 21px;
			font-weight: 700;
			letter-spacing: -1px;
			margin: 0 0 20px;
			text-transform: uppercase;

			@media only screen and (max-width: $screen-sm) {
				margin: 0 0 10px;
			}
		}

		.icons {
			.elockapp,
			.enterprise-app-icon {
				float: left;
			}

			.enterprise-app-icon {
				margin-right: 10px;

				@media only screen and (min-width: $screen-lg) {
					width: 20%;
				}
			}

			img {
				width: 25%;

				@media only screen and (max-width: $screen-sm) {
					width: 46%;
				}
			}
		}

		ul {
			clear: both;
			margin: -10px 0 25px;

			@media only screen and (max-width: $screen-sm) {
				margin: 0 0 25px;
			}
		}

		@media only screen and (max-width: $screen-sm) {
			padding: 22px;
		}
	}

	.open-features {
		margin-top: 20px;
		text-align: center;

		@media only screen and (min-width: 768px) {
			margin-top: 30px;
		}

		.row {
			margin: 0;
			padding: 0 !important;
		}

		h4 {
			font-size: 22px;
			margin: 18px 0 9px;
		}

		p {
			font-size: 17px;
			padding: 0 35px;
		}
	}

	.flexible {
		padding: 30px;

		@media only screen and (min-width: 768px) {
			padding: 30px 50px;
		}

		h3 {
			font-size: 28px;
			font-weight: 700;
			letter-spacing: -1px;
			margin: 0 0 15px;
			text-transform: uppercase;
		}

		.md-4 {
			margin-top: 2.5rem;

			& + .md-4 {
				@media only screen and (min-width: 768px) {
					border-left: 1px solid #00a6c9;
				}
			}

			h4 {
				text-transform: uppercase;
				color: #00a6c9;
				font-size: 1.125rem;
				font-weight: 600;
			}

			img {
				display: block;
				width: 210px;
				margin: 1.25rem auto 2.188rem;
			}

			p {
				font-size: 0.8125rem;
				margin: 0 auto;

				@media only screen and (min-width: 768px) {
					width: 65%;
				}
			}
		}
	}
}

.bluetooth-lockbox {
	.hero .section-header {
		padding: 20px;
		@media only screen and (min-width: 768px) {
			padding: 345px 0 0 100px;
			max-width: 980px;
		}
		.section-title {
		    font-size: 30px;
		    margin: 0 0 10px;
		    @media only screen and (min-width: 768px) {
				text-shadow: 4px 6px 9px rgba(0,0,0,0.7);
			}
		}
		h2.sub {
		    font-size: 35px;
		    text-transform: uppercase;
		    font-weight: 500;
    		font-family: Oswald, sans-serif;
    		color: #000000;
    		margin: 0 0 10px;

    		@media only screen and (min-width: 768px) {
				color: #ffffff;
				font-size: 37px;
				text-shadow: 4px 6px 9px rgba(0,0,0,0.7);
			}
			.block {
				display: inline;
				@media only screen and (min-width: 768px) {
					display: block;
				}
			}
		}
	}
}


/// Bluetooth Support Portal
/// App Pages
.bluetooth-support {

	&.lock-box {
		.product-specs {
			img {
				float: left;
				margin: 25px 15px -25px 5px;
				width: 100px;
			}

			.wall {
				img {
					margin-top: 62px;
				}
			}
		}
	}

	&.select {
		.section-content {
			min-height: 700px;

			.page-title {
				font-weight: 600;
				margin: 50px 0;

				@media only screen and (max-width: $screen-sm) {
					margin: 20px 0;
				}
			}

			h2 {
				font-size: 29px;
			}

			&.select {
				.col {
					&.lock {
						margin-bottom: 50px;

						@media only screen and (max-width: $screen-sm) {
							margin-bottom: 0;
						}
					}

					&.first {
						border-right: 1px solid #cecece;

						@media only screen and (max-width: $screen-sm) {
							border: none;
						}
					}
				}

				img {
					margin: 25px 0;

					@media only screen and (max-width: $screen-sm) {
						margin: 20px 0 50px;
					}
				}
			}
		}
	}

	#container {
		background: #fff;
	}

	#mobile-nav {
		height: auto;
	}

	&.search {
		.section-content {
			padding-bottom: 25px;

			.col-breadcrumb {
				margin-top: 30px;

				ol {
					margin-left: 0;
				}

				@media only screen and (max-width: 767px) {
					display: none;
				}
			}

			.page-title {
				margin: 10px 0 30px;

				 @media only screen and (max-width: 767px) {
					 margin: 10px 0 0;
				 }
			}

			.results {
				margin-top: 40px;
				margin-bottom: 40px;

				.empty {
					display: block;
					height: 1px;
				}

				ul {
					list-style: none;
					padding: 0;
				}

				.support {
					border-bottom: 1px solid #cfcfcf;
					margin-bottom: 20px;
					padding-bottom: 10px;
				}

				.faq-section {
					border-right: none;

					h1 {
						border-bottom: solid 3px #ab0000;
						padding-bottom: 10px;
					}

					h2 {
						font-size: 28px;
					}

					.faq-answer {
						ul {
							list-style: initial !important;
							padding: 0 0 0 1.25em !important;
						}
					}
				}
			}
		}
	}

	&.topics {
		.section-content {
			padding-bottom: 25px;

			.col-breadcrumb {
				margin-top: 30px;

				ol {
					margin-left: 0;
				}

				@media only screen and (max-width: 767px) {
					display: none;
				}
			}

			.page-title {
				margin: 10px 0 30px;

				 @media only screen and (max-width: 767px) {
					 margin: 10px 0 0;
				 }
			}


			h2 {
				color: #bf0000;
				font-size: 28px;
				margin: 0 0 8px;
			}

			h3 {
				font-size: 20px;
				font-weight: 600;
				margin: 0 0 5px;
			}

			ul, ol {
				margin-left: 17px;

				li {
					padding: 1px 0;
				}
			}

			.result {
				border-bottom: 1px solid #cfcfcf;
				margin-bottom: 28px;
				padding-bottom: 20px;

				&:last-child {
					border-bottom: none;
					margin-bottom: 0;
				}
			}

			.app-icon {
				margin-top: 7px;
			}

			.gear-icon, .delete-icon {
				width: 2.7%;
			}

			.relock-icon {
				width: 12%;
			}

			.important {
				font-weight: 600;
				text-transform: uppercase;
			}

			.note {
				font-size: 13px;
				font-style: italic;
			}

			.alpha {
				list-style-type: lower-alpha;
				margin: 5px 0 0 5px;
			}

			.ovals {
				margin: 5px 0 0 5px;
			}

			.icon-4400 {
				margin-top: 35px;

				&.unlock {
					margin-top: 35px;
				}
			}

			.icon-4401 {
				margin-top: 45px;

				&.unlock {
					margin-top: 25px;
				}
			}

			.download {
				margin-top: 15px;
			}
		}
	}

	.bluetooth-smart {
		margin-left: 15px;
	}

	#site-nav {
		left: 51%;
		width: 580px;

		& > .nav {
			width: 100%;
		}
	}

	.site-nav-bkgd {
		width: 600px;
		z-index: -1;
	}

	.page-title {
		margin: 0 0 15px;
	}

	h2 {
		font-size: 24px;
		font-weight: 600;
		margin: 0 0 15px;
	}

	.question {
		clear: both;
		border-bottom: 1px solid #cfcfcf;
		margin: 20px 0 45px;
		padding: 30px 0 10px;

		.col {
			float: none;
			margin: 0 auto;

			h3 {
				background: #eee;
				margin: 0;
				padding: 15px 20px 0;
			}
		}

		.faq-search-bar {
			background: #eee;
			padding: 5px 20px 20px;

			@media only screen and (max-width: 767px) {
				margin-bottom: 15px;
			}
		}

		@media only screen and (max-width: 767px) {
			margin: 0 0 10px;
			padding: 20px 0 0;
		}
	}

	.support-topics {
		ul {
			list-style: none;
			padding: 0;

			li {
				font-size: 15px;
				margin: 1em 0;

				.elockapp {
					float: left;
					margin-right: 11px;
					width: 34%;

					@media only screen and (max-width: 767px) {
						float: none;
						width: 100%;
					}
				}

				@media only screen and (max-width: 767px) {
					font-size: 21px;
				}
			}
		}
		img {
			margin: 5px 7px 0 0;
			width: 54%;

			@media only screen and (max-width: 767px) {
				display: block;
				margin: 15px 0;
				width: 100%;
				max-width: 150px;
			}
		}
	}

	.faq-section {
		border-left: 1px solid #ccc;
		border-right: 1px solid #ccc;
		min-height: 575px;
		padding: 0 30px;

		.zebra-accordion {
			padding-bottom: 0;
		}

		img {
			margin: 10px 7px 0 0;
			max-width: 120px;

			@media only screen and (max-width: 767px) {
				width: 100%;
			}
		}

		.more-faqs {
			margin-top: 15px;
		}

		@media only screen and (max-width: 767px) {
			border-left: none;
			border-right: none;
			border-bottom: 1px solid #cfcfcf;
			padding: 0;
		}
	}

	.product-specs {
		padding-left: 30px;

		@media only screen and (max-width: 767px) {
			clear: both;
			float: none;
			padding: 20px 0 0;
			text-align: center;
		}

		ul {
			list-style: none;
			padding: 0;

			li {
				clear: both;
				font-size: 18px;
			}
		}

		img {
			float: left;
			margin: 25px 30px 25px 5px;
			width: 80px;
		}

		.description {
			float: left;
			text-align: center;

			&.prod-1 {
				margin-top: 45px;
			}

			&.prod-2 {
				margin-top: 80px;
			}

			.btn {
				display: block;
				margin-top: 8px;
				min-width: 135px;
			}
		}
	}

	.faq-contact {
		border-top: 1px solid #cfcfcf;
		margin: 40px auto 0;

		.faq {
			background: #eee;
			clear: both;
			margin: 40px 0 40px;
			padding: 30px;
		}

		.col {
			float: none;
			margin: 0 auto;
		}

		h2 {
			font-size: 25px;
			font-weight: normal;
			margin-bottom: 20px;

			span {
				color: #bf0000;
				font-size: 24px;
				font-weight: 600;
				text-transform: uppercase;
			}

			 @media only screen and (max-width: 767px) {
				margin-bottom: 0;
			 }
		}

		@media only screen and (max-width: 767px) {
			margin: 12px 0 40px;
		}
	}

	.section-content {

		.contact-section {
			float: none;
			margin: 80px auto 0;

			.page-title {
				border-bottom: 1px solid #cfcfcf;
				margin: 0 0 20px;
				padding-bottom: 15px;

				@media only screen and (max-width: 767px) {
					border-bottom: none;
					padding-bottom: 0;
					text-align: center;
				}
			}

			@media only screen and (max-width: 767px) {
				margin: 10px auto;
			}
		}
	}

	#contact-us-form {

		input, textarea, select {
			&.error {
				background: #fee;
				border: 1px solid #c00 !important;
				margin-bottom: 2px;
			}
		}
	}

	.submit-button {
		width: 100%;

		@media only screen and (max-width: 767px) {
			width: 100%;
		}
	}
}

#cookie-notification {
	position: fixed;
	bottom: -200px;
	left: 0;
    z-index: 999;
    display: block;
    width: 100%;
    background-color: #fff;
    padding: 20px 0;
    color: #000;
	font-size: 12px;
	transition: bottom .5s linear;
	-webkit-box-shadow: 0px -5px 10px 0px rgba(0,0,0,0.38);
	-moz-box-shadow: 0px -5px 10px 0px rgba(0,0,0,0.38);
	box-shadow: 0px -5px 10px 0px rgba(0,0,0,0.38);
	@media only screen and (min-width: 767px) {
		bottom: -100px;
	}
}

#cookie-notification .row {
    min-height: 30px;
}

#cookie-notification p {
	margin: 0 25px 20px;

	@media only screen and (min-width: 767px) {
		margin: 0 200px 0 50px;
	}
}

#cookie-notification a {
	color: inherit;

	&:hover {
		opacity: 1!important;
	}
}

#cookie-notification a#privacy {
	text-decoration: underline;
}

#cookie-notification a:hover {
    opacity: 0.8;
}

#cookie-notification-close {
	display: block;
	position: relative;
    text-align: center;
    text-decoration: none;
	font-weight: 700;
	padding: 10px 20px;
	font-size: 12px;
	background-color: #bf0000;
	color: #fff!important;
	width: calc(100% - 40px);
	margin: 0 auto;
	text-transform: uppercase;
	letter-spacing: 1px;

	@media only screen and (min-width: 767px) {
		display: block;
		position: absolute;
		top: 5px;
		right: 0;
		margin: 0;
		width: auto;
	}

	&:hover {
		background-color: #9a0303;
		opacity: 1!important;
	}
}

/// App Pages
.app-page {
	background-color: #fff;
}

/// Customer Support Page
.customer-support-page {
	padding-top: 2.5em;

	h2 {
		margin: 35px 0 3px;
	}

	.vcard {
		padding-left: 3px;
	}

	span {
		.type {
			font-weight: 600;
		}
	}
}

/// Privacy Policy
.privacy-policy {

	.page-title {
		margin-bottom: 40px;
	}

	h2 {
		font-weight: 600;
		margin: 30px 0 20px;
	}

	ol {
		padding: 0 30px;
	}

	.label-section {
		border-bottom: 1px solid rgba(#000,0.15);
		cursor: pointer;
		padding: 23px 0 9px;
		scroll-margin-top: 70px;

		.icon {
			float: left;
			margin-right: 12px;
			width: 27px;
		}

		.content {
			display: block;

			p {
				font-size: 15px;
				line-height: 24px;
				padding-left: 40px;
			}

			ul {
				margin-left: 60px;
			}
		}

		&:first-of-type {
			border-top: 1px solid rgba(#000,0.15);
		}

		&:last-of-type {
			margin-bottom: 50px;
		}

		.address {
			padding: 0 40px 20px;
		}
	}
}

/// Engraved Lock Boxes Page
.engraved-lock-boxes {
	.hero {
		.section-header {
			max-width: 600px;
			padding: 150px 0 0 55px;

			.section-title {
				font: 54px/1.2 Oswald, sans-serif;
			}

			@media only screen and (max-width: 767px) {
				padding: 15px;
			}

			.arrow-right {
				display: inline-block;
				margin: 18px 0 0 5px;
				vertical-align: top;
				width: 0;
				height: 0;
				border-top: 15px solid transparent;
				border-bottom: 15px solid transparent;
				border-left: 15px solid #fff;
			}
		}
	}
	.callout {
		img {
			@media only screen and (max-width: 767px) {
				max-width: none;
			}
		}
	}

	.callout-info {

		.col {
			margin: 20px 0 10px;

			h4 {
				color: #bd1829;
				font: 30px/1.3 'Oswald', sans-serif;
				padding-top: 0;
				text-transform: uppercase;
			}

			ul {
				float: right;

				li {
					color: #6d6e71;
					font-size: 22px;
					margin: 0 0 .20em;
					text-transform: capitalize;
				}

				@media only screen and (max-width: 767px) {
					float: none;
					padding: 0 25px 25px;
				}
			}
		}

		@media only screen and (max-width: 767px) {
			padding: 0 15px;
		}
	}

	.accordion-products-trigger {
		padding: 0 15px;
		text-align: left;
	}

	.personalize {
		background: #e7e7e7;
		color: #231f20;
		max-width: 1280px;

		h4 {
			color: #bd1829;
			font-family: Oswald, sans-serif;
			font-size: 44px;
			margin: 20px 0 50px;
			text-align: center;
			text-transform: uppercase;

			@media only screen and (max-width: 767px) {
				margin: 20px 0;
			}
		}

		ol {
			padding-bottom: 10px;
			text-align: center;

			li {
				display: inline-block;
				font-family: 'Oswald', sans-serif;
				font-size: 26px;
				font-weight: 300;
				padding: 0 20px;
				text-transform: uppercase;

				.circle {
					background: #bd1829;
					border-radius: 30px;
					color: #fff;
					display: inline-block;
					height: 40px;
					margin-right: 5px;
					padding-top: 2px;
					text-align: center;
					width: 40px;
				}

				@media only screen and (max-width: 767px) {
					display: block;
					padding: 0 0 10px 0;
				}
			}

			@media only screen and (max-width: 767px) {
				padding-bottom: 0;
				text-align: left;
			}
		}
	}

	.product-features, .models {

		.page-title {
			font-weight: 600;
			margin-bottom: 5px;
		}

		.section-content-title-lg {
			font-size: 33px;
		}

		&.border-right {
			border-right: 3px solid #000;

			@media only screen and (max-width: 767px) {
				border-right: none;
				border-bottom: 3px solid #000;
			}
		}

		&.second {
			padding-left: 25px;

			@media only screen and (max-width: 767px) {
				padding: 0;
			}
		}
	}

	.create-link {
		color: #fff;
		font-size: 18px;
		font-weight: 600;
		margin: -99px 0 0 165px;
		position: absolute;
		text-transform: uppercase;

		.create {
			background: #707171;
			border: 10px solid #fff;
			line-height: 21px;
			margin: 0 auto;
			padding: 14px 0 14px 25px;
			text-align: left;
			width: 200px;

			.arrow-right {
				display: inline-block;
				margin: -11px 0 0 9px;
				vertical-align: top;
				width: 0;
				height: 0;
				border-top: 10px solid transparent;
				border-bottom: 10px solid transparent;
				border-left: 10px solid #fff;

				@media only screen and (max-width: 767px) {
					margin: 0 0 0 15px;
				}
			}

			@media only screen and (max-width: 767px) {
				border: none;
				width: 100%;
			}
		}

		&:hover {
			text-decoration: none;

			.create {
				background: #90121f;
				border: 10px solid #707171;

				@media only screen and (max-width: 767px) {
					background: #707171;
					border: none;
				}
			}
		}

		@media only screen and (max-width: 767px) {
			border: none;
			display: block;
			margin: 0 0 35px;
			position: relative;
			width: 100%;
		}
	}

	.models {
		display: inline-block;
		text-align: center;

		img {
			padding: 0 10px 25px;
			max-width: 500px;

			@media only screen and (max-width: 767px) {
				max-width: 100%;
			}
		}

		.product-info {
			text-align: left;
			min-height: inherit;

			.product-description {
				min-height: 150px;
			}
		}

		.product-actions {
			.small-text {
				font-size: 11px;
				font-weight: 600;
				margin-left: 26px;
				padding-top: 12px;
				text-align: left;
				width: 210px;

				@media only screen and (max-width: 767px) {
					text-align: left;
					width: 100%;
				}
			}
		}
	}

	.product-warranty {
		margin-left: 15px;
		text-align: left;

		.small-text {
			clear: both;
			display: block;
			font-size: 11px;
			font-weight: 600;
			line-height: 1.2;
			margin-top: 28px;
			margin-left: 0 !important;
			text-align: left;
			width: 210px;
		}

		.title {
			font-size: 20px;
			font-weight: 700;
			margin: 31px 0 6px;
		}

		a {
			float: left;
			font-size: 14px;
		}
	}

	.tab-content {
		.section-content {
			padding-bottom: 3em;

			@media only screen and (max-width: 767px) {
				padding-bottom: 0;
			}
		}
	}
}

/// Engraved Lock Boxes FAQs
.engraved-lock-boxes {
	&.faq {
		.results {
			margin-top: 40px;
			margin-bottom: 40px;

			.empty {
				display: block;
				height: 1px;
			}

			.faq-img {
				padding: 0 10px;
				vertical-align: middle;
			}
		}
	}

	#content {
		margin-bottom: 0;
	}
}

/// Engraved Lock Boxes Modal
.product-detail {
	#customize-button {
		text-transform: uppercase;

		&.get-started {
			height: 47px;
			padding: 0 2.25em;
		}

		@media only screen and (max-width: 767px) {
			margin-bottom: 8px;
			width: 100%;
		}
	}

	.shipping-info {
		&.custom {
			color: #bf0000;
			font-size: 29px !important;
			margin: 0 0 10px !important;
			text-transform: uppercase;
			width: 300px;
		}
	}

	.personalizationWindow {
		background-color: #fff;
		border: 1px solid rgba(#000,0.28);
		border-radius: 8px;
		box-shadow: 0 0 30px 0 rgba(#000,0.2);
		cursor: default;
		display: none;
		margin: 0 auto;
		padding: 25px 0 20px;
		position: absolute;
		right: 0;
		left: 0;
		height: auto;
		min-height: 500px;
		max-height: none;
		width: 100%;
		max-width: 1100px;
		z-index: 999;

		iframe {
			display: none;

			@media only screen and (max-width: 600px) {
				width: 100%;
			}
		}

		.fa-times {
			color: #bd1829;
			font-size: 25px;
			margin: -15px 13px 10px 0;

			&:hover {
				color: #000;
				cursor: pointer;
			}
		}

		@media only screen and (max-width: 600px) {
			width: 100%;
		}
	}

	.close-btn {
		float: right;
	}

	.personalizationTerms {

		.terms-content {
			border: 1px solid rgba(#000,0.2);
			height: 400px;
			overflow-y: scroll;
			margin: 21px 0 12px;
			padding: 10px 20px;
			width: 100%;

			h4 {
				font-size: 18px;
				font-weight: 700;
				margin-top: 4px;
			}

			.term-title {
				font-style: italic;
				font-weight: 700;
				margin-right: 4px;
				text-decoration: underline;
				text-transform: none;
			}

			.upper {
				text-transform: uppercase;
			}
		}

		.term-buttons {
			margin: 6px 0 0;
			text-align: center;

			button {
				margin: 0 6px;
			}
		}
	}
}


/// Airbnb Promotions
.airbnb-promotions {
	.flag {
		display: inline-block;
		margin: 10px;
		max-width: 80px;

		img {
			width: 100%;
		}

		@media only screen and (max-width: 767px) {
			max-width: 28%;
		}
	}

	@media only screen and (max-width: 767px) {
		margin: 0;
	}
}

.extra-btns {
	margin: 10px;

	.international-customer {
		margin-bottom: 6px;
	}

	.coming-soon {
		margin-bottom: 6px;
		width: 190px;
	}
}

/// Show PDF Size In Footer
.pdf-size {
	&:after {
		content: ' (PDF - 53KB)' !important;
	}
}

/// Video Library
.video-library {
	.list-video-library {
		.col-video-library {
			max-height: 295px;
		}
	}
}

/// Support Links
.support-links {
	.section {
		&.first {
			padding-top: 3.5em !important;
		}

		.callout {
			height: 100%;
			min-height: 150px;

			h2 {
				background-color: #bd1829;
				color: #fff;
				font-size: 18px;
				height: 2.2em;
				margin: 0;
				padding: 0.5em 0 0 0;
				text-align: center;
				width: 100%;
			}

			.box-content {
				float: none;
				padding: 20px 20px 0;
				text-align: center;
				min-height: 90px;
				width: 100%;

				a {
					color: #000;
					display: table-cell;
					font-family: Oswald, sans-serif;
					font-size: 17px;
					line-height: 0.1;
					margin-bottom: 15px;
					vertical-align: middle;

					.svg-icon {
						width: auto;
						height: auto;
						margin: 0;
						min-height: 50px;
						vertical-align: middle;
					}

					.fa {
						font-size: 44px;
						margin: 0 15px 0 0;
						position: relative;
						vertical-align: middle;
						min-height: 50px;
					}

					.find-rep {
						display: inline-block;
						height: 38px;
						margin-right: 10px;
						position: relative;
						vertical-align: middle;
					}
				}
			}
		}
	}
}

/// American Lock Landing Page

.american-lock {

	#site-title {
		background-position: 0 -790px;
		height: 70px;
		margin-top: -14px;

		@media only screen and (max-width: 767px) {
			background-position: 0 -413px;
			margin-top: 0;
			width: 134px;
		}
	}

	.section {
		margin-top: 0;

		a {
			color: #e5173f;
		}

		.app-bar {
			margin: 112px 50px;
			position: absolute;

			@media only screen and (max-width: 767px) {
				background: #0061a5;
				margin: 0;
				padding: 10px 0 5px;
				position: relative;
				text-align: center;
			}
		}

		.hero {
			.section-title {
				color: #e5173f;
				text-shadow: 5px 4px 6px rgba(#000, 0.5);

				@media only screen and (max-width: 767px) {
					color: #000;
					text-shadow: none;
				}
			}

			@media only screen and (max-width: 767px) {
				margin: 0;
			}
		}

		.page-title {
			color: #e5173f;
			font-size: 35px;
			margin-bottom: 20px;
			text-transform: uppercase;
		}

		.promo {
			.image-title {
				display: block;
				font-weight: 700;
				margin-bottom: 3px;
				text-align: center;
			}
		}

		.section-header {
			@media only screen and (max-width: 767px) {
				padding: 15px 15px 0;
			}
		}

		.section {
			.row {
				@media only screen and (max-width: 767px) {
					padding: 0;
				}
			}

			@media only screen and (max-width: 767px) {
				padding: 0;
			}
		}

		.copy {
			font-size: 16px;
			line-height: 22px;
		}

		.multi-user-locks {
			.block {
				&.title {
					color: #e5173f;
					font-weight: 700;
				}
			}

			.lock {
				&.header {
					background: #e5173f;

					h4 {
						color: #fff;
						font-family: Oswald, sans-serif;
						font-size: 26px;
						font-weight: normal;
						line-height: 22px;
						margin: 0;
						padding: 10px 5px;
						text-align: center;
						text-transform: uppercase;
					}
				}

				img {
					margin-bottom: 65px;
				}

				.list {
					margin: 12px 0 12px 91px;

					li {
						&:after {
							display: none;
						}
					}
				}

				.see-all {
					display: block;
					font-size: 16px;
					font-weight: 700;
					margin-bottom: 5px;
				}
			}

			@media only screen and (max-width: 767px) {
				margin: 0;
				padding: 0;
			}
		}

		.panel-primary {
			background-color: #0061a5;
			background-image: linear-gradient(to bottom, #0061a5 0%, #004379 100%);

			a {
				color: #fff;
				text-decoration: underline;

				&:hover {
					text-decoration: none;
				}
			}
		}

		#join-us {
			background: #f2f2f2;
			border-top: 1px solid #e5173f;
			box-shadow: 2px 2px 9px rgba(#000, 0.2);
			display: none;
			padding: 25px;

			.social {
				margin-bottom: 10px;

				a {
					background-color: #555;
					background-image: linear-gradient(to bottom, #555 0%, #333 100%);
					border-radius: 3px;
					color: #fff;
					display: block;
					line-height: 17px;
					min-height: 65px;
					padding: 13px 15px 15px;

					.fa {
						background: #fff;
						border-radius: 10px;
						float: left;
						font-size: 40px;
						margin-right: 12px;

						&.fa-linkedin-square {
							color: #007bb6;
							line-height: 32px;
							margin-top: 3px;
							width: 33px;
						}

						&.fa-facebook-square {
							color: #3b5998;
							line-height: 32px;
							margin-top: 2px;
							width: 33px;
						}

						&.fa-twitter {
							background: none;
							color: #00aced;
						}

						&.fa-envelope {
							background: none;
						}
					}

					&:hover {
						background-color: #333;
						background-image: linear-gradient(to bottom, #333 0%, #555 100%);
						text-decoration: none;
					}
				}

				&.mail {
					span {
						display: inline-block;
						padding-top: 11px;
					}
				}
			}
		}

		#event-listing {
			h2 {
				margin-bottom: 6px;
			}

			.event-image {
				margin-top: 34px;
			}

			.description {
				padding: 0 22px;
			}
		}

		@media only screen and (max-width: 767px) {
			min-height: none;
		}
	}
}


/// Personal Use
.personal {
	.callout {
		&.laser {
			img {
				left: 33px;
				top: 33px;
			}
		}

		&.light-up {
			img {
				left: 30px;
				top: 5px;
			}
		}
	}
}

/// Category Slides
.category {
	.slide-caption {
		width: 50%;

		.section-title {
			font: 72px/1.1 $font-family-narrow;
			text-transform: uppercase;
		}

		p {
			font-size: 18px;
			width: 78%;
		}
	}
}

/// Eloqua Inquiry Form
.inquiry {
	.main-header {
		margin-left: 11%;

		@media only screen and (max-width: 767px) {
			margin: 0;
		}
	}

	.main-content {
		margin-left: 15%;

		@media only screen and (max-width: 767px) {
			margin: 0;
		}
	}

	.section-content {
		.block {
			background-color: #cc0000;
			background-image: linear-gradient(to bottom, #c00 0%, #900 100%);
			height: 42px;
			display: block;
			width: 100%;
		}

		p:first-child {
			font-size: 17px;
			font-weight: 600;
			line-height: 26px;
			padding: 13px 4px 0;
		}

		.promo-form {
			background: #f5f5f5;
			border-top: 2px solid #bf0000;
			padding: 25px 10px 25px 30px;

			.radio-inline {
				padding: 8px 4px 0;
			}

			.privacy {
				padding: 20px;
			}

			@media only screen and (max-width: 767px) {
				padding: 20px;
			}
		}
	}
}


/// Business Use Callouts
.md-3 {
	.callout {
		&.new-products {
			img {
				margin: 30px 0 0 25px;
				max-width: 30%;
			}
		}

		&.access-software {
			img {
				margin: 25px 0 0 10px;
				max-width: 50%;
				@media only screen and (max-width: 767px) {
					max-width: 40%;
				}
			}
			.box-content {
				width: 43%;
			}
		}

		&.electronic-products {
			img {
				margin: 20px 0 0 10px;
                max-width: 45%;
			}
			.box-content {
				width: 45%;
			}
		}

		&.custom-key {
		   img {
				margin: 35px 0 0 8px;
				max-width: 47%;

			   @media only screen and (max-width: 767px) {
					margin: 0 0 0 8px;
				}
			}
		}

		&.expert-safety {
			img {
				margin: 0 0 0 -10px;
				max-width: 58%;
			}
			.overlay-effect span {
			    bottom: 5px;
			    line-height: 19px;
			    @media only screen and (max-width: 767px) {
			    	bottom: 0;
				}
			}
		}

		&.commitment {
			img {
				margin: 25px 0 0 10px;
				max-width: 45%;

				@media only screen and (max-width: 767px) {
					margin: 0 0 0 10px;
					max-width: 39%;
				}
			}
		}
	}
}
.md-4 {
	.callout {
		&.puck {
			img {
				margin: 61px 0 0 5px;
				max-width: 50%;

				@media only screen and (max-width: 767px) {
					margin: 20px 0 0;
				}
			}
		}
	}
}

// Construction
.construction {
	.hero {
		.section-header {
			max-width: 650px;
		}
	}

	.magnum {
		img {
			left: 42px;
			top: 4px;
			max-width: 41%;

			@media only screen and (max-width: 767px) {
				left: 20px;
			}
		}
	}

	.checklist {
		img {
			left: 75px;
			top: 18px;
			max-width: 30%;

			@media only screen and (max-width: 767px) {
				left: 40px;
			}
		}
	}

	.btn-feature {
		border-radius: 20px;
		box-shadow: 2px 3px 10px rgba(#000,0.4);
		font-size: 16px;
		margin-top: 15px;
		padding: 0.75em 4em;
	}

	.commercial-features {
		margin-top: 15px;

		@media only screen and (max-width: 767px) {
			margin-top: 0;
		}
	}

	.callouts-secondary {
		.callout {
			img {
				left: 5px;
				top: 30px;
				max-width: 48%;

				@media only screen and (max-width: 767px) {
					left: 11px;
					top: 0px;
					max-width: 41%;
				}
			}

			&.construction-site {
				img {
					top: 40px;

					@media only screen and (max-width: 767px) {
						left: 11px;
						top: 11px;
					}
				}
			}
		}
	}

	.magnum-content {
		padding: 20px 20px 0 70px;

		h2 {
			color: #bd1829;
			font-size: 26px;
			font-weight: 700;
			margin-bottom: 15px !important;
			text-transform: uppercase;
		}

		p {
			font-size: 18px;
			line-height: 28px;
			margin: 0 0 20px;
		}

		@media only screen and (max-width: 767px) {
			padding: 20px 10px;
		}
	}
}

// Vault Update
.vault-update {
	.hero {
		margin-bottom: -25px !important;
	}

	.top-section {
		background: #511b1d;
		color: #fff;
		padding: 4.5% 7%;

		h1 {
			color: #e9333f;
			font-size: 35px;
			margin-bottom: 25px;
			text-align: center;
			text-transform: uppercase;
		}

		p {
			font-size: 16px;
			line-height: 1.5em;
		}
	}

	.info {
		padding: 4% 3%;

		.title {
			font-size: 26px;
			font-weight: 700;
			text-transform: uppercase;
		}

		p {
			font-size: 16px;
			line-height: 1.5em;
		}

		.export {
			margin-right: 5px;
			vertical-align: middle;
			max-width: 160px;
		}

		@media only screen and (max-width: $screen-sm) {
			padding: 6%;
		}
	}

	.faqs {
		.section-content {
			padding: 2.5em 0 0 !important;

			.col {
				border-bottom: 1px solid #ccc;
				margin-bottom: 30px;
				padding-bottom: 15px;

				.question {
					font-size: 19px;
					font-weight: 600;
				}

				.answer {
					line-height: 1.5em;
					margin-left: 10px;

					@media only screen and (max-width: $screen-sm) {
						margin-left: 0;
					}
				}
			}

			@media only screen and (max-width: $screen-sm) {
				padding: 6% !important;
			}
		}
	}
}

// Property Managers
.property-managers {
	.section {
		padding: 0;

		.row {
			max-width: 1280px;
		}

		.section-content {
			@media only screen and (max-width: $screen-sm) {
				padding: 0;
			}
		}
	}

	.hero {
		margin-bottom: 0 !important;

		.hero-image {
			display: block;
		}

		.section-header {
			color: #fff;
			margin: 0 auto;
			padding: 0;
			position: absolute;
			text-align: center;
			top: 43%;
			left: 14%;
			letter-spacing: 1px;
			max-width: 75%;
			text-shadow: 1px 1px 10px rgba(#000,0.3);
			z-index: 3;

			.section-title {
				font: 70px/1.1 Oswald, sans-serif;

				@media only screen and (max-width: $screen-sm) {
					font-size: 1.7em;
				}
			}

			.subtitle{
				font: 30px/1.1 Oswald, sans-serif;
				text-transform: uppercase;

				@media only screen and (max-width: $screen-sm) {
					color: #000;
					font-size: 1.3em;
					margin-top: 20px;
				}
			}

			@media only screen and (max-width: $screen-sm) {
				padding: 2% 4%;
				position: relative;
				top: auto;
				left: auto;
				text-align: left;
				text-shadow: none;
				max-width: 100%;
			}
		}
	}

	.callout-register {
		background: #e1261c;
		padding: 2%;

		h3 {
			color: #fff;
			display: block;
			font: 37px/1.1 Oswald, sans-serif;
			letter-spacing: 1px;
			text-align: center;
			text-transform: uppercase;
			vertical-align: top;

			@media only screen and (max-width: $screen-sm) {
				display: block;
				margin: 15px auto;
			}
		}

		.callout-form {
			padding: 2%;

			form {
				background: #efefef;
				border-radius: 5px;
				box-shadow: 1px 1px 15px rgba(#000,0.7);
				min-height: 440px;
				padding: 4%;

				.required-text {
					font-style: italic;

					span {
						color: #e1261c;
						font-weight: 700;
					}
				}

				label {
					display: block;
					font-size: 13px;
					margin-bottom: 4px;
				}

				.checkbox {
					float: left;
				}

				.checkbox-label {
					margin: -2px 0 0 18px;
				}

				@media only screen and (max-width: $screen-sm) {
					min-height: 620px;
				}
			}
		}

		.callout-content {
			padding: 4% 3% 0;
			text-align: center;

			img {
				margin-bottom: 2%;
			}

			p {
				font-size: 12px;
				font-weight: 600;
				padding: 1% 1% 0;
				text-align: left;

				a {
					color: #000;
					text-decoration: underline;

					&:hover {
						text-decoration: none;
					}

					&:after {
						content: '';
					}
				}
			}
		}

		.required {
			color: #e1261c;
			font-weight: 700;
			margin-left: 1px;
		}

		.btn-register {
			background: #000;
			border: 0;
			border-radius: 0;
			color: #fff;
			font: 15px/1.1 Oswald, sans-serif;
			font-weight: 600;
			letter-spacing: 1px;
			margin-left: 10px;
			padding: 11px 30px;
			text-transform: uppercase;
			transition: all 0.4s ease;

			&:hover {
				background: #e1261c;
			}

			@media only screen and (max-width: $screen-sm) {
				margin-bottom: 15px;
			}
		}
	}

	.callout-options {
		background: #efefef;
		padding: 4% 5% 14%;

		.content {
			padding: 5% 7% 0 0;
			text-align: right;

			h2 {
				font-size: 33px;
				font-weight: 700;
				letter-spacing: -1px;
				margin-bottom: 12px;
			}

			p {
				font-size: 15px;
				line-height: 36px;
			}

			@media only screen and (max-width: $screen-sm) {
				padding: 5% 0;
				text-align: left;
			}
		}

		#cycler {
			padding-left: 2%;
			position: relative;

			img {
				position: absolute;
				z-index: 1;
				max-width: 95%;

				&.active {
					z-index: 3;
				}

				@media only screen and (max-width: $screen-sm) {
					max-width: 100%;
				}
			}

			@media only screen and (max-width: $screen-sm) {
				height: 43vh;
				padding: 5% 0 0;
			}
		}

		@media only screen and (max-width: $screen-sm) {
			padding: 2% 7%;
		}
	}

	.callout-download {
		background: #000;
		color: #fff;
		padding: 4% 5%;

		.col {
			padding-left: 6%;

			img {
				float: left;
				margin-right: 8%;
				max-width: 17%;

				@media only screen and (max-width: $screen-sm) {
					float: none;
					margin: 0 0 20px;
				}
			}

			a {
				color: #fff;

				&:hover {
					color: #bf0000;
					text-decoration: none;
				}

				&:after {
					display: none;
				}
			}

			h4 {
				font-size: 17px;
				font-weight: 700;
				margin: 0 0 7px;
				text-transform: uppercase;
			}

			p {
				font-size: 13px;
				line-height: 23px;
				margin: 0;
				padding: 0;
				width: 80%;

				@media only screen and (max-width: $screen-sm) {
					width: 100%;
				}
			}

			&.locksmith {
				border-left: 2px solid #fff;
				padding-left: 10%;

				img {
					margin-top: 5px;

					@media only screen and (max-width: $screen-sm) {
						margin-top: 0;
					}
				}

				p {
					width: 85%;

					@media only screen and (max-width: $screen-sm) {
						width: 100%;
					}
				}

				@media only screen and (max-width: $screen-sm) {
					border: none;
					padding-left: 6%;
				}
			}

			@media only screen and (max-width: $screen-sm) {
				margin-bottom: 6%;
				padding: 6%;
			}
		}

		@media only screen and (max-width: $screen-sm) {
			text-align: center;
		}
	}

	.callout-key-features {
		background: #efefef;
		padding: 5% 3%;

		.content {
			padding: 7% 0 0 9%;

			h3 {
				font-size: 33px;
				font-weight: 700;
				letter-spacing: -1px;
				margin-bottom: 15px;
			}

			ul {
				padding: 0 0 0 1.5em;

				li {
					margin: 1.3em 0;
				}
			}

			@media only screen and (max-width: $screen-sm) {
				padding: 4% 3%;
			}
		}

		@media only screen and (max-width: $screen-sm) {
			padding: 3%;
		}
	}

	#site-footer {
		margin-top: -130px;
		padding: 1.5em 0 0.5em;

		nav {
			&.row {
				display: none;
			}
		}

		.row {
			text-align: center;
		}

		@media only screen and (max-width: $screen-sm) {
			margin-top: 0;
		}
	}
}

// eLOTO Product Page
.eloto {
	margin-top: 40px;
	text-align: center;

	p {
		font-size: 16px;

		.btn {
			display: block;
			margin: 13px 0;
		}
	}
}

// Gift Shop Page

.gift-shop {
	background-color: #ffffff;

	#mobile-nav {
		display: none !important;
	}
}