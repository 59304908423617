
#site-footer {
	@extend .gradient-gray-footer;
	border-top: 3px solid #929292;
	padding: 3.5em 0 0 0; 
	font-size: 12px; 
	margin: 0;

	a {
		text-decoration: none;
		color: $brand-primary;

		&:hover {text-decoration: underline;}
	}

	.col.last { text-align: right; } 

	nav .col {
		padding:0;
		margin: 0 0 25px 0;

		@media only screen and (max-width: $screen-sm) {
			margin-bottom: 26px;
			text-align: center; 
		}

		ul {

			padding: 10px 0 5px 15px; 

			@media only screen and (max-width: $screen-sm) {
				padding: 0 0 10px 0; 
			}

			li { padding: 5px 0; }
		}
		
		.social-nav {

			margin: 0 auto;		 
			width: 69%;

			li { 
				padding: 0;
				margin: 5px 0;
			}

			@media only screen and (max-width: $screen-sm) {
				width: auto;
				text-align: center; 

				li {
					margin: 5px 2px;
					padding: 0;
				}
			}
		} 		
	}

	h3 { 
		font-size: $font-size-small;
		margin: 0; 
		display: block;
		color: $brand-primary;
		text-transform: uppercase;
		padding: 0 0 5px 15px;
		font-family: $font-family-narrow;
		border-bottom: 1px solid #a8a7a7;

		@media only screen and (max-width: $screen-sm) {
			border-bottom: none;
		}		
	}
  

	@media only screen and (max-width: $screen-sm) {

		 h3 {
			padding: 0 0 5px 0;
		}
	}
 	
	.col.md-2.home-personal,
	.col.md-2.service-support  {width: 19.667%;}
	.col.md-2.business-industry {width: 17.667%;} 
	.col.md-2.about-us {width: 15.667%;} 
	.col.md-2.more-sites{ width: 16.667%;}
	.col.md-2.social {width: 10.667%;}
	

	@media only screen and (max-width: $screen-sm) {
		
		.col {			 

			&.last {
				padding-bottom: .5em;
				text-align: center;
			}
		}

		.col.md-2.home-personal,
		.col.md-2.business-industry,
		.col.md-2.more-sites, 
		.col.md-2.service-support,
		.col.md-2.about-us,
		.col.md-2.social {
	    	width: 100%;
		}
	 
		nav.row .col.social {
			text-align: center;
			margin: 15px 0;
			border-top: 1px solid #a8a7a7;
			border-bottom: 1px solid #a8a7a7;
			padding: 17px 0 0 0;
		}

	}

 
	.md-12.col {
		padding:0;
		@media only screen and (max-width: $screen-sm) { 
			text-align: center;
			margin: 0 auto 15px;
		}
	} 

	.utility { 
		display: inline-block;

		* { font-size: 10px; }

		@media only screen and (max-width: $screen-sm) {
			display: block;
			margin-top: 1em;

			li { padding-left: .5em; }
		}
		
	}

	.copyright{ 
    	padding-right: 10px;
    	font-size: 10px; 

    	@media only screen and (max-width: $screen-sm) { 
			padding-right: 0px;
		}
	} 
}
.home #site-footer,
.home-segment #site-footer {

	margin: -60px 0 0;

	@media only screen and (max-width: $screen-sm) {
		margin: 0;
	}
}