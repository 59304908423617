.careers {

	.callout-content {
	    margin-bottom: 4em;
	}

	.page-title {
	    font: 4em/1.1 "Oswald",sans-serif;
	    margin-bottom: 0;
	    position: absolute;
	    top: 0.35em;
	    text-transform: uppercase;
	    font-weight: 500;
	    color: #ffffff;
	    left: 0.5em;
	    text-shadow: 4px 6px 9px rgba(0,0,0,0.7);
	    letter-spacing: 0.02em;

	    @media only screen and (max-width: $screen-sm) {
			font-size: 2em;
			left: 1em;
			top: 0.15em;
		}
	}

	.content-description {
	    margin-top: 2em;
	}

	p {
		font-size: 1.1em;
	}

	.align-center {
	    padding-top: 1.2em;
	    font-size: 1.3em;
	}

	.callout-item {
	    text-align: center;
	    padding-bottom: 2em;

	    .content {
	    	padding: 2em 0 2em;
	    	background-image: linear-gradient(to bottom, #cccccc 0%, #ffffff 100%);
	    }

	    img {
	    	width: 100%;
	    }

	    img, a {
	    	display: block;
	    }
	}

	h2 {
	    font-size: 1.4em;
	}

}
	