a {
	color: $link-color;
	text-decoration: none;
 
	&:hover { 
		color: $link-hover-color;
		text-decoration: underline;
	}
}  

.not-phone {
	color:inherit;
	text-decoration: none;
}
	
.text {color: green;}

h1, .h1,
h2, .h2,
h3, .h3,
h4, .h4,
h5, .h5,
h6, .h6 {
	font-family: $headings-font-family;
	line-height: $headings-line-height;
	font-weight: $headings-font-weight;
	color: $headings-color;
	margin: $headings-margin; 
}

h1, .h1 { 
	font-size: $font-size-h1; 
	@media only screen and (max-width: $screen-sm) {
		font-size: floor($font-size-base * 2); 
	}  
}

h2, .h2 { 
	font-size: $font-size-h2; 
	@media only screen and (max-width: 768px) {
		font-size: floor($font-size-base * 1.7);
	}
}

h3, .h3 { 
	font-size: $font-size-h3; 
	@media only screen and (max-width: 768px) {
		font-size: floor($font-size-base * 1.5);
	}
}

h4, .h4 { font-size: $font-size-h4; }

h5, .h5 { font-size: $font-size-h5; }

h6, .h6 { font-size: $font-size-h6; }

p { margin: 0 0 $font-size-base; }

small,
.small { font-size: $font-size-small; }

.smaller { font-size: $font-size-smaller; }

.large { font-size: $font-size-large; }

.medium-bold {font-weight: 600;}
 
.line,
.block { display: block; }

#container {
	position: relative;
	z-index: 2;
	margin: 0 auto;
	max-width: 100%;
	min-width: $site-width;
	background-color: $container-bg;
	-webkit-transition: -webkit-transform .3s;
	transition: transform .3s;

	.with-featherlight & {
		z-index: 9999;
	}

	@media (max-width: $screen-sm) {
		max-width: 100%;
		width: 100%;
		min-width: 320px;
	}
}

.mobile-nav-visible #container {
	-webkit-transform: translateX(260px);
	transform: translateX(260px);

	@media only screen and (min-width: 768px) {
		-webkit-transform: none;
		transform: none;
	}
}

@media only screen and (max-width: $screen-sm) {
	html,
	body { overflow-x: hidden; }
}

.hidden{
	display: none;
}

body {
	background-color: white;
}