.product-search {

	h1 {
		color: $brand-primary;
		font-size: 33px;

		@media only screen and (max-width: $screen-sm) { 
			font-size: 22px;

		} 	
	}


	#smart-paginator {
		background: none;
		font-weight: normal;
		height: inherit;
		overflow: visible;
		padding: 0;
		width: auto;

		@media only screen and (min-width: $screen-sm+1) { 
			text-align: right;
			float: right;
		}

		.short { display: none;  }

		> span { 
			color: $color-base;

			@media only screen and (max-width: $screen-sm) { 
				margin-top: 20px;
			}

			&:first-of-type { 
				margin-left: 20px;

				@media only screen and (max-width: $screen-sm) { 
					display: block;
					clear: left;
					margin-left: 0;
				}
			}
		}

		li {
			margin: 0;
			padding: 0 1px;

			a {
				background: none;
				border: none;
				box-shadow: none;
				color: #ab0000;
				font-size: 14px;
				font-weight: 300;
				margin: 0;
				padding: 0;

				@media only screen and (max-width: $screen-sm) { 
					width: 40px;
				}

				&:hover {
					color: $link-hover-color;
					text-decoration: underline;
				}
			}

			&.active {
				padding: 0 3px;
			}

			.prev {
				margin-left: 8px;
				min-width: 40px;
			}

			.next {
				margin-left: 3px;
			}

			span {
				background: none;
				border: none;
				box-shadow: none;
				margin: 0;
				padding: 0;
			}
		}

		.btn {
			background: none !important;
			border: none !important;
			color: $link-color;
			font-weight: 700;
			margin: 0;
			width: 15px;

			@media only screen and (max-width: $screen-sm) { 
					width: 40px;
				}

			&.disabled {
				color: #c2bcbc !important;
			}
		}
	}

	.pagination {
		@extend .clearfix;
		font-size: 14px;
		border-bottom: 1px solid #000;
		padding: 0 0 .5em 0;
		margin: 2em 0 1em 0;
		color: #000;

		ul, ol { 
			display: inline-block;
			margin: 0 5px 0 0;
		}
		
		.sorting {

			.sort-by { 
				margin: 0 0 0 10px;
				padding: 4px 5px 4px 1px;  
 		 
				&:last-child {
					margin-left: 0;   
				}

				&.current {
					color: $link-color;
					background-color: #e6e6e6; 
				}

				span {
					display: inline-block; 
					height: 10px;
					width: 20px; 
					padding: 0 4px;
					font-size: 1.2em;
					text-align: center;
				}

				&.ascending span {vertical-align: middle; line-height: 0.9;}
				&.descending span {vertical-align: top; line-height: 0.7;}
			 
			}

			//.ascending span {
			//	@extend .fa;
			//	@extend .fa-sort-asc;
			//}
			//
			//.descending span {
			//	@extend .fa;
			//	@extend .fa-sort-desc;
			//}

		}

		.pages {
			display: inline-block;
			margin: 0 10px 0 15px; 

			.list-inline {
				padding: 0 2px 0 5px;   

				li {
					margin: 0; 
					text-align: center;
					display: inline-block; 

					a {
						min-width: 20px; 
						padding: 0 4px 3px 4px;
						display: block;
					}
				}  

				li.current {
					background-color: #e6e6e6;

					a {color: $brand-primary;}

					a:hover {
						cursor: text;
						text-decoration: none;
					}
				}
			}
		}

		.paging {
			.view-all {
					margin-left: 1%;
				&.current {
					background-color: #e6e6e6;  
					padding: 4px 6px 4px 6px;  
				}
			}
		}

		.paging,
		.sorting {
			@media only screen and (max-width: $screen-xs) {
				width: 100%;
				text-align: center;
			}
		}
	}


	#products { @extend .clearfix; }

	.product-list .item { 
		width: 25%;
		border-bottom: solid 1px $brand-primary-light;
		padding-bottom: 1em;
		overflow: hidden;

		@media only screen and (min-width: $screen-lg) {
			&:nth-last-child(4), &:nth-last-child(3), &:nth-last-child(2), &:nth-last-child(1) {border-bottom: 0;}
		}

		@media only screen and (max-width: $screen-sm) {
			width: 50%;
			&:nth-last-child(2), &:nth-last-child(1) {border-bottom: 0;}
		}

		@media only screen and (max-width: $screen-xs) {
			width: 100%;
			&:last-child {border-bottom: 0;}
		}
	}

	.results {
		margin: 0;
		padding: 10px 0;
	}

	.resp-tabs-list {
		li {
			a {
				color: $brand-primary;
				text-decoration: none;

				&:hover {
					color: #fff;
				}
			}
			&:hover {
				a {
					color: #fff;
				}
			}
		}

		.resp-tab-active {
			a {
				color: #fff;
			}
		}
	}

	.resp-tabs-container {
		border-top: solid 3px #ab0000;
	}

	@media only screen and (max-width: $screen-sm) {
		ul.resp-tabs-list {
			display: block;
		}

		.resp-tabs-list li {
			 padding: 10px 5px;
			font-size: 12px;
		}
	}	
} 
.free-shipping {
	background: #e6e6e6;
	width: 100%;
	text-align: center;
	padding: 5px;
	font-size: 20px;
	color: $brand-primary;

	span {
		color: $brand-secondary;
		font-weight: 600;
	}
	@media only screen and (max-width: $screen-sm) {
		display: none;
	}
}

#products {
 
	@media only screen and (max-width: $screen-sm) {
		font-size: 17px;
	}
}

.search {

	.pagination {
		@extend .clearfix;
		font-size: 14px;
		border-bottom: 1px solid #000;
		padding: 0 0 0.625em;
		margin: 0.5em 0 1em 0;  
		color: #000;

		.view-all {
			display: inline-block;
			vertical-align: bottom;
		}

		#smart-paginator {
			display: inline-block;
			margin: 4px 0 0 15px;
			float: none; 
			position: relative;
			top: 0.3em;

			ul, ol {
				padding-right: 10px;

				&:after {
					content: "|";
					display: inline-block;
					line-height: 1.9;
					vertical-align: top;
					margin-left: 12px; 
				}

				li {
					display: inline-block;
					margin: 0;
					text-align: center;
	 
					span, a {
						display: block;
					    min-width: 20px;
					    padding: 0 4px 3px;

					    &.current {
					    	color: #434141;
					    	background-color: #e6e6e6;
					    }

					    &.current.prev,
					    &.current.next {
					    	background-color: #FFF;
					    }

					}
				}
			}
		}

		.view-all.current {
			background-color: #e6e6e6;
			padding: 4px 6px;
		}

	}

	.item {
		border-bottom: solid 1px $brand-primary-light;
		overflow: hidden;
		padding: 0 10px 2em 10px;
		width: 25%;

		a:hover,
		a:hover h3 {
			color: $link-hover-color; 
		}

		.img {
			height: 150px;
			text-align: center;

			img { 
				max-width: 100%;
				max-height: 100%;
			}
		}

		@media only screen and (max-width: $screen-sm) {
			padding-bottom: 1em;
			width: 50%;
		}

		@media only screen and (max-width: $screen-xs) {
			width: 100%;
		}  
	}

	.item-list:last-child {
		.item {
			@media only screen and (min-width: $screen-lg) {
				&:nth-last-child(4), &:nth-last-child(3), &:nth-last-child(2), &:nth-last-child(1) {border-bottom: 0;}
			}
			@media only screen and (max-width: $screen-sm) {
				width: 50%;
				&:nth-last-child(2), &:nth-last-child(1) {border-bottom: 0;}
			}
			@media only screen and (max-width: $screen-xs) {
				width: 100%;
				&:last-child {border-bottom: 0;}
			}
		}
	} 

	.category-list {
		padding-top: 25px;

		.item {
			min-height: 265px;
			margin: 10px;
			height: auto;
			text-align: center;
			width: 200px;

			@media only screen and (max-width: $screen-xs) {
				width: 100%;
				margin: 10px 0;
			}
		}
	}

	.resp-tabs-container {
		.section-content {
			border-top: solid 1px $brand-primary-light;
			&:first-child { border-top: none; }
			padding: 15px 0;
		} 

		.zebra-accordion {
			padding-bottom: 0;

			.trigger {
				margin-top: 1px;
				
				.faq-category {
					padding: 0 0 5px 0;
				}
			
			}
		}

	}

}

.support-faq {

	h1 {
		margin-bottom: 14px;
	}

	.filter-content {
		display: block;
		height: 1px;
	}

	.resp-tabs-container {
		fieldset {
			legend {
				font-size: 25px;
			}

			p {
				padding: 0 10px;
			}

			div {
				padding: 0 10px 20px;
				
				blockquote {
					margin: 0 0 7px;
				}

				em {
					color: #444;
					font-weight: 700;
					margin-right: 10px;
				}

				a {
					font-size: 12px;
				}
			}
		}
	}
}

.search-category-callout {
	display: block;
	color: inherit;
	border: 1px solid $brand-secondary;
	margin: 0 0 1.5em;

	@media only screen and (min-width: 768px) {
		margin: -1.5em 0 2em;
	}

	&:hover {
		text-decoration: none;
		color: inherit;
	}

	.row {
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.col {
		padding: 10px;
	}

	img {
		display: block;
		margin: 0 auto;
		max-height: 150px;
	}

	h2 {
		font-weight: 700;

		* {
			display: inline;
			margin: 0;
			padding: 0;
		}
	}
}