table { width: 100%; }

.table-group { margin-bottom: -20px; }

.table {
	background: $table-background;
	margin-bottom: 20px;

	&:last-child { margin-bottom: 0; }
	
	th,
	td {
		padding: 10px;
		border: $table-border;
	}

	th {
		text-align: center;
		font-weight: 400;
		background: $table-th-background;
		color: $table-th-color;
	}
}

.table-striped tr:nth-child(even) { background: $table-stripe-background; }

.table-wrapper {
	overflow-x: auto;
	overflow-y: hidden;
	width: 100%;
	margin-bottom: 20px;

	.table {
		margin-bottom: 0;

		@media screen {
			min-width: 600px;
		}
	}
}