#site-header {
	position: relative;
	z-index: 99;
	padding: 60px 0 0;
	width: 100%;
	margin: 0 auto;
	height: 115px;

	@media only screen and (max-width: $screen-sm) {
		padding-top: 0;
		height: auto;
	}

	.site-header-inner-container {
		position: relative;
		margin: 0 auto;
		max-width: $container-inner-width;
	}
}

.app-bar {
	z-index: 1;

	@media only screen and (max-width: $screen-sm) {
		z-index: 11;
		border-bottom: 1px solid $panel-border-color;

		.col {
			position: relative;
			background-color: $brand-primary-light;
			z-index: 4;
			height: 47px;
			padding: .4em 0;
		}
	}

	.btn {
		position: absolute;
		top: 0;
		z-index: 4;
		padding: 0 10px;
		height: 47px;
		background: $brand-secondary;
		color: #fff;
		border-radius: 0;
	}

	.search-bar {
		position: absolute;
		bottom: 23px;
		right: 20px;
		z-index: 12;
		width: 290px;

		@media only screen and (min-width: $screen-sm+1) {
			display: block !important;
		}

		label {
			margin-right: .25em;
		}

		form {
			text-align: right;
		}

		@media only screen and (max-width: $screen-sm) {
			top: 0;
			background-color: $brand-secondary;
			border-top: 1px solid $panel-border-color;
			transition: top .15s;
			width: 100%;
			height: 47px;
			display: block;
			bottom: none;
			right: 0;
			padding: 0 7%;
			z-index: 2;

			&.active {
				top: 100%;
			}

			form {
				margin: .5em 0;
				text-align: left;
			}

			label {
				display: none;
			}

			input {
				width: 100%;
			}

			.btn {
				.fa-search {
					display: none;
				}

				.assistive-text {
					position: relative;
					clip: auto;
				}
			}
		}
	}
}

.search-bar {
	form {
		position: relative;
	}

	input {
		padding-right: 60px;
		font-size: 14px;
		border-color: $color-base;
	}

	.btn {
		position: absolute;
		top: 0;
		right: 0;
		height: 100%;
		padding: 0 10px;
		background-color: #333;
		border-radius: 0;

		.fa-search:before {
			color: #fff;
		}
	}
}

#site-title {
	@extend .header-logo-black;
	position: relative;
	z-index: 3;
	float: left;
	margin: 0.25em 0 .5em 0;
	width: 219px;
	height: 52px;

	@media only screen and (max-width: $screen-sm) {
		float: none;
		margin: 0 auto;
		width: 156px;
		height: 35px;
		text-align: center;
		background-size: 100% auto;
		background-position: 0 -35px;
	}

	.category &,
	.service-support-home & {
		background-position: left top;

		@media only screen and (max-width: $screen-sm) {
			background-position: left -35px;
		}
	}

	a {
		color: inherit;
		text-decoration: none;
		display: block;
		height: 100%;
	}
}

#nav-toggle {
	left: 0;
	padding: 12px 11px 7px;
	border-right: 1px solid $panel-border-color;

	&.btn-link {
		color: #fff;

		&:hover {
			color: $brand-primary-light;
		}
	}
}

#search-toggle {
	right: 0;
	border-left: 1px solid $panel-border-color;

	&.btn-link {
		color: #fff;

		&:hover {
			color: $brand-primary-light;
		}
	}
}

.nav {
	@extend .reduced;
	margin-bottom: 0;

	li {
		position: relative;
		margin: 0;
	}

	a {
		display: block;
		text-decoration: none;
		padding: 10px;
	}

	.nav-item > a {
		color: $brand-primary;

		.icon-arrow-down {
			border-top-color: #ccc;
		}

		&:hover {
			background-color: #333;
			color: #fff;
		}
	}

	.nav-item.active .dropdown-menu {
		left: 0;
		display: block !important;
	}

	.nav-item.active > a {
		background-color: #000;
		color: #fff;
	}
}

.dropdown-menu {
	@extend .reduced;
	position: absolute;
	top: 100%;
	left: -999em;
	z-index: 99;
	background-color: #fff;
	min-width: 200px;
	border: 1px solid #ccc;
	border-top-width: 0;
	box-shadow: 0 1px 3px rgba(#000,0.25);
	overflow: hidden;
	padding: 5px 0;

	@media only screen and (max-width: $screen-sm) {
		display: none;
	}

	li {
		display: block;

		& + li {
			margin: 0;
		}
	}

	a {
		display: block;
		color: inherit;
		text-align: left;
		padding: 5px;
	}
}

#site-nav {
	position: absolute;
	z-index: 12;
	top: 0;
	left: 300px;
	width: 900px;	

	@media only screen and (max-width: $screen-sm) {
		display: none !important;
	}

	.nav {
		display: flex;
		width: 900px;
	}

	.nav-item {
		& > a {
			text-align: center;
			font-size: 16px;
			font-weight: 400;
			letter-spacing: 0.02em;
			font-family: $font-family-narrow;
			line-height: 2.7;
			padding: 0 10px;
			height: 42px;
			display: block;
			text-transform: uppercase;
		}

		&.active .nav-bar-sub {
			opacity: 1;
			visibility: visible;
		}
	}

	.nav-bar-sub {
		position: absolute;
		left: 0;
		display: block;
		background-color: $brand-secondary;
		color: #fff;
		padding: 0;
		width: 220px;
		opacity: 0;
		visibility: hidden;
		transition: opacity 250ms ease, visibility 250ms ease;

		.nav-item {
			float: none;
			margin-top: 0;

			.nav-link {
				font-family: $font-family-sans-serif;
				color: inherit;
				font-size: 14px;
				text-transform: none;
				height: auto;
				padding: 10px 13px;
				text-align: left;
				letter-spacing: 0;
				line-height: 1.4;
			}
		}
	}

	.nav-item-personal,
	.nav-item-business {
		&:hover {
			& > .nav-link {
				background-color: $brand-secondary;
				color: #fff;
			}

			.nav-bar-sub {
				opacity: 1;
				visibility: visible;
				transition-delay: 100ms;
			}
		}

		& > .nav-link:hover {
			background-color: $brand-secondary;
		}
	}
}

.personal .nav-item-personal,
.business .nav-item-business,
.service-and-support .nav-item-support,
.faq .nav-item-support,
.packlock-buying-guide .nav-item-support,
.where-to-buy .nav-item-where-to-buy {
	background: $brand-secondary;

	@media only screen and (max-width: $screen-sm) {
		background: inherit;
	}

	a {
		color: #fff;
	}
}

.personal.all-products .nav-item-personal .nav-item-all-products,
.business.all-products .nav-item-business .nav-item-all-products,
.around-the-home .nav-item-around-the-home,
.bike .nav-item-bike,
.vehicle .nav-item-vehicle,
.travel .nav-item-travel,
.school-gym-health-club .nav-item-school-gym-health-club,
.key-safes-personal-storage .nav-item-key-safes-personal-storage,
.commercial-security .nav-item-commercial-security,
.school-health-and-recreation .nav-item-school-health-recreation,
.door-hardware .nav-item-door-hardware,
.safety-solutions .nav-item-safety-solutions,
.government .nav-item-government,
.cables-and-chains .nav-item-cables {
	background: #000;
}

.site-nav-bkgd {
	position: absolute;
	z-index: 1;
	bottom: 18px;
	right: 0;
	width: 900px;
	height: 42px;
	background-color: $brand-primary-light;

	.category &,
	.service-support-home & {
		background-color: #fff;
		opacity: 0.86;
	}
}

#mobile-nav {
	position: absolute;
	top: 0;
	left: 0;
	width: 260px;
	height: 100%;
	border-right: 1px solid #cd6666;
	color: #fff;

	ul ul {
		display: none;
	}

	& > .nav {
		& > .nav-item {
			background: none;

			&.open {
				.nav-link {
					.plus-minus {
						&:after {
							content: '\2013';
						}
					}
				}
			}

			.nav-link {
				color: inherit;
			}

			& > .nav-link {
				position: relative;

				.plus-minus {
					top: 50%;
					transform: translateY(-50%);
					line-height: 1;
				}
			}
		}

		& > .nav-item-personal > .nav-link,
		& > .nav-item-business > .nav-link,
		& > .nav-item-support > .nav-link,
		& > .nav-item-where-to-buy > .nav-link {
			border-bottom: 1px solid #cd6666;
			font-weight: 700;
			font-size: 18px;
		}

		.nav-bar-sub {
			.nav-link {				
				padding-left: 30px;
			}
		}
	}
}

.mobile-nav-visible #mobile-nav-close {
	position: fixed;
	top: 0;
	left: 260px;
	z-index: 99999;
	width: 100%;
	height: 100%;
}

.utility-bar {
	position: absolute;
	top: 0;
	left: 0;
	z-index: 4;
	width: 100%;
	background-color: #fff;
	padding: 8px 0;
	text-align: right;
	box-shadow: 0 5px 5px #d6d6d6;
	border-bottom: solid 1px #afafaf;
	font-size: 0.85em;
	height: 33px;
	overflow: hidden;

	.row {
		max-width: 1220px;
	}

	.category &,
	.service-support-home &{
		box-shadow: none;
		border: none;
	}

	ul {
		margin-bottom: 0;
	}

	.list-inline li {
		position: relative;
	}

	.nav-item.active .dropdown-menu {
		left: 0;
		display: block !important;
	}

	a {
		color: $brand-primary;
	}

	.right li > a {
		font-weight: 600;
	}

	.nav-item {
		&:not(:last-child) {
			margin-right: 1em;
		}
	}

	.nav-item.active > a {
		background-color: #000;
		color: #fff;
	}

	.nav-item.icon-cart {
		display: inline-block;
		position: relative;
		width: 53px;
		height: 22px;
		text-align: left;

		.cart-count {
			position: absolute;
			left: 32px;
			top: -1px;
			text-align: center;
			font-family: $font-family-narrow;
			font-size: 0.75em;
			letter-spacing: 1px;
			font-weight: 700;
			color: $brand-secondary;
			width: 20px;
		}
	}
}