/*======================== Page: Homepage ======================= */
.home {
	.video-download-modal-container {
		display: none;
	}
}
.home #content { 

	.section-content { @extend .clearfix; }

	.section-callouts {
		margin-bottom: .5em;
	}
}

.home .callout-alt {
	display: block;
	height: 264px;
	overflow: hidden;
	margin: 5px 0;

	@media only screen and (max-width: $screen-sm) {
		max-width: 390px;
		height: auto;
		margin: 10px auto;
	}
	
	a {
		color: #fff;
	}	
	
	img {
		z-index: 1;
		position: relative;
	}
	
	h2 {
		background-color: $brand-secondary;
	    opacity: .87;
	    color: #fff; 
	    margin: -72px 0 0;
	    padding: 15px; 
	    text-transform: uppercase;
	    font: 32px/1.2 $font-family-narrow; 
	    position: relative;
	    z-index: 2;

	    @media only screen and (max-width: $screen-sm) {
			font-size: 24px;
			margin-top: -62px;
		}
	}

	&:hover {
		text-decoration: none;

		h2 {
			opacity: 1;
		}
	}

}

.ie8 .home .callout-alt:hover {

		h2 {
			background-color: darken($brand-secondary, 10%);
		}
	}