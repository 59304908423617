@import "variables";
@import "mixins";
@import "colors";

// ===== Header: Navigation ===== //

.about-us .page-header,
.about-us .section {

	padding-bottom: 0;

	#about-nav-toggle {
		@extend .gradient-gray-dark;
		@extend .border;
		border-width: 1px 0 0 0;
		padding: 10px 0;
		line-height: 100%;
		cursor: pointer;
		position: relative;
		color: #fff;

		&:hover { text-decoration: none; }

		.plus-minus {
			position: relative;
			left: 3px;
			right: 0;
			top: 4px;
		}

		&.open .plus-minus { top: 2px; }
	}

	.nav-bar {
		margin-top: 1em;
		@extend .gradient-gray-light;

		@media only screen and (max-width: $screen-sm) {
			background: none;
		}

		.nav {
			background: none;
			 
			@media only screen and (max-width: $screen-sm) {
				padding: 0;
			}
		}

		.nav-item {
			@extend .border;
			border-width: 0;
			float: left;

			@media only screen and (max-width: $screen-sm) {
				float: none;
				background: #fff;
				text-align: center;
				border-width: 0 0 1px 0;
			}

			> a {
				text-transform: uppercase;
				font-size: 14px;
				padding: 10px 18px;

				@media only screen and (max-width: $screen-sm) {
					padding: 13px 0;
				}

				&:hover {
					background: none;
					color: $brand-secondary;
				}
			}
		}
	}
}

.about-us.history .nav-link1,
.about-us.as-seen .nav-link2,
.about-us.super-bowl .nav-link3,
.about-us.press-room .nav-link4,
.about-us.who-we-are .nav-link5,
.about-us.driving-directions .nav-link6,
.about-us.supporting-us-jobs .nav-link7 { color: $brand-secondary; }

#about-nav-top {
	
	@media only screen and (max-width: $screen-sm) {
		display:  none;
	}	
}

// ===== General: Main Content Area ===== //

.clearfix:before,
.clearfix:after {
	content: ' ';
	display: table;
}

.clearfix:after { clear: both; }

.about-us {
	.section-main {
		padding: 0;
		overflow: hidden;
		position: relative;

		&.section-basic {
			border-bottom: 45px solid $brand-secondary;
		}

		@media only screen and (max-width: $screen-sm) {
			height: auto;
			overflow: visible;
		} 
		.gradient-fade {
			position: absolute;
			top: 0;
			height: 100%;
			width: 100px;

			@media only screen and (max-width: $screen-sm) {
				display: none;
			}
		}
	}

	.section-bottom {
		@extend .gradient-gray-light;
		padding: 30px 0;
	}

	 
}

// ===== General: Flexslider ===== //
.flexslider,
.row-wide  {
	width: 100%;
	max-width: 1200px;
	margin: 0 auto;

	@media only screen and (max-width: $screen-sm) {
		padding: 0 10px;
	}
}

.flexslider {
	@media only screen and (max-width: $screen-sm) {
		width: 100%;
		padding: 0 0 0 75px;
	}
}

.flexslider-controls {
  position: relative;
  background: $brand-secondary;

  @media only screen and (max-width: $screen-sm) {
  	position: absolute;
  	left: 0;
  	top: 0;
  	width: 60px;
  	height: 100%;
  }

}

@media only screen and (max-width: $screen-sm) {

	.history .flexslider,
	.history .flexslider-controls { min-height: 515px; }
	
	.as-seen .flexslider,
	.as-seen .flexslider-controls { min-height: 260px; }

	.super-bowl .flexslider,
	.super-bowl .flexslider-controls { min-height: 200px; }
}

.about-us .flex-control-nav {

	margin: 0;
	display: inline-block;
	text-align: center;
	margin: 0 auto;
	width: 100%;

	li {
		
		color: #fff;
		cursor: pointer;
		padding: 11px 2em 15px 2em;
		margin: 0;
		vertical-align: middle;

		@media only screen and (max-width: $screen-sm) {
			width: 100%;
			padding: 15px 0;
			text-align: center;
		}
	}

	.flex-active {
		background: #6e0000 url("//cdn.masterlock.com/masterlock/resources/img/about-us/about-us-nav-on.png") no-repeat bottom center;

		@media only screen and (max-width: $screen-sm) {
			background: transparent url("//cdn.masterlock.com/masterlock/resources/img/about-us/about-us-nav-on-mobile.png") no-repeat left center;
			overflow: visible;
			width: 68px;
			padding-right: 10px;
			background-size: 100%;
		}
	}
}

.slides {

	padding: 0;
	margin: 0;
	height: auto;
	@extend .clearfix;

	>li:not(.flex-active-slide) { 

		height: 0 !important;
	}

	.slide-image {
		width: 780px;
		height: 420px;
		float: left;

		@media only screen and (max-width: $screen-sm) {
			display: none;
		}

		img { 
			float: right;
			width: 100%;
		}
	}

	.slide-content {
		width: 400px;
		height: 420px;
		padding-top: 10px;
		float: right;
		overflow: hidden;

		@media only screen and (max-width: $screen-sm) {
			height: auto;
			width: 100%;
		}

		.section-title { 
			margin-bottom: 0;
			font-size: 30px;

			@media only screen and (max-width: $screen-sm) {
				font-size: 27px;
			}
		}

		ol {
			overflow-y: auto;
			height: 374px;
			margin: 0;

			@media only screen and (max-width: $screen-sm) {
				height: auto;
			}
		}

		li {
			@extend .clearfix;
			@extend .border;
			border-width: 1px 0 0 0;
			padding: 10px 0 0 0;
			margin: 10px 0 0 0;

			&:first-child {
				border-top: 0;
				padding: 0;
			}

			&:last-child {
				margin-bottom: 10px;
			}

			.thumb {
				float: left;
				width: 65px;
				margin-right: 12px;
			}

			.content {
				float: left;
				width: 300px;

				@media only screen and (max-width: $screen-sm) {
					width: 100%;
					padding-left: 77px;
					padding-right: 0;
					margin-left: -77px;
				}
			}

			a {
				color: $color-base;

				&:hover {
					color: $brand-secondary;
					text-decoration: none;
				}
			}

			h4 {
				font-weight: 700;
				font-size: 21px;
				margin: 0;
			}

			p { 
				font-size: 14px;
				margin: 0;
			}
		}

	}
}

// ===== Page: About Us ===== //

.about-us {

	.content-hero {

		padding-top: 0;
		height: 420px;

		 @media only screen and (max-width: $screen-sm) {
			height: auto;
			padding: 0;
			overflow: hidden;
		 }

		.flexslider {		
			position: relative;
			z-index: 0;
			@media only screen and (max-width: $screen-sm) {
		 		padding: 0;
			}
		}

		.col {
			padding: 0 4% 0 60%;
			color:#fff;
			position:absolute;
			z-index: 1;
			top: 40px;

			@media only screen and (max-width: $screen-sm) {
				padding: 0 10px;
				color: $color-base;
				position: relative;
				top: 10px;
			}
		}

		.page-title {
			font: 35px/1.4 $font-family-narrow;
			text-transform: uppercase;
			margin-bottom: 16px;

			@media only screen and (max-width: $screen-sm) { font-size: 28px; }
		}

		p {
			font-size: 14px;
			line-height: 1.6;

			a {
				color: #ffffff;
				text-decoration: underline;

				@media only screen and (max-width: $screen-sm) {
					color: $link-color;
					text-decoration: none;
				}
			}

			a:hover {
				color: #eeeeee;
				@media only screen and (max-width: $screen-sm) {
					color: $link-color;
					text-decoration: underline;
				}
			}
		} 
	}
}

// ===== Page: Press Release ===== //
.press-release {
	 article header h1 { margin-top: 1em; }
}


// ===== Page: Press Room ===== //
.press-room {
	a[href$=".pdf"]:after {
    	content: "";
	}
}


// ====== modal styles ====== //

.fancybox-type-iframe {

	.fancybox-nav {

		width: 13%;
	    	
    	span {
		    visibility: visible;
		    opacity: 0.2;
		}

		&:hover span {
			opacity: 1;
		}
    }

    .fancybox-next {
	    right: -7px;
	}

	.fancybox-prev {
	    left: -7px;
	}

	@media only screen and (min-width: $screen-sm+1) {

		.fancybox-nav {
	    	width: 60px;

	    	span {
			    visibility: visible;
			    opacity: 0.5;
			}

			&:hover span {
				opacity: 1;
			}
	    }
	}
}

.modal {
	.modal-image {
		text-align: center;
	}

	.modal-content {
		text-align: left;
	}
}

// ==== Supporting American Jobs ===== //

.supporting-us-jobs {
	.page-title {
		width: 100%;
		height: auto;
		margin: 0 auto;
		
		@media only screen and (max-width: $screen-sm) { margin-top: 20px; }  
	}

	.intro {
		padding: 10px 0 20px 0;	
		font-size: $font-size-large;
		@media only screen and (max-width: $screen-sm) { font-size: $font-size-small; }  
	}
}

@media print {

	.flexslider-controls,
	.slides .slide-image,
	.about-us .section-bottom,
	.slides .slide-content li .thumb { display: none !important; }

	.about-us .print { display: inline !important; }

	.flexslider,
	.row-wide,
	.content-hero,
	.content-hero .col,
	.slides .slide-content,
	.slides .slide-content ol {
		position: relative !important;
		float: none !important;
		padding: 0 !important;
		margin: inherit !important;
		width: auto !important;
		height: auto !important;
		text-align: left !important;
		top: 0 !important;
	}

	.section-main.section-basic { border: 0 !important; }

	.flexslider,
	.row-wide {
		display: block !important;
		height: auto !important;
		opacity: 1 !important;

		.slides,
		li {
			display: block !important;
			height: auto !important;
			opacity: 1 !important;
			float: none !important;
			width: 80% !important;

			.slide-content .section-title {
				margin-bottom: 1em;
			}

			ol li .content {
				width: 80% !important;
			}

			.slide-content li:first-child {
				border-top: 1px solid #cfcfcf !important;
				padding-top: 10px !important;
				margin-top: 0 !important;
			}
		}

		li {
			
			&:first-child .slide-content .section-title {
				display: block !important;
			}

			.slide-content .section-title {
				display: none !important;
			}
		}
	}

	.slides >li:not(.flex-active-slide) { 

		height: 100% !important;
	}

	.about-us .flexslider,
	.about-us .row-wide { margin: 0 auto !important; }
}

.featherlight-content{
	overflow:hidden!important;
}