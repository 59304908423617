.header-logo-white { 
	@include sprite($height: 100%, $width: 100%, $x-coord: 1px, $y-coord: -68px);
}

.header-logo-black { 
	@include sprite($height: 100%, $width: 100%, $x-coord: 0px, $y-coord: -62px);	
}

.footer-logo { 
	@include sprite($height: 100%, $width: 100%, $x-coord: 0px, $y-coord: -65px);
}

.icon-facebook { 
	@include sprite($height: 100%, $width: 100%, $x-coord: -3px, $y-coord: -147px);
}

.icon-twitter { 
	@include sprite($height: 100%, $width: 100%, $x-coord: -43px, $y-coord: -147px);
}

.icon-google-plus {
	@include sprite($height: 100%, $width: 100%, $x-coord: -82px, $y-coord: -147px);
}

.icon-pinterest {
	@include sprite($height: 100%, $width: 100%, $x-coord: -2px, $y-coord: -186px);
}

.icon-youtube { 
	@include sprite($height: 100%, $width: 100%, $x-coord: -43px, $y-coord: -186px);
}

.icon-linkedin {
	@include sprite($height: 100%, $width: 100%, $x-coord: -81px, $y-coord: -187px);
}

.icon-email {
	@include sprite($height: 100%, $width: 100%, $x-coord: -3px, $y-coord: -225px);
}

.icon-blog {
	@include sprite($height: 100%, $width: 100%, $x-coord: -40px, $y-coord: -224px);
}

.icon-cart {
	@include sprite($height: 100%, $width: 100%, $x-coord: 21px, $y-coord: -266px);
}

.icon-pdf {
	@include sprite($height: 100%, $width: 100%, $x-coord: -49px, $y-coord: -270px);
}

.icon-product-videos {
	@include sprite($height: 100%, $width: 100%, $x-coord: 0, $y-coord: -300px);
	
}

.icon-product-docs {
	@include sprite($height: 100%, $width: 100%, $x-coord: -75px, $y-coord: -377px);
	
}

.icon-product-related {
	@include sprite($height: 100%, $width: 100%, $x-coord: -75px, $y-coord: -300px);
	
}

.icon-product-faqs {
	@include sprite($height: 100%, $width: 100%, $x-coord: 0, $y-coord: -379px);
	
}

.icon-tough-under-fire {
	@include sprite($height: 100%, $width: 100%, $x-coord: 0, $y-coord: -462px);
}

.icon-tough-under-fire-dark {
	@include sprite($height: 100%, $width: 100%, $x-coord: 0, $y-coord: -655px);
}

// *** has to be last so retina devices use the 2x image
.icon { @include image-2x; }
