@media print {

	@page { margin: 1cm; }

	* {
		background: transparent !important;
		color: #000 !important;
		text-shadow: none !important;
	}

	nav,
	form,
	button
	embed,
	object,
	video,
	.btn,
	.flexslider,
	.app-bar,
	.social-share,
	.callout img,
	.callout-alt img,
	.link-title,
	.hero .hero-image,
	.resp-tabs-list,
	.plus-minus,
	.pagination,
	.item-compare,
	.tough-under-fire-testimonials .read-all,
	.tough-under-fire-testimonials .read-more,
	.product-image figure figcaption,
	#currentAddress,
	.section-content .section-content-title-sm .icon,
	.faq-contact,
	.filter-content,
	.product-detail .tab-content .row .col img,
	.free-shipping,
	.item-link-micro { display: none !important; }

	.print { display: inherit !important; }

	.resp-tab-content,
	.zebra-accordion .list-accordion,
	.zebra-accordion form { 
		display: block !important;
		height: auto !important;
		opacity: 1 !important;
	}

	pre,
	blockquote,
	img,
	.panel { page-break-inside: avoid; }

	// resets

	#content,
	.callout,
	.callout h2,
	.callout-alt,
	.callout-alt h2,
	.callout .box-content,
	.product-selector .overlay-effect,
	.product-selector .overlay-effect .box-content,
	.hero,
	.hero .section-header,
	.hero .section-header .col,
	.side-nav .section-content,
	.general .section:first-of-type,
	.free-shipping,
	.best-used-for .col,
	.tough-under-fire-testimonials .col,
	.tough-under-fire-testimonials .more-from-master,
	.more-from-masterlock .site h5,
	.section-content .section-content-title-sm .icon-header,
	.product-detail .tab-content .row .col {
		position: relative !important;
		float: none !important;
		padding: 0 !important;
		margin: inherit !important;
		width: auto !important;
		height: auto !important;
		text-align: left !important;
		min-height: 1px !important;
		min-width: 1px !important;
		top: 0 !important;
		left: 0 !important;
	}

	.row .col,
	.resp-tab-content,
	.filter-content .zebra-accordion .list-accordion { margin: 0 0 20px 0 !important; }

	#content, 
	#container {
		min-width: 0 !important;
	}

	.centered-red,
	.tab-content .tab-header h2 { 
		border: 1px solid #ddd;
		border-width: 1px 0;
		page-break-inside: avoid;
	}

	.page-title, 
	.hero .section-header 
	.section-title,
	.section-content .section-content-title-lg { font-size: 26px;  }

	h2, .h2 { font-size: 20px; }

	h3, .h3 { font-size: 18px; }
	
	.page-title, h1, h2, h3 {
		margin: 5px 0;
		padding: 0;
	}

	.border { border: 0 !important; }

	// Home page
 	 
	.home-segment .col-sm .col { width: 33.33% !important; }

	#site-header { padding: 1em 0 2em 0; }

	#site-header .site-header-inner-container,
	.hero { 
		width: 100%;
		max-width: 1200px;
	}

	#site-footer .copyright { font-size: 12px; }

	.home #site-footer, .home-segment #site-footer {margin: 0;}

	.section-header.row {
		margin: 2px !important;

		.line {display: inline !important;}
	}
	
	// Product Comparison
	.product-comparison	.compare-table {
		margin: 0; 
		width: 7.25in !important;  

		.category {
	  		padding: 0 5px 0 0; 
	  		width: 20% !important; 
	  	}

	  	.model-num { font-size: 14px !important; }

		tr { border-bottom: solid 1px #CCC; }

		.item {
			border-left: 1px solid #CCC !important;
			width: 20% !important; 
			padding: 5px; 

			&:last-child {
				border-right: 1px solid #CCC !important;
			}

			.image-zoom img {
				width: 85% !important; 
			}
		}
	}

	// General pages 
	
	.general  h2 { margin: 5px 0; }

	.supporting-us-jobs {

		.page-title img {
			width: 100% !important;  
		}

		.callout { display: none; }
	}

	.government {
		.section-content {
			.col.first,
			.panel-primary {display: none;}
		}

		.md-6 {
			width: 100% !important; 
		}
	}

	.faq {

		.section-content .zebra-accordion .trigger,
		.section-content .zebra-accordion .faq-category {
			padding: 10px 0 !important; 
		}

		.callout-links .col { 
			width: 100% !important; 
			a {
				padding: 0 !important;
				margin: 0 !important; 
				height: auto !important; 
				min-height: 0 !important;
				line-height: normal !important;

				span { line-height: normal !important;}
			}
		}
	}

	.item-image-src {
		display: inline-block;
	}

	.product-search .md-9{
		width: 100%;
	}
	
}