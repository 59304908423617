@charset "UTF-8";

/* EU US stylesheet */

.eu-container {
    position: relative;
    background-color: #FFF;
    max-width: 1280px;
    border-top: 15px solid #000;
    margin: 0 auto;
    text-align: left;
}

.eu-link:hover {
    background: #bd1829 !important;  
}


/* ====== header ======================================= */


.eu-header {
    .right-menu-btns {
        margin: 4px 8px 0 0;
        text-align: right;
        
        a {
            display: initial;
            padding: 0;
            
            img {
                vertical-align: middle;
                
                &.icon-lang {
                    margin-left: 7px;
                    width: 33px;
                }

                &.love-btn {
                    width: 110px;
                }
            }
        }
    }
}

.eu-landing {
    .callout-alt {
        display: block;
        height: 264px;
        overflow: hidden;
        margin: 5px 0;
        
        h2 {
            background-color: #bd1829;
            opacity: .87;
            color: #fff;
            margin: -72px 0 0;
            padding: 15px;
            text-transform: uppercase;
            font: 32px/1.2 "Oswald",sans-serif;
            position: relative;
            z-index: 2;
        }
        
        &:hover {
            text-decoration: none;
            
            h2 {
                opacity: 1;
            }
        }
    }
}

#eu-header {
    background: transparent url('#{$cdnPath}/img/eu-header.jpg') no-repeat left top;
    height: 242px;
    border-top: 1px solid #FFF;
    border-bottom: 1px solid #FFF;
    position: relative;
    background-position: top;
    	h1 {
    		text-indent: -9999px;
    		display: block;
		    width: 300px;
		    margin: 0;
    			a {
    				display: block;
    				height: 147px;
    			}
    	}
    	.lovemasterlock-img {
    		margin-top: -110px;
    		float: right;
    			@media only screen and (max-width: $screen-sm) {
					display: none;
				}
    	}
    @media only screen and (max-width: $screen-sm) {
		background-repeat: no-repeat;
		background-size: 126%;
		background-position: left;
		max-height: 100px;
	}
	 @media only screen and (max-width: 550px) {
		background-repeat: no-repeat;
		background-size: 140%;
		background-position: left;
		max-height: 65px;
	}
}

/* ===== footer ==================================== */

#eu-footer {
	background: transparent url('#{$cdnPath}/img/eu-footer.jpg') repeat-x left top;
    padding-top: 10px;
    height: 55px;
    border-bottom: 1px solid #FFF;
    font-weight: bold;
    font-size: 10px;
    color: #FFF;
    position: relative;
    @media only screen and (max-width: $screen-sm) {
		//background-size: 60%;
		height: 133px;
		background-size: cover;
	}
}

#eu-footer ul.footer-nav {
	padding: 0 0 5px 0;
	margin-top: 10px;
	display: block;
	float: left;
	list-style: none;
	@media only screen and (max-width: $screen-sm) {
		padding-left: 20px;
		margin: 0 auto;
		float: none;
	}
}

#eu-footer ul.footer-nav li {
	padding: 0 7px;
	border-left: 1px solid #FFF;
	float: left;
	@media only screen and (max-width: $screen-sm) {
		text-align: center;
	}
}

#eu-footer ul li.first {
	padding-left: 20px;
	border: none;
	@media only screen and (max-width: $screen-sm) {
		padding-left: 0px;
	}
}

#eu-footer a { color: #FFF; }

#eu-footer a:hover { color: #CCC; }

#eu-footer p {
	padding-right: 20px;
	margin: 4px 0 0 0;
	text-align: right;
}

#eu-footer small { font-size: 10px; }

#eu-footer .social {
	width: 360px;
	position: absolute;
	right: 15px;
	top: 20px;
	text-align: right;
	@media only screen and (max-width: $screen-sm) {
		top: 70px;
		text-align: center;
		width: 100%;
	}
}

#eu-footer .social h5 {
	display: inline-block;
	color: #FFFFFF;
	font-size: 10px;
	margin: 0 2px 0 10px;
	float: none;
	text-transform: uppercase;
	vertical-align: middle;
	font-weight: normal;
}

#eu-footer .social ul {
	display: inline-block;
	float: none;
	padding:0;
	vertical-align: middle;
}

#eu-footer .social .like-follow {margin-top:-3px;}

#eu-footer .social ul li {
	display: inline-block;
	vertical-align: middle;
	float: none;
	padding:0;
	margin:0;
	border: none;
}

#eu-footer ul.static-social li {
 height: 17px !important;
 width: 17px !important;
}

.eu-container .copyright {
	display: block;
	clear: both;
	height: 37px;
    line-height: 2.5;
	background-color: #000000;
	color: #FFFFFF;
	text-align: center;
	overflow: hidden;
	margin-bottom: 20px;
		small {
			font-size: 10px;
		}
}

.pagination {
	font-size: 14px;
    border-bottom: 1px solid #000;
    padding: 0 0 .5em 0;
    margin: 2em 0 1em 0;
    color: #000;
}

.product-search header#eu-header {
	height: 175px !important;
}

#eu-products {
	margin-top: 60px;
	.item-list .item {
		height: 345px;
	}
	.item-list .item-name {
		height: 80px;
	}
}

/*================ Product Detail =============================*/
@import "variables";
@import "mixins";
@import "colors";

// ================ General Styles ==================== //


.clearfix:before,
.clearfix:after {
	content: ' ';
	display: table;
}

.clearfix:after { clear: both; }

figure {
	figcaption { padding-top: 1em; }
}

// ================ Top section of page ==================== //

.product-image {
	text-align: center;
	padding-bottom: 1em;

	.thumbs {
		margin: 0 auto;
		max-width: 210px;
		text-align: center;

		@media only screen and (max-width: $screen-sm) { max-width: 100%; }

		@extend .clearfix;

		a {
			overflow: hidden;
			display: inline-block;
			margin: 0 5px;
			border: 1px solid #b2b2b2;
			width: 55px;
			height: 55px;

			img { height: 100%; max-width: none;}

			&:hover {
				box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.4);
			}
		}
	}

	.product-video-thumbs {
		@extend .border;
		border-width: 1px 0 0 0;
		margin-top: 1em;
		padding-top: 1em;
	}

}

.easyzoom-flyout {
	border: solid 3px #b2b2b2;
	min-width: 250px;
	background-color: #FFFFFF;
	
	@media only screen and (max-width: $screen-xs) {display: none !important; }
}

.product-info {
	min-height: 360px;
	@media only screen and (max-width: $screen-sm) { min-height: 0; } 

	.product-name { font-size: 28px; 
		@media only screen and (max-width: $screen-sm) { font-size: 20px; }
	}

	.product-description { 
		font-size: 22px; 
		line-height: 1.2;
	}

	.product-name, p { font-weight: 500; }
 
	.social-share {
		margin: 0 0 .8em 0;
	}

	p, .best-used-for { 
		width: 100%;
	}

	.best-used-for h3,
	.product-warranty h3 {
		font-size: 21px;
		font-weight: 700;
	}

	.product-warranty {
		margin: 40px 0 20px; 

		a {
			display: inline-block;
			padding-bottom: 5px;
		}
	}

	.best-used-for {
		padding-top: 1em;

		@media only screen and (max-width: $screen-sm) {
			margin-top: 2em;
		}

		@extend .clearfix;

		ul.row {
			margin-bottom: 0;
			@media only screen and (max-width: $screen-sm) { margin:0; }

			li { 
				display: table;
				margin: 0 0 15px 0;

				@media only screen and (max-width: $screen-sm) {
					padding: 5px 0;
					margin: 0;
				}
			}
		}

		.title {
			text-align: left;

			.thumb {
				border: 1px solid #b2b2b2;
				width: 80px;
				height: 80px;
				overflow: hidden;
				margin-right: 10px;
				display: inline-block;
				vertical-align: middle;
			}

			.thumb-description {
				display: inline-block;
				width: 55%;
				vertical-align: middle;
			}

			@media only screen and (max-width: $screen-sm) {
				width: 100%;
			}
		}
	}

	.microsite {
		@extend .border;
		display: block;
		border-width: 1px 0 0 0;
		padding: .75em 0 0 0;
		margin: .5em 20px 0 0;

		@media only screen and (max-width: $screen-sm) {
			border: 0;
			margin: 0;
			padding: 0;
		}
	}
}

.product-actions {

	.price-featured,
	.price-featured-title,
	.price-large,
	.btn,
	p { display: inline-block; }

	* { vertical-align: top; }

	.price-main {

		margin-bottom: 1.25em;
		font-weight: 500;
		font-size: 23px;
		line-height: 1.2;

		@media only screen and (max-width: $screen-xs) {
			width: 80%;
			margin: 0 auto 2em auto;
		}

		.price-featured {
			@extend .border;
			border-width: 0 0 0 1px;
			padding: 0 0 0 .5em;
			margin: 0 0 0 .5em;
		}

		.price-featured-title { font-size: 14px; }

		.price-large {
			font: 38px/1 $font-family-narrow;
			color: #000;
			vertical-align: bottom;
			margin-bottom: 2px; 

			sup {
				top: .6em;
				font-size: 60%;
			}
		}

		.price-quantity {
			font-size: 0.8em;
			color: #000000; 
			vertical-align: bottom;
		}

		.price-small {
			display: block;
			font-size: 13px;
			margin-top: 5px;
		}
	}

	.find-products, .buy-now { 
		max-width: 210px;

		@media only screen and (max-width: $screen-xs) { 
			max-width: 100%;
		}
	 }

	.find-products {
		form {
			@extend .gradient-gray-dark;
			padding: 10px 17px 14px 17px;
		}

		label {
			color: #fff;
			font-weight: 500;
		}

		input { 
			padding-right: 46px;
			font-size: 14px;
		}

		.find-products-bar { 
			position: relative;
			margin: 10px 0 0 0;

			.field-validation-error {
				left: 64%;
			}
		}

		button {
			position: absolute;
			top: 0;
			right: 0;
			height: 100%;
			z-index: 3;
			padding: 6px 10px;
			font-weight: 700;
			background: #c9c9c9;
			color: #666666;
			border: 1px solid #bbbbbb;
			border-width: 1px 1px 1px 0;

			&:hover { 
				background: darken(#c9c9c9, 10%);
			}
		}
	}

	.buy-now {
		@extend .clearfix;
		@extend .border;
		border-width: 1px 0 0 0;
		padding: 1.25em 0 0 0;
		margin: 1.25em 0 0 0;

		@media only screen and (max-width: $screen-xs) { 
			width: 200px;
			margin: 1em auto;
			border: 0;
		}

		.btn {
			padding-left: 2.25em;
			padding-right: 2.25em;
			font-weight: 500;
			height: 47px;
		}

		.shipping-info { 
			margin: 0 0 0 5px;
			font-size: 13px;
			line-height: 1.2;
		}
	}
	.small-asterisk {
		font-size: 15px;
		vertical-align: top;
	}

	.small-text {
		font-size: 10px;
		display: block;
		padding-top: 40px;
	}
}

// ================ Tabbed Content ==================== //

.chevron {
	visibility: hidden;
	display: block;
	position: relative;
	text-align: center;
	padding: 1px;
	margin: 10px auto;
	height: 1px;
	width: 80px;
}

.chevron:before {
	content: '';
	position: absolute;
	top: 0;
	left: 0;
	height: 100%;
	width: 51%;
	background: #ccc;
	transform: skew(0deg, 6deg);
}

.chevron:after {
	content: '';
	position: absolute;
	top: 0;
	right: 0; 
	height: 100%;
	width: 50%;
	background: #ccc;
	transform: skew(0deg, -6deg);
}

.top-nav {
	@extend .gradient-gray-light;
	box-shadow: 0 10px 10px rgba(#000,0.2);
	text-align: center;
	padding: 20px 0 10px 0;
	margin: 0 auto -10px;
	width: 1280px;
	
	@media only screen and (max-width: $screen-sm) { 
		width: 100%;
	}

	ul {
		margin-bottom: 0;
	}

	li {
		a {
			color: #777;
			font-size: 18px;
			font-family: $font-family-narrow;
		}

		&:hover a,
		&.active a { 
			color: $link-color; 
			text-decoration: none;

			.chevron {
				visibility: visible;
			}
		}
	}

	&.top-nav-stick {
		position: fixed;
		top: 0;
		z-index: 3; 
	    width: 100%;
	}
}

.tab-content {
	font-size: 16px;

	.tab-header { 
		background: $brand-secondary;
		color: #ffffff;
		text-align: center;
		width: 1280px;
		margin: 0 auto;
		@media only screen and (max-width: $screen-sm) { 
			width: 100%;
		}

		
		h2 {
			font-size: 24px;
			height: 55px;
			line-height: 55px;
			margin-bottom: 0;
		}

		@media only screen and (max-width: $screen-sm) { 
			min-height: 45px;
		}
	}

	.section-content {

		padding-top: 4.5em;
		padding-bottom: 6em;

		@media only screen and (max-width: $screen-sm) { 
			padding-top: 1em;
			padding-bottom: 1em;
		}

		ul li { 
			margin-bottom: 1.5em; 
			
			&.md-6.sm-6.col {
				float: none;
				display: inline-block;
				vertical-align: top;
				width: 49%;
				word-wrap: break-word;
				@media only screen and (max-width: $screen-sm) { 
					width:100%;
				}
			}
		}
		
	}

	.product-details {
		p { line-height: 2; }
	}
}

.product-nav-accordion { //class added via mediaCheck in product-navigation.js

	.accordion-products {position: relative;}

	.accordion-products > section {
		border-top: solid 1px #FFFFFF;

			h2.accordion-products-trigger {
				text-align: left;
				padding-left: 20px; 
				padding-right: 40px; 
				cursor: pointer;

				@media only screen and (max-width: $screen-sm) { 
					font-size: 15px;
					height: 45px;
					line-height: 45px;
				}
			}
 
			.accordion-products-trigger:after,
			.accordion-products-trigger.open:after { 
				position: absolute;
				right: 18px;
				top: 0;	
				font-size: 24px;
			}

			.accordion-products-trigger:after {
				content: "+";
			}

			.accordion-products-trigger.selected:after {
    			content:"-";
			}

	}

	.tough-under-fire-testimonials {
		padding: 0;
		
		.tab-header { 
			background: $brand-secondary; 
			h2 { 
				max-width: 1200px;
				margin-right: auto;
				margin-left: auto; 
				position: relative;
				width: 100%;
			} 
		}
	}

}




.specs-list {
	display: table;
	width: 100%;		

	li {
		display: table-row;
		font-weight: 400;

		.spec-key,
		.spec-value {  
			padding: 15px 25px;
			display: table-cell;
			vertical-align: middle;
		}

		.spec-key { 
			width: 40%;
			font-weight: 700;
		}

		.spec-value {
			width: 60%;
			
			a { text-decoration: underline; }
		}

		&:nth-child(odd) {
			.spec-key,
			.spec-value {  
				background: #eee;
				color: #000000; 
			}
		}
	}
}

.service-support {

	.section-content ul {
		@extend .clearfix;
		padding: 3em 0;
		margin: 0;
	}

	.product-docs {
		ul li a { 
			word-wrap: break-word;
			display: block; 
		}

		.icon-pdf {
			position: relative;
			display: inline-block;
			top: 3px;
			width: 16px;
			height: 16px;
			margin-right: 10px;
		}
	}
}

.answer{
	float: left;
	padding-right: 10px;
}

.related-products {

	.item-list .item { 
		width: 16.6%;

		@media only screen and (max-width: $screen-sm) { 
			width: 50%; 
		}

		@media only screen and (max-width: $screen-xs) { 
		 width: 100%; 
		} 
	}
}

ul.eu-product-cat.results {
	width: 769px !important;
}

.flexbox {
	@media only screen and (min-width: $screen-lg) {  
	 
		.flex-cols > .row {
			@include flexbox;
		}
		 
		.flex-cols > .row > .col {
			flex: 1; 
		    -ms-flex-order: 1; 
			position: relative;

		  	> .border {
				position: relative;
				height: 100%;
				right: 0;
			}
		}

	}
}

.product-search {
    
    &.after-fire {
        
        .section-content {
            padding-top: 40px;
            min-height: 600px;
            
            .fire {
                text-align: center;
            }
            
            .content {
                padding-top: 25px;
                
                @media only screen and (max-width: 767px) {
                    padding-top: 0;
                }
            }
            
            @media only screen and (max-width: 767px) {
                padding-top: 0;
                min-height: 0;
            }
        }
    }
    
    &.safes-contact {
        
        .section-content {
            padding-top: 40px;
            min-height: 600px;
            
            .content {
                h4 {
                    font-size: 18px;
                    font-weight: 600;
                }
                
                ul {
                    padding-bottom: 20px;
                    
                    &.hotline {
                        list-style: none;
                        padding-left: 0;
                        
                        li {
                            padding-bottom: 5px;
                            
                            &.note {
                                font-style: italic;
                            }
                        }
                    }
                }
            }
            
            .icon {
                padding-top: 50px;
                text-align: center;
                
                @media only screen and (max-width: 767px) {
                    display: none;
                }
            }
            
            @media only screen and (max-width: 767px) {
                padding-top: 0;
                min-height: 0;
            }
        }
    }
}

// Literature Site
.literature {
    
    #site-header {
        padding: 0;
        
        &.eu-header {
            .site-header-inner-container {
                background: #000;
                padding: 20px 0 10px;
                
                @media only screen and (max-width: 767px) {
                    padding: 10px 0 0;
                }
            }
            
            .app-bar {
                .col {
                    @media only screen and (max-width: 767px) {
                        background: none;
                        height: 68px;
                    }
                }
                
                @media only screen and (max-width: 767px) {
                    border-bottom: none;
                }
            }
        }
    }
    
    #site-title {
        background-image: url("#{$cdnPath}/img/sprite.png");
        height: 52px;
        width: 219px;
        background-position: 0 0;
    }
    
    .app-bar .col {
        @media only screen and (max-width: 767px) {
            height: 75px;
        }
    }
    
    .right-header {
        float: right;
        margin-top: 15px;
        
        a {
            color: #fff;
            font-size: 21px;
            font-style: italic;
        }
        
        @media only screen and (max-width: 767px) {
            display: none;
        }
    }
    
    #content {
        h1 {
            font-size: 33px;
            font-weight: 700;
            margin-bottom: 7px;
            text-transform: uppercase;
        }
        
        .callout-alt {
            h2 {
                background-color: #bd1829;
                opacity: .87;
                color: #fff;
                margin: 0 0 0 16px;
                padding: 10px;
                text-transform: uppercase;
                font: 30px/1.2 "Oswald",sans-serif;
                position: absolute;
                z-index: 2;
                top: 0;
                width: 448px;
                
                @media only screen and (max-width: 767px) {
                    margin: 0;
                    top: 10px;
                    width: 100%;
                }
            }
        }
        
        .lit-home {
            text-align: center;
            
            .col {
                display: inline-block;
                float: none;
                margin: 50px 0 100px;
                text-align: left;
                
                .callout-alt {
                    
                    img {
                        @media only screen and (max-width: 767px) {
                            width: 100%;
                            max-width: none;   
                        }
                    }
                    
                    h2 {
                        background-color: #bd1829;
                        opacity: .87;
                        color: #fff;
                        margin: -72px 0 0;
                        padding: 15px;
                        text-transform: uppercase;
                        font: 32px/1.2 "Oswald",sans-serif;
                        position: relative;
                        z-index: 2;
                        top: 0;
                        width: 100%;
                    }

                    &:hover {
                        text-decoration: none;

                        h2 {
                            opacity: 1;
                        }
                    }
                }
                
                @media only screen and (max-width: 767px) {
                    display: block;
                    float: left;
                    margin: 0 0 40px;   
                }
            }
        }
        
        .cat-home {
            margin-top: 50px;
            
            img {
                @media only screen and (max-width: 767px) {
                    width: 100%;
                    max-width: none;   
                }
            }
            
            .landing-text {
                margin-top: 60px;
                
                p {
                    font-size: 19px;
                    line-height: 27px;
                    padding: 0;
                    
                    @media only screen and (max-width: 767px) {
                        padding: 0 30px;   
                    }
                }
                
                @media only screen and (max-width: 767px) {
                    margin-top: 10px;
                    padding-right: 0;   
                }
            }
            
            .home-text {
                h1 {
                    font-size: 33px;
                    font-weight: 600;
                    margin-bottom: 7px;
                    text-transform: uppercase;
                }
            }
            
            .col {
                text-align: center;
                
                &.download {
                    margin-top: 63px;
                    text-align: center;
                    
                    img {
                        @media only screen and (max-width: 767px) {
                            width: auto;
                            max-width: 100%;
                        }
                    }
                    
                    @media only screen and (max-width: 767px) {
                        margin-top: 20px;        
                    }
                }
            }
            
            @media only screen and (max-width: 767px) {
                margin-top: 20px;
            }
        }
        
        .cat-flags {
            margin: 60px auto 20px;
            text-align: center;
            
            .col {
                display: inline-block;
                float: none;
                margin-bottom: 20px;
                text-align: center;
            }
            
            @media only screen and (max-width: 767px) {
                margin: 10px auto;   
            }
        }
    }
    
    #eu-footer {
        @media only screen and (max-width: 767px) {
            height: 70px;
        }
    }
    
    .cat-link {
        
        p {
            font-size: 17px;
            margin: 7px 0 0;
            padding: 0 40px;
        }
        
        &:after {
            display: none !important;
        }
    }
    
    .dutch-banner {
        float: right;
        font-size: 20px;
        font-style: italic;
        font-weight: 600;
        text-align: center;
    }
    
    .catalogue {
        margin: 40px 0 20px;
        text-align: center;
        
        &.cat-section {
            h3 {
                background: #bd1829;
                color: #fff;
                font-size: 19px;
                font-weight: 700;
                margin-top: 100px;
                padding: 12px 20px;
                text-align: left;
                
                @media only screen and (max-width: 767px) {
                    margin-top: 0;
                }
            }
        }
        
        @media only screen and (max-width: 767px) {
            margin: 20px 0;
        }
    }
    
    .technical {
        h1 {
            font-weight: 600;
            margin: 15px 0 0;
        }
        
        h3 {
            font-weight: 600;
        }
        
        .col {
            margin-bottom: 60px;
            
            li {
                font-size: 13px;
            }
            
            @media only screen and (max-width: 767px) {
                margin-bottom: 10px;   
            }
        }
    }
}