[data-tooltip] {
	color: $tooltip-trigger-color;
	cursor: pointer;

	&:hover { color: $tooltip-trigger-hover-color; }
}

.tooltip-trigger-icon { font-size: 120%; }

.tooltip {
    font-size: 13px;
    margin-left: 67px;
	position: absolute;
	z-index: 9999;
	padding: 1.25em;
	width: 100%;
	max-width: $tooltip-max-width;
	background: $tooltip-bg; 
	color: $tooltip-color;
	@if $tooltip-border-radius != 0 {
		border-radius: $tooltip-border-radius;
	}

	&:after {
		content: '';
		position: absolute;
		top: 100%;
		left: 30.3%;
		border: $tooltip-arrow-width solid transparent;
		border-top-color: $tooltip-arrow-color;
		margin-left: -$tooltip-arrow-width;
	}
}

.tooltip-left:after {
	left: 1em;
	margin-left: 0;
}

.tooltip-right:after {
	right: 1em;
	left: auto;
	margin-left: 0;
}