*,
:before,
:after {
	-moz-box-sizing: border-box;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
}

.social-share * { box-sizing: content-box; }

.clearfix:before,
.clearfix:after {
	content: ' ';
	display: table;
}

.clearfix:after { clear: both; }

.group { @extend .clearfix; }

.row {
	@extend .clearfix;
	position: relative;
	width: 100%;
	max-width: $container-width;
	margin-right: auto;
	margin-left: auto;
}

.row .row {
	max-width: none;
	min-width: 0;
	margin-right: -10px;
	margin-left: -10px;
	@media only screen and (max-width: $screen-sm) {
		margin: 0;
	}
}

.row .row.col-sm {
	margin-right: -5px;
	margin-left: -5px;
}

.col {
	position: relative;
	float: left;
	width: 100%;
	padding: 0 10px;

	@media only screen and (max-width: $screen-sm) {
		padding: 10px 0;
	}
}

.col-sm {

	.col { padding: 0 5px; }

	@media only screen and (max-width: $screen-sm) {
		padding: 5px 0;
	}
}

.col.last { float: right; }

.row-centered {
	@extend .clearfix;
	margin: 0 auto;
	float: none;
}

.flex-col {
	position: relative;
	padding: 0 10px;
}
 
@media only screen {

	.sm-12 { width: 100%; }

	.sm-11 { width: 91.666667%; }

	.sm-10 { width: 83.333333%; }

	.sm-9 { width: 75%; }

	.sm-8 { width: 66.666667%; }

	.sm-7 { width: 58.333333%; }

	.sm-6 { width: 50%; }

	.sm-5 { width: 41.666667%; }

	.sm-4 { width: 33.333333%; }

	.sm-3 { width: 25%; }

	.sm-2 { width: 16.666667%; }

	.sm-1 { width: 8.333333%; }

	.show-for-xs { display: none !important; }

}

@media only screen and (min-width: 768px) {

	.md-12 { width: 100%; }

	.md-11 { width: 91.666667%; }

	.md-10 { width: 83.333333%; }

	.md-9 { width: 75%; }

	.md-8 { width: 66.666667%; }	

	.md-7 { width: 58.333333%; }

	.md-6 { width: 50%; }

	.md-5 { width: 41.666667%; }

	.md-4 { width: 33.333333%; }

	.md-3 { width: 25%; }

	.md-2 { width: 16.666667%; }

	.md-1 { width: 8.333333%; }

}

@media only screen and (min-width: 1025px) {

	.lg-12 { width: 100%; }

	.lg-11 { width: 91.666667%; }

	.lg-10 { width: 83.333333%; }

	.lg-9 { width: 75%; }

	.lg-8 { width: 66.666667%; }

	.lg-7 { width: 58.333333%; }

	.lg-6 { width: 50%; }

	.lg-5 { width: 41.666667%; }

	.lg-4 { width: 33.333333%; }

	.lg-3 { width: 25%; }

	.lg-2 { width: 16.666667%; }

	.lg-1 { width: 8.333333%; }

	.show-for-md,
	.show-for-sm { display: none !important; }

	.show-for-lg { display: inherit !important; }

}

@media only screen and (min-width: 768px) and (max-width: 1024px) {

	.show-for-lg,
	.hide-for-md,
	.show-for-sm { display: none !important; }

	.show-for-md { display: inherit !important; }

}

@media only screen and (max-width: $screen-sm) {
	
	.show-for-lg,
	.show-for-md,
	.hide-for-sm { display: none !important; }

	.show-for-sm { display: inherit !important; }

}

@media only screen and (max-width: 480px) {

	.show-for-xs { display: inherit !important; }

	.hide-for-xs { display: none !important; }

	.xs-12 { width: 100%; }

	.xs-11 { width: 91.666667%; }

	.xs-10 { width: 83.333333%; }

	.xs-9 { width: 75%; }

	.xs-8 { width: 66.666667%; }

	.xs-7 { width: 58.333333%; }

	.xs-6 { width: 50%; }

	.xs-5 { width: 41.666667%; }

	.xs-4 { width: 33.333333%; }

	.xs-3 { width: 25%; }

	.xs-2 { width: 16.666667%; }

	.xs-1 { width: 8.333333%; }

}

.ie8 {
	
	.show-for-sm,
	.show-for-md { display: none !important; }

	.sm-12 { width: 100%; }

	.sm-11 { width: 91.666667%; }

	.sm-10 { width: 83.333333%; }

	.sm-9 { width: 75%; }

	.sm-8 { width: 66.666667%; }

	.sm-7 { width: 58.333333%; }

	.sm-6 { width: 50%; }

	.sm-5 { width: 41.666667%; }

	.sm-4 { width: 33.333333%; }

	.sm-3 { width: 25%; }

	.sm-2 { width: 16.666667%; }

	.sm-1 { width: 8.333333%; }

	.md-12 { width: 100%; }

	.md-11 { width: 91.666667%; }

	.md-10 { width: 83.333333%; }

	.md-9 { width: 75%; }

	.md-8 { width: 66.666667%; }

	.md-7 { width: 58.333333%; }

	.md-6 { width: 50%; }

	.md-5 { width: 41.666667%; }

	.md-4 { width: 33.333333%; }

	.md-3 { width: 25%; }

	.md-2 { width: 16.666667%; }

	.md-1 { width: 8.333333%; }

	.lg-12 { width: 100%; }

	.lg-11 { width: 91.666667%; }

	.lg-10 { width: 83.333333%; }

	.lg-9 { width: 75%; }

	.lg-8 { width: 66.666667%; }

	.lg-7 { width: 58.333333%; }

	.lg-6 { width: 50%; }

	.lg-5 { width: 41.666667%; }

	.lg-4 { width: 33.333333%; }

	.lg-3 { width: 25%; }

	.lg-2 { width: 16.666667%; }

	.lg-1 { width: 8.333333%; }

}

@media print {
	
	.show-for-sm,
	.show-for-md { display: none !important; }

	.sm-12 { width: 100%; }

	.sm-11 { width: 91.666667%; }

	.sm-10 { width: 83.333333%; }

	.sm-9 { width: 75%; }

	.sm-8 { width: 66.666667%; }

	.sm-7 { width: 58.333333%; }

	.sm-6 { width: 50%; }

	.sm-5 { width: 41.666667%; }

	.sm-4 { width: 33.333333%; }

	.sm-3 { width: 25%; }

	.sm-2 { width: 16.666667%; }

	.sm-1 { width: 8.333333%; }

	.md-12 { width: 100%; }

	.md-11 { width: 91.666667%; }

	.md-10 { width: 83.333333%; }

	.md-9 { width: 75%; }

	.md-8 { width: 66.666667%; }

	.md-7 { width: 58.333333%; }

	.md-6 { width: 50%; }

	.md-5 { width: 41.666667%; }

	.md-4 { width: 33.333333%; }

	.md-3 { width: 25%; }

	.md-2 { width: 16.666667%; }

	.md-1 { width: 8.333333%; }

	.lg-12 { width: 100%; }

	.lg-11 { width: 91.666667%; }

	.lg-10 { width: 83.333333%; }

	.lg-9 { width: 75%; }

	.lg-8 { width: 66.666667%; }

	.lg-7 { width: 58.333333%; }

	.lg-6 { width: 50%; }

	.lg-5 { width: 41.666667%; }

	.lg-4 { width: 33.333333%; }

	.lg-3 { width: 25%; }

	.lg-2 { width: 16.666667%; }

	.lg-1 { width: 8.333333%; }

}