.electronic-products {
	.callout {
		@media only screen and (min-width: 768px) {
			height: 150px;
		}
		img {
			height: calc(100% - 48px);
			max-width: none;

			@media only screen and (min-width: 768px) {
				height: auto;
				max-height: 150px;
			}
		}

		.box-content {
			@media only screen and (min-width: 768px) {
				padding-left: 10px;
			}

			h2 {
				@media only screen and (min-width: 768px) {
					font-size: 20px;
				}
			}
		}
	}

	&.personal {
		.callouts-secondary {
			margin: 0 auto;
		}
	}
}

.electronic-product-support {
	.hero {
		margin-bottom: 0 !important;

		.vault-app-icon {
		    position: absolute;
		    left: 20px;
		    top: 90px;
		    width: 100%;
		    max-width: 100px;

		    @media only screen and (max-width: $screen-sm) {
				left: 1%;
    			top: 1%;
			}

			@media only screen and (max-width: 575px) {
    			max-width: 18%;
			}
		}

		h1.section-title {
			font-size: 72px;
			text-shadow: 4px 6px 9px rgba(#000,0.7);

			@media only screen and (max-width: $screen-sm) {
				text-shadow: none;
				font-size: 35px;
				color: #000;
			}
		}

		h3.sub {
			border-top: 2px solid #00a6c9;
			font-size: 20px;
			font-weight: 600;
			margin-top: 1.25rem;
			padding-top: 1.25rem;
			text-transform: uppercase;
			width: 65%;

			@media only screen and (max-width: $screen-sm) {
				color: #000;
				width: auto;
			}
		}

		p {
			@media only screen and (min-width: 768px) {
				text-shadow: 1px 3px 5px rgba(0,0,0,0.7);
			}
		}
	}

	.top-nav {
		margin-bottom: 0;

		ul {
			display: flex;
			align-items: center;
			justify-content: center;
		}

		li {
			-webkit-box-flex: 1;
            -ms-flex: 1 0 auto;
                flex: 1 0 auto;
			margin-bottom: 0;

			a {
				display: block;
			}
		}
	}

	.eps-callout {
		@media only screen and (max-width: $screen-sm) {
			padding: 0 15px;
		}
	}
}

.eps-callout {
	text-align: center;
	padding: 20px;
	display: block;
	background: #e9e9e9;
	max-width: 1280px;

	@media only screen and (min-width: 768px) {
		display: flex;
		text-align: left;
		align-items: center;
		justify-content: center;
	}

	p {
		@media only screen and (min-width: 768px) {
			margin-bottom: 0;
			font-size: 1rem;
			color: #55575a;
		}
	}

	.btn {
		width: 100%;
		max-width: 260px;
		background: #1f74b1;
		text-transform: uppercase;
		font-family: Oswald, sans-serif;
		font-size: 17px;
		padding: 8px 15px;
		margin: 0 5px 10px;
		border: 1px solid #000;
		letter-spacing: 0.055em;
		box-shadow: inset 0 0 20px rgba(#000,0.5);
		border-radius: 5px;
		color: #fff;

		@media only screen and (min-width: 768px) {
			display: block;
			margin: 0;
		}

		&:hover {
			background: #175886;
		}

		& + .btn {
			@media only screen and (min-width: 768px) {
				margin-top: 10px;
			}
		}
	}

	a.show-on-mobile {
		display: inline-block;
		@media only screen and (min-width: 768px) {
			display: none;
		}
	    
	}
}

.secondary-callout {
	text-align: center;
	padding: 0 0 10px;

	@media only screen and (min-width: 768px) {
		text-align: right;
		padding: 20px 0 10px 0;
	}

	.col {
		@media only screen and (min-width: 768px) {
			display: flex;
			justify-content: flex-end;
		}
	}

	.btn {
		max-width: 237px;
		width: 100%;
		@media only screen and (min-width: 768px) {
			flex: 0 0 auto;
			margin-left: 20px;

		}
	}
}

.eps-section {
	padding: 2rem 10px 0;

	.icon-product-docs {
		width: 65px;
		height: 65px;
		margin: 0 auto;
	}

	.videos-list {
		margin-bottom: -20px;

		@media only screen and (max-width: 767px) {
			margin: 0 -10px -10px;
			width: auto;
		}

		li {
			padding: 0 10px;
			margin-bottom: 20px;

			@media only screen and (max-width: 767px) {
				margin-bottom: 10px;
			}

			&:nth-child(odd) {
				@media only screen and (max-width: 767px) {
					clear: both;
				}
			}

			&:nth-child(4n+1) {
				@media only screen and (min-width: 768px) {
					clear: both;
				}
			}

			.h4 {
				margin-bottom: 0;
			}
		}
	}
}

.eps-search {
	display: flex;
	align-items: center;
	margin: 0 auto 10px;
	width: calc(100% - 20px);

	@media only screen and (min-width: 768px) {
		margin-top: 20px;

		.electronic-product-support-search & {
			max-width: 100%;
		}
	}

	.electronic-product-support-search & {
		margin-right: auto;
	}

	.eps-search-input {
		height: 40px;
	}

	button {
		flex: 0 0 auto;
		margin-left: 10px;
		padding: 0;
		width: 40px;
		height: 40px;
	}
}

.electronic-product-support-search {
	.section.section-general {
		padding-top: 4em;
	}
}

.search-bar-epdm {
	padding: 0 10% 20px 10%;
	border-bottom: solid 1px #ddd;
}


.bluetooth-specifications {
	margin: 0 10px;
	overflow-x: auto;

	@media only screen and (min-width: 768px) {
		margin: 0;
	}

	table {
		min-width: 600px;

		th,
		td {
			padding: 10px 5px;
			border-style: solid;
			border-width: 1px;
			overflow: hidden;
			word-break: normal;
			border-color: #000;
			font-weight: normal;
		}

		tbody {
			th {
				text-align: left;

				&[scope="col"] {
					background-color: #329a9d;
					color: #fff;
					text-transform: uppercase;
				}
			}
		}
	}
}

.eps-btn-wrapper {
	padding: 30px 0;
	margin-bottom: 0;

	@media only screen and (min-width: 768px) {
		padding-bottom: 0;
	}

	.btn {
		background: #1f74b1;
		text-transform: uppercase;
		font-family: Oswald, sans-serif;
		font-size: 18px;
		padding: 8px 25px;
		border: 1px solid #000;
		letter-spacing: 0.05em;
		box-shadow: inset 0 0 20px rgba(#000,0.5);
		border-radius: 5px;
		color: #fff;

		&:hover {
			background: darken(#1f74b1,10%);
		}
	}
}

.business.bluetooth-lockbox,
.business.bluetooth-landing {
	.fancybox-brightcove {
		display: block;
		position: relative;
		padding-top: 56.25%;

		&:hover {
			.fa-play {
				background-color: $brand-secondary;
			}
		}

		img {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
		}

		.fa-play {
			position: absolute;
			top: 50%;
			left: 50%;
			font-size: 36px;
			color: #fff;
			background: rgba(#000,0.45);
			width: 100px;
			height: 100px;
			line-height: 100px;
			text-align: center;
			border-radius: 50%;
			padding-left: 7px;
			margin: -50px 0 0 -50px;
			transition: background-color 150ms;
		}
	}
}

.update {
	.hero .section-header {
    	max-width: 575px;
	}

	h2.section-title {
		color:#00a8cb;
		margin-bottom: 0.5em;
	}

	.question-container-updates {
		background-color: #eee;
		color: #444;
		cursor: pointer;
		padding: 18px;
		width: 100%;
		border: none;
		text-align: left;
		outline: none;
		font-size: 15px;
		transition: 0.4s;
		margin-bottom: 7px;
		position:relative;
	  	h3 {
  		    display: inline-block;
	  		font-size: 16px;
    		margin: 0;
    		font-style: italic;
    		padding-right: 50px;
	  	}

	  	.strong {
	  		font-weight: bold;
	  		font-style: normal;
	  	}
	}

	.question-container-updates:after {
		content: '\002B';
		color: #000000;
		font-size: 24px;
		position: absolute;
		right: 25px;
		top: 12px;
	}

	.active:after {
		content: "\2212";
	}

	.faq-answer {
		padding: 0 18px;
		background-color: white;
		max-height: 0;
		overflow: hidden;
		transition: max-height 0.2s ease-out;

		img {
		    max-width: 760px;
		    width: 100%;
		    margin-bottom: 50px;
		    display: block;
		}
	}

}

.application-support {
	margin: 0 1em 2.5em;
	@media only screen and (min-width: 768px) {
		margin: 2em 0;
	}
	.support-container {
	    border: 1px solid #c0c0c0;
	    margin-top: 1em;
	    @media only screen and (min-width: 768px) {
		    display: flex;
		}
	}

	.content-left {
	    background-color: #f5f5f5;
	    display: flex;
	    flex-direction: column;
    	align-items: center;
    	justify-content: center;
    	text-align: center;
    	padding: 0;
    	border-bottom: 1px solid #c0c0c0;
    	@media only screen and (min-width: 768px) {
	    	border-right: 1px solid #c0c0c0;
	    	border-bottom: none;
		}

		h3 {
		    font-weight: 600;
		    font-size: 1.7rem;
		}

		#myChatLinkContainer {
		    display: inline-block;
		}

		div#myChatLink a {
			&:hover {
			    text-decoration: none;
			}
		}

		.btn-primary {
			background: $brand-danger;
		    font-size: 1rem;
		    font-weight: 600;
		    text-transform: uppercase;
		    -webkit-transition: all 0.5s ease;
		    transition: all 0.5s ease;
		    max-width: 300px;

		    &:hover {
		    	background: #900000;
		    	text-decoration: none;
		    }
		}

		.text-red {
		    color: $brand-danger;
		    font-size: 1.4rem;
		}
	}
	.top {
		position: relative;
		&:after {
			content: '';
		    border-bottom: 1px solid #c0c0c0;
		    height: 1px;
		    width: 50%;
		    position: absolute;
    		bottom: 0;
    		display: inline-block;
		    margin: 0 auto;
		    left: 0;
		    right: 0;
		}
	}

	.top, .bottom {
	    padding: 2em 0;
		width: 100%;
		@media only screen and (min-width: 768px) {
			display: flex;
		    flex-direction: column;
		    align-items: center;
		    justify-content: center;
		    height: 100%;
    	}
	}

	.phone-number {
	    margin-bottom: 0.5em;
	}

	span.hours {
	    display: block;
	}

	.content-right {
		padding: 2em;

		h3.form-heading {
			@media only screen and (min-width: 768px) {
			    padding: 0 0.5em 0.25em;
			}
		}

		.required-fields {
			display: block;
			@media only screen and (min-width: 768px) {
			    padding: 0 0.75em 0.5em;
			}
		}

		input[type="text"], select {
		    height: 2.5em;
		}

		.opt-in {
		    margin: 0.5em 0 1em;
		}

		.privacy-link {
			padding: 0 0 1em;
			@media only screen and (min-width: 768px) {
				padding: 0 0 1em 0.75em;
			}
		}

		button {
			background-image: none;
		    text-transform: uppercase;
		    font-weight: 600;
		    font-size: 1.1em;
		    padding: 0.85em 1.5em;
		    width: 142px;
		    -webkit-transition: all 0.5s ease;
		    transition: all 0.5s ease;
		}
	}
}

.search-section {
	h2 {
    	margin: 0;
    	padding: 0 0.5em;
    	@media only screen and (min-width: 768px) {
    		padding: 0;
    	}
	}

}

.application-thank-you {
	header.hero {
	    display: none;
	}

	main#content {
		top: auto;
	}

	h6#site-title {
		@media only screen and (min-width: 768px) {
		    background-position: 0px -62px;
		}
	}

	.application-support {
	    margin: 2.5em 0;
	}

	.support-container {
	    display: flex;
	    flex-direction: column;
	    justify-content: center;
	    align-items: center;
	    padding: 3em 1em;
	    border: none;
	    text-align: center;
	    @media only screen and (min-width: 768px) {
	    	padding: 7em;
	    }

	    h2.thank-you-heading {
		    font-weight: 600;
		    text-transform: uppercase;
		    font-size: 1.5rem;
		    @media only screen and (min-width: 768px) {
			    font-size: 2.5rem;
			}
		}

		p {
		    font-size: 1rem;
		    margin: 0;
		    @media only screen and (min-width: 768px) {
			    font-size: 1.2rem;
			}
		}
	}
}

